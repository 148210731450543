import { Checkbox, Drawer, Icon } from '@grafana/ui';
import { Disclosure } from '@headlessui/react';
import { components } from 'api/src/apiv1';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { Activity, Bell, Disc, Import, LucideIcon } from 'lucide-react';
import React, { useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import { GET } from '../../client';
import useObservable from '../../hooks/useObservable';
import { currentProjectIdObservable, recordListObservable } from '../../observables';
import { Channel } from '../../types';
import { transformLocalName } from '../../utils';

dayjs.extend(utc);

type SelectChannelsDrawerProps = {
  onClose: () => void;
  selectedChannels: Array<{ name: string; projectId?: number }>;
  selectedGroups: Array<{ name: string; projectId?: number }>;
  onChange: (
    selectedChannels: Array<{ name: string; projectId?: number }>,
    selectedChannelGroups: Array<{ name: string; projectId?: number }>
  ) => void;
};

const channelListObservable = new BehaviorSubject<Channel[]>([]);
const channelGroupListObservable = new BehaviorSubject<Array<components['schemas']['ChannelGroup']>>([]);
const eventListObservable = new BehaviorSubject<Array<components['schemas']['Event']>>([]);

function SelectChannelsDrawer({ onClose, selectedChannels, selectedGroups, onChange }: SelectChannelsDrawerProps) {
  // const projects = useObservable(projectResourcesObservable, {});
  // const channels = useObservable(channelListObservable, []);
  // const channelGroups = useObservable(channelGroupListObservable, []);

  useEffect(() => {
    GET('/api/channels').then(({ data }) => {
      if (!data) {
        return;
      }
      channelListObservable.next(data);
    });
    GET('/api/channel-groups').then(({ data }) => {
      if (!data) {
        return;
      }
      channelGroupListObservable.next(data);
    });
    GET('/api/events').then(({ data }) => {
      if (!data) {
        return;
      }
      eventListObservable.next(data);
    });
  }, []);

  return (
    <Drawer title="Select Channels" onClose={onClose} size="sm">
      <ProjectChannelList
        projectId={currentProjectIdObservable.value}
        channelListObservable={channelListObservable}
        channelGroupsObservable={channelGroupListObservable}
        recordListObservable={recordListObservable}
        eventListObservable={eventListObservable}
        selectedChannels={selectedChannels}
        selectedGroups={selectedGroups}
        onChange={(selectedChannels, selectedChannelGroups) => {
          onChange(selectedChannels, selectedChannelGroups);
        }}
      />
      {/*<div className="flex flex-col space-y-1 pb-4">*/}
      {/*  {Object.keys(projects)*/}
      {/*    .sort((a, b) => {*/}
      {/*      // current first, active second, others third*/}
      {/*      if (Number(a) === currentProjectIdObservable.value) {*/}
      {/*        return -1;*/}
      {/*      }*/}
      {/*      if (Number(b) === currentProjectIdObservable.value) {*/}
      {/*        return 1;*/}
      {/*      }*/}
      {/*      if (projectList.find((v) => v.id === Number(a))?.active) {*/}
      {/*        return -1;*/}
      {/*      }*/}
      {/*      if (projectList.find((v) => v.id === Number(b))?.active) {*/}
      {/*        return 1;*/}
      {/*      }*/}
      {/*      if (Number(a) < Number(b)) {*/}
      {/*        return -1;*/}
      {/*      }*/}
      {/*      if (Number(a) > Number(b)) {*/}
      {/*        return 1;*/}
      {/*      }*/}
      {/*      return 0;*/}
      {/*    })*/}
      {/*    .map((projectId) => {*/}
      {/*      const { channels, channelGroups, records, events } = projects[Number(projectId)]!;*/}
      {/*      return (*/}
      {/*        <Disclosure key={projectId} defaultOpen>*/}
      {/*          <Disclosure.Button*/}
      {/*            as="div"*/}
      {/*            className="py-2 px-3 cursor-pointer hover:bg-black/5 dark:hover:bg-white/5 rounded-lg flex items-center"*/}
      {/*          >*/}
      {/*            <Icon name="folder" size="md" className="opacity-80 mb-0" />*/}
      {/*            <p className="text-base font-semibold mb-0 ml-2">*/}
      {/*              {projectListObservable.value.find((v) => v.id === Number(projectId))?.name}*/}
      {/*            </p>*/}
      {/*            {projectListObservable.value.find((v) => v.id === Number(projectId))?.active && (*/}
      {/*              <Badge text="Active" color="green" className="ml-2" />*/}
      {/*            )}*/}
      {/*            {Number(projectId) === currentProjectIdObservable.value && (*/}
      {/*              <Badge text="Current" color="blue" className="ml-2" />*/}
      {/*            )}*/}
      {/*            <Icon name="angle-right" size="lg" className="ml-auto mr-0 ui-open:rotate-90 transition-all" />*/}
      {/*          </Disclosure.Button>*/}
      {/*          <Disclosure.Panel className="pl-4 pr-2">*/}
      {/*            <ProjectChannelList*/}
      {/*              projectId={Number(projectId)}*/}
      {/*              key={projectId}*/}
      {/*              channelListObservable={channels}*/}
      {/*              channelGroupsObservable={channelGroups}*/}
      {/*              recordListObservable={records}*/}
      {/*              eventListObservable={events}*/}
      {/*              selectedChannels={selectedChannels}*/}
      {/*              selectedGroups={selectedGroups}*/}
      {/*              onChange={onChange}*/}
      {/*            />*/}
      {/*          </Disclosure.Panel>*/}
      {/*        </Disclosure>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*</div>*/}
    </Drawer>
  );
}

const ProjectChannelList: React.FC<{
  projectId: number;
  channelListObservable: BehaviorSubject<Channel[]>;
  channelGroupsObservable: BehaviorSubject<Array<components['schemas']['ChannelGroup']>>;
  recordListObservable: BehaviorSubject<Array<components['schemas']['Record']>>;
  eventListObservable: BehaviorSubject<Array<components['schemas']['Event']>>;
  selectedChannels: Array<{ name: string; projectId?: number }>;
  selectedGroups: Array<{ name: string; projectId?: number }>;
  onChange: (
    selectedChannels: Array<{ name: string; projectId?: number }>,
    selectedChannelGroups: Array<{ name: string; projectId?: number }>
  ) => void;
}> = ({
  projectId,
  channelListObservable,
  channelGroupsObservable,
  recordListObservable,
  eventListObservable,
  selectedChannels,
  selectedGroups,
  onChange,
}) => {
  const channels = useObservable(channelListObservable, []);
  const channelGroups = useObservable(channelGroupsObservable, []);
  const records = useObservable(recordListObservable, []);
  const events = useObservable(eventListObservable, []);

  const nodes: Array<{
    value: string;
    label: string;
    icon: LucideIcon;
    children: Array<{
      type: 'checkbox-group' | 'group';
      value: string;
      label: string;
      children: Array<
        | {
            type: 'checkbox-group';
            value: string;
            label: string;
            children: Array<{
              type: 'checkbox';
              value: string;
              label: string;
            }>;
          }
        | {
            type: 'checkbox';
            value: string;
            label: string;
          }
      >;
    }>;
  }> = useMemo(() => {
    // const user = useObservable(userObservable, null);
    const realtimeChannels = channels.filter((channel) => !!channel.dataflowId);
    const importedChannels = channels.filter((channel) => !!channel.importId);
    const recordedChannels = channels.filter((channel) => !!channel.recordId);
    const eventChannels = channels.filter((channel) => !!channel.eventId);

    const realtimeChannelGroupNames = realtimeChannels.flatMap((channel) => channel.groups);
    const realtimeChannelGroups =
      channelGroups?.filter((group) => realtimeChannelGroupNames.includes(group.name)) || [];

    realtimeChannelGroups.sort((a, b) => {
      if (a.name === 'Default') {
        return -1;
      }
      if (b.name === 'Default') {
        return 1;
      }
      if (a.name === 'Raw') {
        return -1;
      }
      if (b.name === 'Raw') {
        return 1;
      }
      if (a.name === 'Derived') {
        return -1;
      }
      if (b.name === 'Derived') {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    const importedChannelGroupNames = importedChannels.flatMap((channel) => channel.groups);
    const importedChannelGroups =
      channelGroups?.filter((group) => importedChannelGroupNames.includes(group.name)) || [];

    const realtimeGroupChannels = realtimeChannels.reduce(
      (groups, channel) => {
        if (channel.groups.length === 0) {
          groups[0].channels.push(channel);
          return groups;
        }
        for (const chGroup of channel.groups) {
          const group = groups.find((group) => group.name === chGroup);
          if (group) {
            group.channels.push(channel);
          }
        }
        return groups;
      },
      [
        {
          name: 'Default',
          channels: [] as Channel[],
        },
        ...(realtimeChannelGroups.map((group) => ({ name: group.name, channels: [] })) as Array<{
          name: string;
          channels: Channel[];
        }>),
      ]
    );

    realtimeGroupChannels.forEach((group) => {
      group.channels = _.uniqBy(group.channels, 'name');
    });

    if (realtimeGroupChannels[0].channels.length === 0) {
      realtimeGroupChannels.shift();
    }

    const importedGroupChannels = importedChannels.reduce(
      (groups, channel) => {
        if (channel.groups.length === 0) {
          groups[0].channels.push(channel);
          return groups;
        }
        for (const chGroup of channel.groups) {
          const group = groups.find((group) => group.name === chGroup);
          if (group) {
            group.channels.push(channel);
          }
        }
        return groups;
      },
      [
        {
          name: 'Default',
          channels: [] as Channel[],
        },
        ...(importedChannelGroups.map((group) => ({ name: group.name, channels: [] })) as Array<{
          name: string;
          channels: Channel[];
        }>),
      ]
    );

    importedGroupChannels.forEach((group) => {
      group.channels = _.uniqBy(group.channels, 'name');
    });

    if (importedGroupChannels[0].channels.length === 0) {
      importedGroupChannels.shift();
    }

    const recordedGroupChannels: Array<{
      name: string;
      children: Array<{ name: string; channels: Array<{ name: string; label: string }> }>;
    }> = [];

    for (const record of records) {
      const recordChannels = recordedChannels.filter((channel) => channel.recordId === record.id);
      const recordGroupNames = recordChannels.flatMap((channel) => channel.groups);
      const recordGroups = channelGroups?.filter((group) => recordGroupNames.includes(group.name)) || [];

      const groupChannels = recordChannels.reduce(
        (groups, channel) => {
          if (channel.groups.length === 0) {
            groups[0].channels.push(channel);
            return groups;
          }
          for (const chGroup of channel.groups) {
            const group = groups.find((group) => group.name === chGroup);
            if (group) {
              group.channels.push(channel);
            }
          }
          return groups;
        },
        [
          {
            name: 'Default',
            channels: [] as Channel[],
          },
          ...(recordGroups.map((group) => ({ name: group.name, channels: [] })) as Array<{
            name: string;
            channels: Channel[];
          }>),
        ]
      );

      groupChannels.forEach((group) => {
        group.channels = _.uniqBy(group.channels, 'name');
      });

      if (groupChannels[0].channels.length === 0) {
        groupChannels.shift();
      }

      recordedGroupChannels.push({
        name: record.testId,
        children: groupChannels.map((group) => ({
          name: group.name,
          channels: group.channels.map((channel) => ({
            name: channel.name,
            label: channel.name,
          })),
        })),
      });
    }

    const eventGroupChannels: Array<{
      name: string;
      children: Array<{ name: string; channels: Array<{ name: string; label: string }> }>;
    }> = [];

    for (const event of events) {
      const channels = eventChannels.filter((channel) => channel.eventId === event.id);
      const eventGroupNames = channels.flatMap((channel) => channel.groups);
      const eventGroups = channelGroups?.filter((group) => eventGroupNames.includes(group.name)) || [];

      const groupChannels = channels.reduce(
        (groups, channel) => {
          if (channel.groups.length === 0) {
            groups[0].channels.push(channel);
            return groups;
          }
          for (const chGroup of channel.groups) {
            const group = groups.find((group) => group.name === chGroup);
            if (group) {
              group.channels.push(channel);
            }
          }
          return groups;
        },
        [
          {
            name: 'Default',
            channels: [] as Channel[],
          },
          ...(eventGroups.map((group) => ({ name: group.name, channels: [] })) as Array<{
            name: string;
            channels: Channel[];
          }>),
        ]
      );

      groupChannels.forEach((group) => {
        group.channels = _.uniqBy(group.channels, 'name');
      });

      if (groupChannels[0].channels.length === 0) {
        groupChannels.shift();
      }

      eventGroupChannels.push({
        name: `${event.name} ${dayjs(event.createdAt)
          .utcOffset(new Date().getTimezoneOffset())
          .format('YYYY-MM-DD HH:mm:ss')}`,
        children: groupChannels.map((group) => ({
          name: group.name,
          channels: group.channels.map((channel) => ({
            name: channel.name,
            label: channel.name,
          })),
        })),
      });
    }

    return [
      {
        value: 'realtime',
        label: 'Realtime',
        icon: Activity,
        children: realtimeGroupChannels.map((group) => ({
          type: 'checkbox-group' as const,
          value: group.name,
          label: transformLocalName(group.name),
          children: group.channels.map((channel) => ({
            type: 'checkbox' as const,
            value: channel.name,
            label: transformLocalName(channel.name),
          })),
        })),
      },
      {
        value: 'imported',
        label: 'Imported',
        icon: Import,
        children: importedGroupChannels.map((group) => ({
          type: 'checkbox-group' as const,
          value: group.name,
          label: transformLocalName(group.name),
          children: group.channels.map((channel) => ({
            type: 'checkbox' as const,
            value: channel.name,
            label: transformLocalName(channel.name),
          })),
        })),
      },
      {
        value: 'recorded',
        label: 'Recorded',
        icon: Disc,
        children: recordedGroupChannels.map((group) => ({
          type: 'group' as const,
          value: group.name,
          label: group.name,
          children: group.children.map((child) => ({
            type: 'checkbox-group' as const,
            value: child.name,
            label: child.name,
            children: child.channels.map((channel) => ({
              type: 'checkbox' as const,
              value: channel.name,
              label: transformLocalName(channel.label || channel.name),
            })),
          })),
        })),
      },
      {
        value: 'event',
        label: 'Event',
        icon: Bell,
        children: eventGroupChannels.map((group) => ({
          type: 'group' as const,
          value: group.name,
          label: group.name,
          children: group.children.map((child) => ({
            type: 'checkbox-group' as const,
            value: child.name,
            label: transformLocalName(child.name),
            children: child.channels.map((channel) => ({
              type: 'checkbox' as const,
              value: channel.name,
              label: transformLocalName(channel.label || channel.name),
            })),
          })),
        })),
      },
    ];
  }, [channels, channelGroups, records, events]);

  return (
    <>
      {nodes.map((node) => (
        <Disclosure key={node.value} defaultOpen>
          <Disclosure.Button
            as="div"
            className="py-2 px-3 cursor-pointer hover:bg-black/5 dark:hover:bg-white/5 rounded-lg flex items-center"
          >
            <node.icon className="h-4 w-4 mr-2" />
            <p className="text-base font-semibold mb-0">{node.label}</p>
            <Icon name="angle-right" size="lg" className="ml-auto mr-0 ui-open:rotate-90 transition-all" />
          </Disclosure.Button>
          <Disclosure.Panel className="pl-4 pr-2">
            <div className="flex flex-col space-y-1">
              {node.children.map((group) => (
                <Disclosure key={group.value} defaultOpen={node.value === 'realtime'}>
                  <Disclosure.Button
                    as="div"
                    className="py-2 px-3 cursor-pointer hover:bg-black/5 dark:hover:bg-white/5 rounded-lg flex items-center"
                  >
                    {/*<p className="text-base font-semibold mb-0">{group.label}</p>*/}
                    {group.type === 'checkbox-group' ? (
                      <Checkbox
                        checked={selectedGroups.some((v) => v.name === group.value && v.projectId === projectId)}
                        onChange={(event) => {
                          let newSelectedChannels = selectedChannels;
                          let newSelectedGroups = selectedGroups;
                          if (event.currentTarget.checked) {
                            newSelectedChannels = [
                              ...selectedChannels,
                              ...group.children.map((channel) => ({
                                name: channel.value,
                                projectId,
                              })),
                            ];
                            newSelectedGroups = [
                              ...selectedGroups,
                              {
                                name: group.value,
                                projectId,
                              },
                            ];
                          } else {
                            newSelectedChannels = newSelectedChannels.filter(
                              (v) => !group.children.some((channel) => _.isEqual(v, { name: channel.value, projectId }))
                            );
                            newSelectedGroups = selectedGroups.filter(
                              (v) => !(v.name === group.value && v.projectId === projectId)
                            );
                          }
                          onChange(newSelectedChannels, newSelectedGroups);
                        }}
                      />
                    ) : (
                      <Icon name="folder" size="md" className="opacity-80" />
                    )}
                    {group.type === 'group' ? (
                      <p className="text-base font-semibold mb-0 ml-2">{group.label}</p>
                    ) : (
                      <p className="text-base font-semibold mb-0 ml-2">
                        {group.label} (
                        {
                          selectedChannels.filter(
                            (v) =>
                              v.projectId === projectId && group.children.some((channel) => channel.value === v.name)
                          ).length
                        }
                        /{group.children.length})
                      </p>
                    )}
                    <Icon name="angle-right" size="lg" className="ml-auto mr-0 ui-open:rotate-90 transition-all" />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-4 pr-2">
                    <div className="flex flex-col space-y-1">
                      {group.children.map((child) => (
                        <React.Fragment key={child.value}>
                          {child.type === 'checkbox-group' ? (
                            <Disclosure>
                              <Disclosure.Button
                                as="div"
                                className="py-2 px-3 cursor-pointer hover:bg-black/5 dark:hover:bg-white/5 rounded-lg flex items-center"
                              >
                                <Checkbox
                                  checked={selectedGroups.some(
                                    (v) => v.name === child.value && v.projectId === projectId
                                  )}
                                  onChange={(event) => {
                                    let newSelectedChannels = selectedChannels;
                                    let newSelectedGroups = selectedGroups;
                                    if (event.currentTarget.checked) {
                                      newSelectedChannels = [
                                        ...selectedChannels,
                                        ...group.children.map((channel) => ({
                                          name: channel.value,
                                          projectId,
                                        })),
                                      ];
                                      newSelectedGroups = [
                                        ...selectedGroups,
                                        {
                                          name: group.value,
                                          projectId,
                                        },
                                      ];
                                    } else {
                                      newSelectedChannels = newSelectedChannels.filter(
                                        (v) =>
                                          !group.children.some((channel) =>
                                            _.isEqual(v, { name: channel.value, projectId })
                                          )
                                      );
                                      newSelectedGroups = selectedGroups.filter(
                                        (v) => !(v.name === group.value && v.projectId === projectId)
                                      );
                                    }
                                    onChange(newSelectedChannels, newSelectedGroups);
                                  }}
                                />
                                <p className="text-base font-semibold mb-0 ml-2">
                                  {child.label} (
                                  {
                                    selectedChannels.filter(
                                      (v) =>
                                        v.projectId === projectId &&
                                        child.children.some((channel) => channel.value === v.name)
                                    ).length
                                  }
                                  /{child.children.length})
                                </p>
                                <Icon
                                  name="angle-right"
                                  size="lg"
                                  className="ml-auto mr-0 ui-open:rotate-90 transition-all"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="pl-4 pr-2">
                                <div className="flex flex-col space-y-1">
                                  {child.children.map((channel) => (
                                    <div
                                      key={channel.value}
                                      className="flex items-center space-x-2 py-1 px-2 hover:bg-black/5 dark:hover:bg-white/5 rounded-md"
                                    >
                                      <Checkbox
                                        label={channel.label}
                                        className="[&_span]:text-base w-full justify-start"
                                        checked={selectedChannels.some(
                                          (v) => v.projectId === projectId && v.name === channel.value
                                        )}
                                        onChange={(event) => {
                                          let newSelectedChannels = selectedChannels;
                                          let newSelectedGroups = selectedGroups;
                                          if (event.currentTarget.checked) {
                                            newSelectedChannels = [
                                              ...selectedChannels,
                                              {
                                                name: channel.value,
                                                projectId,
                                              },
                                            ];
                                          } else {
                                            newSelectedChannels = selectedChannels.filter(
                                              (v) => v.name !== channel.value || v.projectId !== projectId
                                            );
                                          }
                                          onChange(newSelectedChannels, newSelectedGroups);
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </Disclosure>
                          ) : (
                            <div className="flex items-center space-x-2 py-1 px-2 hover:bg-black/5 dark:hover:bg-white/5 rounded-md">
                              <Checkbox
                                label={child.label}
                                className="[&_span]:text-base w-full justify-start"
                                checked={selectedChannels.some(
                                  (v) => v.projectId === projectId && v.name === child.value
                                )}
                                onChange={(event) => {
                                  let newSelectedChannels = selectedChannels;
                                  let newSelectedGroups = selectedGroups;
                                  if (event.currentTarget.checked) {
                                    newSelectedChannels = [
                                      ...selectedChannels,
                                      {
                                        name: child.value,
                                        projectId,
                                      },
                                    ];
                                  } else {
                                    newSelectedChannels = selectedChannels.filter(
                                      (v) => v.name !== child.value || v.projectId !== projectId
                                    );
                                  }
                                  onChange(newSelectedChannels, newSelectedGroups);
                                }}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </div>
          </Disclosure.Panel>
        </Disclosure>
      ))}
    </>
  );
};

// const MemoizedSelectChannelsDrawer = React.memo(SelectChannelsDrawer, (prev, next) => {
//   return true;
// });

export default SelectChannelsDrawer;
