import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal } from '@grafana/ui';
import { PUT } from 'client';
import React from 'react';
import type { ProjectUser } from '../../types';
import UserForm, { UserFormValues } from './UserForm';
import { currentProjectIdObservable } from '../../observables';

export default function UserEditModal({ user, onClose }: { user: ProjectUser; onClose: () => void }) {
  const handleSaveUser = async (values: UserFormValues) => {
    const appEvents = getAppEvents();
    try {
      if (values.name !== user.name || values.email !== user.email || values.login !== user.login) {
        const response = await PUT('/api/users/{user_id}', {
          params: {
            path: {
              user_id: user.userId,
            },
          },
          body: {
            ...values,
            theme: '',
          },
        });
        if (response.error) {
          throw new Error(response.error.message);
        }
      }
      if (values.role !== user.userRole) {
        await PUT('/api/projects/{project_id}/users/{user_id}/role', {
          params: {
            path: {
              project_id: currentProjectIdObservable.value,
              user_id: user.userId,
            },
          },
          body: {
            role: values.role,
          },
        });
      }
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['User started successfully'],
      });
      onClose();
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Failed to start user: ${e instanceof Error ? e.message : e}`],
      });
    }
  };

  // const handleDisableUser = async () => {
  //   const appEvents = getAppEvents();
  //   try {
  //     await PUT('/api/users/{user_id}/disable', {
  //       params: {
  //
  //       }
  // }

  return (
    <Modal title="Edit User" isOpen={true} onDismiss={onClose}>
      <UserForm
        onSubmit={handleSaveUser}
        defaultValues={{ ...user, role: user.userRole || 'Client', password: '' }}
        actions={
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={onClose}>
              Cancel
            </Button>
            {!user.isDisabled ? (
              <Button variant="destructive" onClick={onClose}>
                Disable
              </Button>
            ) : (
              <Button variant="success" onClick={onClose}>
                Enable
              </Button>
            )}
            <Button type="submit">Save</Button>
          </Modal.ButtonRow>
        }
      />
    </Modal>
  );
}
