import { css } from '@emotion/css';
import {
  Button,
  ColorPickerInput,
  Field,
  Input,
  Menu,
  MultiSelect,
  RadioButtonGroup,
  ReactMonacoEditor,
  useTheme2
} from '@grafana/ui';
import { components } from 'api';
import { API_URL } from 'common';
import { AnimatePresence, m } from 'framer-motion';
import React, { useState } from 'react';
import stringHash from 'string-hash';

type Channel = components['schemas']['Channel'];
type ChannelGroup = components['schemas']['ChannelGroup'];

const tabs = [
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Plotting',
    value: 'plotting',
  },
];

const ChannelMenu: React.FC<{ channel: Channel; groups: ChannelGroup[]; colors: string[] }> = ({
  channel,
  groups,
  colors,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [modifiedChannel, setModifiedChannel] = useState<Channel>(channel);
  const [channelAttributes, setChannelAttributes] = useState(JSON.stringify(channel.channelAttributes, null, 2));
  const theme = useTheme2();

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        await fetch(`${API_URL}/api/channels/${channel.name}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...modifiedChannel,
            channelAttributes: JSON.parse(channelAttributes),
          }),
        });
      }}
      className={css({
        backgroundColor: theme.colors.background.primary,
        boxShadow: theme.shadows.z3,
        width: 300,
      })}
    >
      <div
        className={css({
          padding: 8,
          paddingTop: 10,
          paddingBottom: 4,
        })}
      >
        <p
          className={css({
            fontWeight: 600,
            margin: 0,
            fontSize: '1rem',
          })}
        >
          {channel.name}
        </p>
      </div>
      <Menu.Divider />
      <div className="p-2">
        <p className="text-sm font-medium opacity-80 italic m-0 mb-2">Channel Group</p>
        <MultiSelect
          options={groups.map((group) => ({ label: group.name, value: group.name }))}
          value={modifiedChannel.groups}
          onChange={(groups) => {
            setModifiedChannel((channel) => ({
              ...channel,
              groups: groups.map((group) => group.value).filter(Boolean) as string[],
            }));
          }}
        />
      </div>
      <Menu.Divider />
      <div className="p-2">
        <p className="text-sm font-medium opacity-80 italic m-0 mb-2">Attributes</p>
        <RadioButtonGroup
          options={tabs}
          value={selectedTab}
          onChange={setSelectedTab}
          fullWidth
          className={css({
            marginBottom: 8,
          })}
        />
        <AnimatePresence mode="popLayout">
          {selectedTab === 'channel' && (
            <m.div key="channel" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ReactMonacoEditor
                language="json"
                value={channelAttributes}
                onChange={(value) => {
                  setChannelAttributes(value || '');
                }}
                options={{
                  minimap: { enabled: false },
                  // lineNumbers: 'off',
                }}
                className={css({
                  minHeight: 160,
                })}
              />
            </m.div>
          )}
          {selectedTab === 'plotting' && (
            <m.div
              key="plotting"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="p-1"
            >
              <Field label="Color">
                <ColorPickerInput
                  value={
                    (modifiedChannel.plottingAttributes.color as string) ||
                    colors[Math.abs(stringHash(channel.name)) % colors.length]
                  }
                  onChange={(color) => {
                    setModifiedChannel((channel) => ({
                      ...channel,
                      plottingAttributes: { ...channel.plottingAttributes, color },
                    }));
                  }}
                />
              </Field>
              {/* <Field label="Visibility">
                <Switch
                  value={!(modifiedChannel.plottingAttributes?.hidden || false)}
                  onChange={(_event) => {
                    setModifiedChannel((channel) => ({
                      ...channel,
                      plottingAttributes: {
                        ...channel.plottingAttributes,
                        hidden: !channel.plottingAttributes?.hidden,
                      },
                    }));
                  }}
                />
              </Field> */}
              <Field label="Max Data Points (second)">
                <Input
                  type="number"
                  value={(modifiedChannel.plottingAttributes.max_data_points_per_second as number) ?? 24}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    setModifiedChannel((channel) => ({
                      ...channel,
                      plottingAttributes: {
                        ...channel.plottingAttributes,
                        max_data_points_per_second: Number(ev.target.value || ''),
                      },
                    }));
                  }}
                />
              </Field>
            </m.div>
          )}
        </AnimatePresence>
      </div>
      <Menu.Divider />
      <div className="p-2">
        <Button icon="save" variant="success" type="submit" fullWidth className="w-full">
          Save
        </Button>
      </div>
    </form>
  );
};

export default ChannelMenu;
