import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Tooltip } from '@grafana/ui';
import { Dataflow, FunctionDataType } from '../../types';
import DataflowEditDrawer from './DataflowEditDrawer';
import { DELETE, GET, POST } from '../../client';
import _ from 'lodash';
import useConfirm from '../../hooks/useConfirm';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import Icons from '../Icons';
import { API_URL } from 'common';
import useObservable from '../../hooks/useObservable';
import { runtimeStateObservable } from '../../observables';

interface DataflowListItemProps {
  dataflow: Dataflow;
}

const DataflowListItem: React.FC<DataflowListItemProps> = ({ dataflow }) => {
  const activeProjectId = useObservable(runtimeStateObservable, {
    recording: null,
    activeProjectId: 0,
  }).activeProjectId;
  const [isOpen, setIsOpen] = useState(false);
  const [sinkOutputType, setSinkOutputType] = useState<FunctionDataType | null>(null);
  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete dataflow (${dataflow.name})`,
    body: 'Are you sure you want to delete this dataflow?',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/dataflows/{id}', {
          params: {
            path: {
              id: dataflow.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete dataflow (${dataflow.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete dataflow (${dataflow.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete dataflow (${dataflow.name}) failed: ${err}`],
        });
      }
    },
  });

  useEffect(() => {
    GET('/api/sink-functions/{id}', {
      params: {
        path: {
          id: dataflow.sinkId,
        },
      },
    }).then(({ data }) => {
      if (!data || data.__typename !== 'Sink') {
        return;
      }
      setSinkOutputType(data.outputType);
    });
  }, [dataflow]);

  return (
    <Card>
      <Card.Heading>
        {dataflow.name}{' '}
        {dataflow.projectId === activeProjectId ? (
          <Card.Tags className="flex space-x-2 items-center">
            {sinkOutputType && <Badge text={_.capitalize(sinkOutputType)} color="blue" />}
            {dataflow.processes.length > 0 && <Badge text="Processed" color="orange" />}
            {dataflow.status.status === 'INITIALIZING' && <Badge text="Initializing" color="purple" />}
            {dataflow.status.status === 'RUNNING' && <Badge text="Running" color="green" />}
            {dataflow.status.status === 'STOPPED' && <Badge text="Stopped" color="purple" />}
            {dataflow.status.status === 'ERROR' && (
              <Tooltip content={dataflow.status.message} theme="error">
                <div role="button" className="cursor-default">
                  <Badge text="Error" color="red" />
                </div>
              </Tooltip>
            )}
          </Card.Tags>
        ) : (
          <Card.Tags className="flex space-x-2 items-center">
            <Badge text="Inactive" color="red" tooltip="Only dataflow in active project can be started" />
          </Card.Tags>
        )}
      </Card.Heading>
      <Card.Description>{dataflow.description}</Card.Description>
      {/* <Card.Meta>{[`Host: ${datasource.host}`, `Port: ${datasource.port}`]}</Card.Meta> */}

      <Card.Actions>
        {dataflow.projectId === activeProjectId && (
          <>
            <Button
              key="restart"
              variant="secondary"
              className="pl-4"
              onClick={async () => {
                try {
                  const resposne = await fetch(`${API_URL}/api/dataflows/${dataflow.id}/restart`, {
                    method: 'POST',
                  });
                  const appEvents = getAppEvents();
                  if (!resposne.ok) {
                    appEvents.publish({
                      type: AppEvents.alertError.name,
                      payload: [`Restart dataflow (${dataflow.name}) failed`],
                    });
                    return;
                  }
                  appEvents.publish({
                    type: AppEvents.alertSuccess.name,
                    payload: [`Restart dataflow (${dataflow.name}) successfully`],
                  });
                } catch (e) {
                  const appEvents = getAppEvents();
                  appEvents.publish({
                    type: AppEvents.alertError.name,
                    payload: [`Restart dataflow (${dataflow.name}) failed`],
                  });
                }
              }}
            >
              <Icons.restart className="h-4 w-4 mr-2" />
              Restart
            </Button>
            {dataflow.status.status === 'RUNNING' && (
              <Button
                icon="times"
                key="stop"
                variant="secondary"
                onClick={async () => {
                  try {
                    const resposne = await POST('/api/dataflows/{id}/stop', {
                      params: {
                        path: {
                          id: dataflow.id,
                        },
                      },
                    });
                    const appEvents = getAppEvents();
                    if (resposne.error) {
                      appEvents.publish({
                        type: AppEvents.alertError.name,
                        payload: [`Stop dataflow (${dataflow.name}) failed`],
                      });
                      return;
                    }
                    appEvents.publish({
                      type: AppEvents.alertSuccess.name,
                      payload: [`Stop dataflow (${dataflow.name}) successfully`],
                    });
                  } catch (e) {
                    const appEvents = getAppEvents();
                    appEvents.publish({
                      type: AppEvents.alertError.name,
                      payload: [`Stop dataflow (${dataflow.name}) failed`],
                    });
                  }
                }}
              >
                Stop
              </Button>
            )}
          </>
        )}
        <Button key="edit" variant="secondary" icon="edit" onClick={() => setIsOpen(true)}>
          Edit
        </Button>
        <Button key="delete" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Card.Actions>
      {isOpen && <DataflowEditDrawer dataflow={dataflow} onClose={() => setIsOpen(false)} />}
      {ConfirmComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default DataflowListItem;
