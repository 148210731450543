import React, { useEffect } from 'react';

interface UpdateIsDirtyProps {
  isDirtyRef: React.MutableRefObject<boolean>;
  isDirty: boolean;
}

const UpdateIsDirty: React.FC<UpdateIsDirtyProps> = ({ isDirtyRef, isDirty }) => {
  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty, isDirtyRef]);

  return <></>;
};

export default UpdateIsDirty;
