import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Badge, Button, Card, IconButton } from '@grafana/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { DELETE, POST } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { Project } from '../../types';
import { currentProjectIdObservable } from '../../observables';
import useObservable from '../../hooks/useObservable';

dayjs.extend(utc);

interface ProjectListItemProps {
  project: Project;
  onEditClick: () => void;
}

const ProjectListItem: React.FC<ProjectListItemProps> = ({ project, onEditClick }) => {
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete project (${project.name})`,
    body: 'Are you sure you want to delete this project? All data in this project will be removed.',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/projects/{id}', {
          params: {
            path: {
              id: project.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete project (${project.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete project (${project.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete project (${project.name}) failed: ${err}`],
        });
      }
    },
  });

  const [ConfirmActivateComponent, handleActivate] = useConfirm({
    title: `Activate project (${project.name})`,
    body: 'Are you sure you want to activate this project? Current active project will be inactivated.',
    confirmText: 'Activate',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await POST('/api/projects/{id}/activate', {
          params: {
            path: {
              id: project.id,
            },
          },
        });
        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Activate project (${project.name}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Activate project (${project.name}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Activate project (${project.name}) failed: ${err}`],
        });
      }
    },
  });

  return (
    <Card>
      <Card.Heading>{project.name}</Card.Heading>
      <Card.Tags className="space-x-1">
        {project.id === currentProjectId && <Badge text="Current" color="blue" />}
        {project.active ? <Badge text="Active" color="green" /> : <Badge text="Inactive" color="red" />}
      </Card.Tags>
      <Card.Actions>
        <Button key="edit" variant="secondary" icon="edit" onClick={onEditClick}>
          Edit
        </Button>
        {!project.active && (
          <>
            <Button key="finish" variant="success" icon="power" onClick={() => handleActivate()}>
              Activate
            </Button>
          </>
        )}
      </Card.Actions>
      <Card.SecondaryActions className="justify-end">
        {project.id !== currentProjectId && (
          <a href={`/?orgId=${project.id}`} className="inline-flex">
            <IconButton name="external-link-alt" aria-label={`go to project ${project.name}`} tooltip="Go to Project" />
          </a>
        )}
        <IconButton
          name="trash-alt"
          aria-label={`delete project ${project.name}`}
          tooltip={!project.active ? 'Delete project' : 'Cannot delete active project'}
          disabled={project.active}
          onClick={() => handleDelete()}
        />
      </Card.SecondaryActions>
      {ConfirmComponent}
      {ConfirmActivateComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

export default ProjectListItem;
