import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal } from '@grafana/ui';
import { PUT } from 'client';
import React from 'react';
import type { Project } from '../../types';
import ProjectForm, { ProjectFormValues } from './ProjectForm';

export default function ProjectEditModal({ project, onClose }: { project: Project; onClose: () => void }) {
  const handleSaveProject = async (values: ProjectFormValues) => {
    const appEvents = getAppEvents();
    try {
      const response = await PUT('/api/projects/{id}', {
        params: {
          path: {
            id: project.id,
          },
        },
        body: values,
      });
      if (response.error) {
        throw new Error(response.error.message);
      }
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Project started successfully'],
      });
      onClose();
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Failed to start project: ${e instanceof Error ? e.message : e}`],
      });
    }
  };

  return (
    <Modal title="Edit Project" isOpen={true} onDismiss={onClose}>
      <ProjectForm
        onSubmit={handleSaveProject}
        defaultValues={project}
        actions={
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Modal.ButtonRow>
        }
      />
    </Modal>
  );
}
