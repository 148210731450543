import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { ClickOutsideWrapper, ColorPicker, colors, Icon, IconButton, Input, Portal, useStyles2 } from '@grafana/ui';
import { Popover } from '@headlessui/react';
import { components } from 'api';
import { AnimatePresence, LayoutGroup, m } from 'framer-motion';
import { Activity, Disc, Import, LucideIcon } from 'lucide-react';
import {
  channelGroupListObservable,
  channelListObservable,
  currentProjectIdObservable,
  recordListObservable,
} from 'observables';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { distinct, map } from 'rxjs';
import stringHash from 'string-hash';
import { DELETE, PUT } from '../client';
import useConfirm from '../hooks/useConfirm';
import useObservable from '../hooks/useObservable';
import ChannelMenu from './ChannelMenu';
import { appState } from './SimplePanel';

type Channel = components['schemas']['Channel'];
type ChannelGroup = components['schemas']['ChannelGroup'];

const getStyles = (theme: GrafanaTheme2) => {
  return {
    channelItem: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 4,
      '&:hover': {
        backgroundColor: theme.colors.emphasize(theme.colors.background.canvas, 0.1),
      },
    }),
    activeChannelItem: css({
      backgroundColor: theme.colors.emphasize(theme.colors.background.canvas, 0.1),
    }),
  };
};

type ChannelGroupWithChannels = ChannelGroup & {
  channels: Channel[];
  immutable?: boolean;
};

// const getColors = (theme: GrafanaTheme2) =>
//   theme.visualization.palette.filter(
//     (color) =>
//       colorManipulator.getContrastRatio(theme.visualization.getColorByName(color), theme.colors.background.primary) >=
//       theme.colors.contrastThreshold
//   );

const ChannelGroups: React.FC<{
  height: number;
  reducedMotion: 'user' | 'always';
  onChannelGroupSelected: (channelGroup: ChannelGroup) => void;
}> = ({ height, reducedMotion, onChannelGroupSelected }) => {
  // const colors = useMemo(() => getColors(theme), [theme]);
  // const [channels, setChannels] = React.useState<Channel[]>([]);
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const channels = useObservable(channelListObservable, []);
  const channelGroups = useObservable(channelGroupListObservable, []);
  const recordList = useObservable(recordListObservable, []);
  // const [channelGroups, setChannelGroups] = React.useState<ChannelGroupWithChannels[]>([]); // data.series[0].fields[0].values[0
  const [groupedRealtimeChannels, setGroupedRealtimeChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [groupedImportedChannels, setGroupedImportedChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [groupedRecordedChannels, setGroupedRecordedChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>({ 0: true, 1: true, 2: true });
  const [recordedExpanded, setRecordedExpanded] = React.useState<Record<number, boolean>>({});
  // const [hiddenChannels, setHiddenChannels] = React.useState<string[]>([]);
  const groupNameEditing = useObservable(
    appState.pipe(
      distinct(({ groupNameEditing }) => groupNameEditing),
      map((x) => x.groupNameEditing)
    ),
    null
  );
  const [ConfirmDeleteModal, openConfirmDeleteModal] = useConfirm<{
    groupId: number;
  }>({
    title: 'Delete Channel Group',
    body: 'Are you sure you want to delete this channel group?',
    confirmText: 'Delete',
    onConfirm: async (props) => {
      if (!props) {
        return;
      }
      await DELETE('/api/channel-groups/{id}', {
        params: {
          path: { id: props.groupId },
        },
      });
    },
  });

  const realtimeChannels = useMemo(() => channels.filter((channel) => !!channel.dataflowId), [channels]);
  const importedChannels = useMemo(() => channels.filter((channel) => !!channel.importId), [channels]);
  const recordedChannels = useMemo(() => channels.filter((channel) => !!channel.recordId), [channels]);

  // useEffect(() => {
  //   // move channel without group to default group

  //   const groups = [
  //     { id: 0, name: 'Default Group', channels: [] as Channel[], immutable: true },
  //     ...channelGroups.map((group) => ({ ...group, channels: [] as Channel[] })),
  //   ];

  //   channels.forEach((channel) => {
  //     if (channel.groups.length === 0) {
  //       groups[0].channels.push(channel);
  //       return;
  //     }
  //     for (const chGroup of channel.groups) {
  //       const group = groups.find((group) => group.name === chGroup);
  //       if (group) {
  //         group.channels.push(channel);
  //       }
  //     }
  //   });

  //   setExpanded(groups.reduce((acc, group) => ({ ...acc, [group.id]: true }), {} as Record<number, boolean>));

  //   setGroupedChannels(groups);
  // }, [channelGroups, channels]);

  useEffect(() => {
    const realtimeChannelGroupNames = realtimeChannels.flatMap((channel) => channel.groups);
    const realtimeChannelGroups = channelGroups.filter((group) => realtimeChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, project_id: currentProjectId },
      ...realtimeChannelGroups.map((group) => ({ ...group, channels: [] as Channel[] })),
    ];

    for (const channel of realtimeChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    setGroupedRealtimeChannels(groups);
  }, [realtimeChannels, channelGroups, currentProjectId]);

  useEffect(() => {
    const importedChannelGroupNames = importedChannels.flatMap((channel) => channel.groups);
    const importedChannelGroups = channelGroups.filter((group) => importedChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, project_id: currentProjectId },
      ...importedChannelGroups.map((group) => ({ ...group, channels: [] as Channel[] })),
    ];

    for (const channel of importedChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    setGroupedImportedChannels(groups);
  }, [importedChannels, channelGroups, currentProjectId]);

  useEffect(() => {
    const recordedChannelGroupNames = recordedChannels.flatMap((channel) => channel.groups);
    const recordedChannelGroups = channelGroups.filter((group) => recordedChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, project_id: currentProjectId },
      ...recordedChannelGroups.map((group) => ({ ...group, channels: [] as Channel[] })),
    ];

    for (const channel of recordedChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    setGroupedRecordedChannels(groups);
  }, [recordedChannels, channelGroups, currentProjectId]);

  return (
    <>
      <LayoutGroup>
        <CategorizedChannelGroups
          title="Realtime"
          icon={Activity}
          expand={expanded[0]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 0: expand }))}
          channelGroups={channelGroups}
          groupedChannels={groupedRealtimeChannels}
          setGroupedChannels={setGroupedRealtimeChannels}
          reducedMotion={reducedMotion}
          groupNameEditing={groupNameEditing}
          height={height}
          onChannelGroupSelected={onChannelGroupSelected}
          openConfirmDeleteModal={openConfirmDeleteModal}
          defaultExpanded
        />
        <CategorizedChannelGroups
          title="Imported"
          icon={Import}
          expand={expanded[1]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 1: expand }))}
          channelGroups={channelGroups}
          groupedChannels={groupedImportedChannels}
          setGroupedChannels={setGroupedImportedChannels}
          reducedMotion={reducedMotion}
          groupNameEditing={groupNameEditing}
          height={height}
          onChannelGroupSelected={onChannelGroupSelected}
          openConfirmDeleteModal={openConfirmDeleteModal}
        />
        <CategoryAccordion
          title="Recorded"
          icon={Disc}
          expand={expanded[2]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 2: expand }))}
          reducedMotion={reducedMotion}
        >
          <m.div className="pl-6 pr-2 py-1">
            {recordList.map((record) => (
              <div key={record.id} className="py-1">
                <CategorizedChannelGroups
                  title={record.testId}
                  expand={recordedExpanded[record.id]}
                  onExpandChange={(expand) => setRecordedExpanded((expanded) => ({ ...expanded, [record.id]: expand }))}
                  channelGroups={channelGroups}
                  groupedChannels={groupedRecordedChannels
                    .filter((group) => group.channels.some((channel) => channel.recordId === record.id))
                    .map((group) => ({
                      ...group,
                      channels: group.channels.filter((channel) => channel.recordId === record.id),
                    }))}
                  setGroupedChannels={setGroupedRecordedChannels}
                  reducedMotion={reducedMotion}
                  groupNameEditing={groupNameEditing}
                  height={height}
                  onChannelGroupSelected={onChannelGroupSelected}
                  openConfirmDeleteModal={openConfirmDeleteModal}
                  classes={{
                    title: '!text-white',
                  }}
                />
              </div>
            ))}
          </m.div>
        </CategoryAccordion>
        {/* <CategorizedChannelGroups
          title="Recorded"
          icon={Disc}
          expand={expanded[2]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 2: expand }))}
          channelGroups={channelGroups}
          groupedChannels={groupedRecordedChannels}
          setGroupedChannels={setGroupedRecordedChannels}
          reducedMotion={reducedMotion}
          groupNameEditing={groupNameEditing}
          height={height}
          onChannelGroupSelected={onChannelGroupSelected}
          openConfirmDeleteModal={openConfirmDeleteModal}
        /> */}
      </LayoutGroup>
      {ConfirmDeleteModal}
    </>
  );
};

const CategoryAccordion: React.FC<{
  title: string;
  icon?: LucideIcon;
  expand: boolean;
  onExpandChange: (expanded: boolean) => void;
  reducedMotion?: 'user' | 'always';
  titleClass?: string;
  children: React.ReactNode;
}> = ({ title, icon: TitleIcon, expand, onExpandChange, reducedMotion, titleClass, children }) => {
  return (
    <m.div layout={reducedMotion === 'user' ? 'position' : false} className={!expand ? 'mb-2' : 'mb-1'}>
      <div className="flex items-center" role="button" onClick={() => onExpandChange(!expand)}>
        {TitleIcon && <TitleIcon className="mr-2 h-4 w-4" />}
        <h6 className={cx('text-base text-indigo-200 m-0 font-bold tracking-wider', titleClass)}>{title}</h6>
        <Icon
          name="angle-down"
          size="xl"
          className="ml-auto mr-0"
          style={{
            transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
      <AnimatePresence>{expand && children}</AnimatePresence>
    </m.div>
  );
};

const CategorizedChannelGroups = ({
  title,
  icon: TitleIcon,
  expand,
  onExpandChange,
  channelGroups,
  groupedChannels,
  setGroupedChannels,
  reducedMotion,
  groupNameEditing,
  height,
  onChannelGroupSelected,
  openConfirmDeleteModal,
  defaultExpanded = false,
  classes,
}: {
  title: string;
  icon?: LucideIcon;
  expand: boolean;
  onExpandChange: (expanded: boolean) => void;
  channelGroups: ChannelGroup[];
  groupedChannels: ChannelGroupWithChannels[];
  setGroupedChannels: React.Dispatch<React.SetStateAction<ChannelGroupWithChannels[]>>;
  reducedMotion: 'user' | 'always';
  groupNameEditing: number | null;
  height: number;
  onChannelGroupSelected: (channelGroup: ChannelGroup) => void;
  openConfirmDeleteModal: (props: { confirmProps: { groupId: number } }) => void;
  defaultExpanded?: boolean;
  classes?: {
    title?: string;
  };
}) => {
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>(
    groupedChannels.reduce((acc, group) => {
      acc[group.id] = true;
      return acc;
    }, {} as Record<number, boolean>)
  );

  useEffect(() => {
    if (!defaultExpanded) {
      return;
    }
    setExpanded(
      groupedChannels.reduce((acc, group) => {
        acc[group.id] = true;
        return acc;
      }, {} as Record<number, boolean>)
    );
  }, [groupedChannels, defaultExpanded]);

  return (
    <m.div layout={reducedMotion === 'user' ? 'position' : false} className={!expand ? 'mb-2' : 'mb-1'}>
      <div className="flex items-center" role="button" onClick={() => onExpandChange(!expand)}>
        {TitleIcon && <TitleIcon className="mr-2 h-4 w-4" />}
        <h6 className={cx('text-base text-indigo-200 m-0 font-bold tracking-wider', classes?.title)}>{title}</h6>
        <Icon
          name="angle-down"
          size="xl"
          className="ml-auto mr-0"
          style={{
            transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
      <AnimatePresence mode="popLayout">
        {expand && (
          <div className="py-1">
            {groupedChannels.map((group) => (
              <React.Fragment key={group.id}>
                <m.div
                  layout={reducedMotion === 'user' ? 'position' : false}
                  role="button"
                  className="flex items-center pl-6 pr-2 py-1"
                  onClick={() => setExpanded({ ...expanded, [group.id]: !expanded[group.id] })}
                >
                  <div className="flex items-center">
                    {groupNameEditing === group.id ? (
                      <ClickOutsideWrapper
                        onClick={() => {
                          appState.next({ ...appState.getValue(), groupNameEditing: null });
                        }}
                      >
                        <form
                          onSubmit={async (ev) => {
                            ev.preventDefault();
                            appState.next({ ...appState.getValue(), groupNameEditing: null });
                            await PUT('/api/channel-groups/{id}', {
                              params: {
                                path: { id: group.id },
                              },
                              body: group,
                            });
                          }}
                        >
                          <Input
                            value={group.name}
                            onChange={(ev) => {
                              group.name = ev.currentTarget.value;
                              setGroupedChannels((groupedChannels) => [...groupedChannels]);
                            }}
                            onBlur={async () => {
                              appState.next({ ...appState.getValue(), groupNameEditing: null });
                              await PUT('/api/channel-groups/{id}', {
                                params: {
                                  path: { id: group.id },
                                },
                                body: group,
                              });
                            }}
                          />
                        </form>
                      </ClickOutsideWrapper>
                    ) : (
                      <>
                        <p className="text-base m-0 font-semibold">
                          {group.name} ({group.channels.length})
                        </p>
                        {!group.immutable && (
                          <IconButton
                            name="pen"
                            aria-label="edit"
                            className={css({ marginLeft: 8 })}
                            size="sm"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              appState.next({ ...appState.getValue(), groupNameEditing: group.id });
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex items-center ml-auto mr-0">
                    {groupNameEditing !== group.id && (
                      <>
                        {!group.immutable && (
                          <>
                            <IconButton
                              name="sliders-v-alt"
                              aria-label=""
                              size="sm"
                              className="mr-2"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                onChannelGroupSelected({
                                  id: group.id,
                                  name: group.name,
                                  project_id: group.project_id,
                                });
                              }}
                            />
                            <IconButton
                              name="trash-alt"
                              aria-label="delete"
                              size="sm"
                              className="mr-2"
                              onClick={async (event) => {
                                event.stopPropagation();
                                openConfirmDeleteModal({ confirmProps: { groupId: group.id } });
                              }}
                            />
                          </>
                        )}
                        {/* <IconButton
                  name={
                    group.channels.every((channel) => hiddenChannels.includes(channel.name)) ? 'eye-slash' : 'eye'
                  }
                  aria-label=""
                  size="sm"
                  style={{ marginRight: 8 }}
                  onClick={async (event) => {
                    event.stopPropagation();
                    if (group.channels.every((channel) => hiddenChannels.includes(channel.name))) {
                      for (const channel of group.channels) {
                        channel.plottingAttributes.hidden = false;
                      }
                      await PUT('/api/channels', {
                        body: group.channels,
                      });
                    } else {
                      for (const channel of group.channels) {
                        channel.plottingAttributes.hidden = true;
                      }
                      await PUT('/api/channels', {
                        body: group.channels,
                      });
                    }
                  }}
                /> */}
                      </>
                    )}
                    <Icon
                      name="angle-down"
                      size="xl"
                      style={{
                        transform: expanded[group.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </div>
                </m.div>
                <AnimatePresence mode="popLayout">
                  {expanded[group.id] && (
                    <m.div
                      key={group.id}
                      layout={reducedMotion === 'user'}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="pl-6"
                      style={{
                        maxHeight: height - 32,
                        overflow: 'auto',
                        paddingBottom: 4,
                      }}
                    >
                      {group.channels.map((channel) => (
                        <m.div layout={reducedMotion === 'user'} key={channel.name}>
                          <ChannelItem
                            channel={channel}
                            rerenderChannels={() => setGroupedChannels((groupedChannels) => [...groupedChannels])}
                            hiddenChannels={[]}
                            colors={colors}
                            groups={channelGroups}
                          />
                        </m.div>
                      ))}
                    </m.div>
                  )}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </div>
        )}
      </AnimatePresence>
    </m.div>
  );
};

const ChannelItem: React.FC<{
  channel: Channel;
  rerenderChannels: () => void;
  hiddenChannels: string[];
  colors: string[];
  groups: ChannelGroup[];
}> = ({ channel, rerenderChannels, hiddenChannels, colors, groups }) => {
  const styles = useStyles2(getStyles);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={cx(styles.channelItem, open && styles.activeChannelItem)}
            as="div"
            role="button"
          >
            <div className="flex items-center">
              <div
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
                className={css({
                  '& button': { width: 14, height: 14 },
                })}
              >
                <ColorPicker
                  color={
                    (channel.plottingAttributes.color as string) ||
                    colors[Math.abs(stringHash(channel.name)) % colors.length]
                  }
                  onChange={async (color) => {
                    channel.plottingAttributes.color = color;
                    rerenderChannels();
                    await PUT('/api/channels/{name}', {
                      params: {
                        path: { name: channel.name },
                      },
                      body: channel,
                    });
                  }}
                />
              </div>
              <p className="m-0 ml-2">{channel.name}</p>
            </div>
            <div className="flex items-center">
              {/* <IconButton
                name={hiddenChannels.includes(channel.name) ? 'eye-slash' : 'eye'}
                aria-label=""
                size="sm"
                style={{ marginRight: 8 }}
                onClick={async (event) => {
                  event.stopPropagation();
                  channel.plottingAttributes.hidden = !hiddenChannels.includes(channel.name);
                  await PUT('/api/channels/{name}', {
                    params: {
                      path: { name: channel.name },
                    },
                    body: channel,
                  });
                }}
              /> */}
              <Icon name="angle-right" size="sm" />
            </div>
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={(ref) => {
                setPopperElement(ref);
              }}
              style={popperStyles.popper}
              // className={css({
              //   zIndex: 1300,
              // })}
              {...attributes.popper}
            >
              <ChannelMenu channel={channel} groups={groups} colors={colors} />
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default ChannelGroups;
