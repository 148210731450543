import { WS_API_URL } from 'common';
import React, { useEffect, useMemo, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import DerivativeListItem from './DerivativeListItem';
import { Dataflow, Derivative } from '../../types';
import dayjs from 'dayjs';
import { currentProjectIdObservable } from '../../observables';
import useObservable from '../../hooks/useObservable';
import { Icon, Input } from '@grafana/ui';

const DerivativeList = () => {
  const [derivatives, setDerivatives] = useState<Derivative[]>([]);
  const projectId = useObservable(currentProjectIdObservable, 0);
  const [keywords, setKeywords] = useState('');

  const filteredDerivatives = useMemo(() => {
    if (!keywords) {
      return derivatives;
    }
    return derivatives.filter((derivative) => {
      return derivative.name.toLowerCase().includes(keywords.toLowerCase());
    });
  }, [derivatives, keywords]);

  useEffect(() => {
    if (projectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/derivatives/watch?project_id=' + projectId);

    ws.onmessage = (event) => {
      const derivatives = JSON.parse(event.data) as Derivative[];
      setDerivatives(derivatives.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  return (
    <div className="flex flex-col">
      <div className="flex mb-2 p-1 space-x-1">
        <Input
          placeholder="Search Derived Channels"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
      </div>
      {filteredDerivatives.map((derivative) => (
        <DerivativeListItem key={derivative.id} derivative={derivative} />
      ))}
      {derivatives.length === 0 ? (
        <div className="p-2 text-center text-gray-500">No derived channels</div>
      ) : filteredDerivatives.length === 0 ? (
        <div className="p-2 text-center text-gray-500">No derived channels found</div>
      ) : null}
    </div>
  );
};

export default DerivativeList;
