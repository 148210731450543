import React, { useEffect, useState } from 'react';
import { Checkbox, IconButton } from '@grafana/ui';
import { Channel, SimpleOptions } from '../../types';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WS_API_URL } from 'common';
import { PUT } from '../../client';
import { css, cx } from '@emotion/css';

interface ChannelGroupChannelToggleListProps {
  options: SimpleOptions;
  onOptionsChange: (options: SimpleOptions) => void;
}

const ChannelGroupChannelToggleList: React.FC<ChannelGroupChannelToggleListProps> = ({ options, onOptionsChange }) => {
  const selectedChannelGroup = options.selectedChannelGroup;
  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(`${WS_API_URL}/api/channels/watch`);

    ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.data) as Channel[];
      setChannels(data);
    });

    return () => {
      ws.close();
    };
  }, []);

  const handleChange = (channel: Channel) => async (event: React.FormEvent<HTMLInputElement>) => {
    if (!selectedChannelGroup) {
      return;
    }

    const checked = event.currentTarget.checked;
    const channelGroups = channel.groups;

    if (checked) {
      channelGroups.push(selectedChannelGroup.name);
    } else {
      channelGroups.splice(channelGroups.indexOf(selectedChannelGroup.name), 1);
    }

    try {
      await PUT('/api/channels/{name}', {
        params: {
          path: {
            name: channel.name,
          },
        },
        body: {
          ...channel,
          groups: channelGroups,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (!selectedChannelGroup) {
    return null;
  }

  return (
    <>
      <div className="flex items-center space-x-2 p-1">
        <IconButton
          name="arrow-left"
          aria-label=""
          size="xxl"
          onClick={() => {
            onOptionsChange({
              ...options,
              view: 'channels',
              selectedChannelGroup: null,
            });
          }}
        />
        <p className="my-0 font-semibold">{selectedChannelGroup.name}</p>
      </div>
      <div className="mt-4 list-none flex flex-col">
        {channels.map((channel) => (
          <Checkbox
            key={channel.name}
            label={channel.name}
            value={channel.groups.includes(selectedChannelGroup.name)}
            onChange={handleChange(channel)}
            className={cx(
              'flex space-x-2 hover:bg-black/10 hover:dark:bg-white/10 p-1 cursor-pointer',
              css({
                '& > span': {
                  fontSize: '1em',
                },
                '& input': {
                  cursor: 'pointer',
                },
              })
            )}
          />
        ))}
      </div>
    </>
  );
};

export default ChannelGroupChannelToggleList;
