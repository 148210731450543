import React, { useEffect, useState } from 'react';
import { FunctionPinLevel, TypedFunction } from 'types';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WS_API_URL } from 'common';
import FunctionListItem from './FunctionListItem';

const FunctionList = () => {
  const [functions, setFunctions] = useState<TypedFunction[]>([]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(`${WS_API_URL}/api/typed-functions/watch`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data) as TypedFunction[];
      setFunctions(
        data
          .filter((fn) => !fn.function.dataflowId && !fn.function.importId)
          .sort((a, b) => getPinLevelIndex(b.function.pinLevel) - getPinLevelIndex(a.function.pinLevel))
      );
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="flex flex-col">
      {functions.map((fn) => (
        <FunctionListItem key={fn.function.name} fn={fn} />
      ))}
    </div>
  );
};

const getPinLevelIndex = (pinLevel: FunctionPinLevel) => {
  return ['UNPINNED', 'PINNED', 'SYSTEM'].indexOf(pinLevel);
};

export default FunctionList;
