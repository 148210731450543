import { Field, Input, Select, TextArea } from '@grafana/ui';
import useObservable from 'hooks/useObservable';
import { projectUserListObservable } from 'observables';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const userSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.union([z.literal(''), z.string().email()]),
  login: z.string().min(1, 'Username is required for login'),
  password: z.string().min(1, 'Password is required'),
  role: z.enum(['Admin', 'Developer', 'User', 'Client']),
});

export type UserFormValues = z.infer<typeof userSchema>;

type UserFormProps = {
  defaultValues?: UserFormValues;
  actions?: React.ReactNode;
  onSubmit?: (values: UserFormValues) => void;
};

export default function UserForm({ defaultValues, actions, onSubmit }: UserFormProps) {
  const users = useObservable(projectUserListObservable, []);
  const userNames = useMemo(() => users.map((user) => user.name), [users]);
  const form = useForm<UserFormValues>({
    defaultValues: defaultValues ?? {
      name: '',
      email: '',
      login: '',
      password: '',
      role: 'Client',
    },
    resolver: zodResolver(!defaultValues ? userSchema : userSchema.omit({ password: true })),
    reValidateMode: 'onChange',
  });

  return (
    <form className="gap-2 flex flex-col" onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}>
      <Field label="Name" invalid={!!form.formState.errors.name} error={form.formState.errors.name?.message} required>
        <Input {...form.register('name')} />
      </Field>
      <Field label="Email" invalid={!!form.formState.errors.email} error={form.formState.errors.email?.message}>
        <Input {...form.register('email')} />
      </Field>
      <Field
        label="Login"
        invalid={!!form.formState.errors.login}
        error={form.formState.errors.login?.message}
        required
      >
        <Input {...form.register('login')} />
      </Field>
      <Field
        label="Password"
        invalid={!!form.formState.errors.password}
        error={form.formState.errors.password?.message}
        required
      >
        <Input {...form.register('password')} />
      </Field>
      <Field label="Role" invalid={!!form.formState.errors.role} error={form.formState.errors.role?.message} required>
        <Controller
          control={form.control}
          name="role"
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => field.onChange(v.value)}
              options={[
                {
                  label: 'Admin',
                  value: 'Admin',
                },
                {
                  label: 'Developer',
                  value: 'Developer',
                },
                {
                  label: 'User',
                  value: 'User',
                },
                {
                  label: 'Client',
                  value: 'Client',
                },
              ]}
            />
          )}
        />
      </Field>
      {actions}
    </form>
  );
}
