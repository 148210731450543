import { Button, Checkbox, Modal } from '@grafana/ui';
import { ProjectUser } from '../../types';
import React from 'react';
import { getAppEvents } from '@grafana/runtime';
import { DELETE } from '../../client';
import { currentProjectIdObservable } from '../../observables';
import { AppEvents } from '@grafana/data';

type UserDeleteModalProps = {
  user: ProjectUser;
  onClose: () => void;
};

export default function UserDeleteModal({ user, onClose }: UserDeleteModalProps) {
  const [removeFromAllProjects, setRemoveFromAllProjects] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const appEvents = getAppEvents();

    try {
      let error;
      if (!removeFromAllProjects) {
        const response = await DELETE(`/api/projects/{project_id}/users/{user_id}`, {
          params: {
            path: {
              project_id: currentProjectIdObservable.value,
              user_id: user.userId,
            },
          },
        });
        error = response.error;
      } else {
        const response = await DELETE(`/api/users/{user_id}`, {
          params: {
            path: {
              user_id: user.userId,
            },
          },
        });
        error = response.error;
      }
      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Delete user (${user.name}) Successfully`],
        });
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete user (${user.name}) failed: ${error.message}`],
        });
      }
    } catch (err) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Delete user (${user.name}) failed: ${err}`],
      });
    }
  };

  return (
    <Modal title={`Delete User (${user.name || user.login})`} isOpen={true} onDismiss={onClose}>
      <form onSubmit={handleSubmit}>
        <p>
          Are you sure you want to remove the user <span className="font-bold">{user.name}</span> from this project?
        </p>
        <Checkbox
          label="Remove from all projects"
          description="This will remove the user from all projects. User cannot log in to Grafana after this action."
          value={removeFromAllProjects}
          onChange={(event) => setRemoveFromAllProjects(event.currentTarget.checked)}
        />
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Delete</Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  );
}
