import { PanelPlugin } from '@grafana/data';
import '../../../styles/dist/index.css';
import { SimplePanel } from './components/SimplePanel';
import { userObservable } from './observables';
import { SimpleOptions } from './types';

// @ts-ignore
export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(async (builder) => {
  let user = userObservable.value;

  if (!user) {
    let resolve: (value: void) => void;
    const promise = new Promise((_resolve) => {
      resolve = _resolve;
    });
    const sub = userObservable.subscribe((newUser) => {
      user = newUser;
      resolve();
    });
    await promise;
    sub.unsubscribe();
  }

  return builder.addSelect({
    path: 'view',
    defaultValue: 'channels',
    name: 'Manager',
    settings: {
      options: [
        {
          value: 'channels',
          label: 'Channel',
        },
        {
          value: 'dataflows',
          label: 'Dataflow',
        },
        {
          value: 'dataImports',
          label: 'Data Import',
        },
        ...(user?.userRole === 'Admin' || user?.userRole === 'Developer'
          ? [
              {
                value: 'functions',
                label: 'Function',
              },
              {
                value: 'records',
                label: 'Record',
              },
              {
                value: 'eventTriggers',
                label: 'Event Trigger',
              },
            ]
          : []),
        ...(user?.userRole === 'Admin'
          ? [
              {
                value: 'projects',
                label: 'Project',
              },
              {
                value: 'users',
                label: 'User',
              },
            ]
          : []),
        ...(user?.userRole === 'User' || user?.userRole === 'Admin' || user?.userRole === 'Developer'
          ? [
              {
                value: 'events',
                label: 'Event',
              },
              {
                value: 'dataExport',
                label: 'Data Export',
              },
              {
                value: 'derivatives',
                label: 'Derived Channel',
              },
            ]
          : []),
      ],
    },
  });
});

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    // refresh token
    fetch('/api/user/auth-tokens/rotate', {
      method: 'POST',
      credentials: 'include',
    });
  }
});
