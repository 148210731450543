import useObservable from 'hooks/useObservable';
import { projectUserListObservable } from 'observables';
import React, { useEffect, useMemo, useState } from 'react';
import type { ProjectUser, ProjectUserRole } from '../../types';
import UserEditModal from './UserEditModal';
import UserListItem from './UserListItem';
import { Icon, Input, MultiSelect } from '@grafana/ui';

const UserList = () => {
  const users = useObservable(projectUserListObservable, []);
  const [selectedUser, setSelectedUser] = useState<ProjectUser | null>(null);
  const [keywords, setKeywords] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<ProjectUserRole[]>([]);

  const filteredUsers = useMemo(() => {
    if (!keywords && selectedRoles.length === 0) {
      return users;
    }
    return users.filter((user) => {
      return (
        (keywords === '' ||
          user.name?.toLowerCase().includes(keywords.toLowerCase()) ||
          user.email?.toLowerCase().includes(keywords.toLowerCase()) ||
          user.login?.toLowerCase().includes(keywords.toLowerCase())) &&
        (selectedRoles.length === 0 || selectedRoles.includes(user.userRole || 'Client'))
      );
    });
  }, [users, keywords, selectedRoles]);

  useEffect(() => {
    if (selectedUser) {
      const index = users.findIndex((user) => user.userId === selectedUser.userId);
      if (index === -1) {
        setSelectedUser(null);
      } else {
        setSelectedUser(users[index]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <div className="flex flex-col -mt-4">
      <div className="flex mb-1 p-1 space-x-1">
        <Input
          placeholder="Search Users"
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
        />
        <MultiSelect
          className="max-w-48"
          placeholder="Role"
          prefix={<Icon name="user" />}
          options={[
            {
              label: 'Admin',
              value: 'Admin',
            },
            {
              label: 'Developer',
              value: 'Developer',
            },
            {
              label: 'User',
              value: 'User',
            },
            {
              label: 'Client',
              value: 'Client',
            },
          ]}
          value={selectedRoles}
          onChange={(v) => {
            setSelectedRoles(v.map((item) => item.value as ProjectUserRole));
          }}
        />
      </div>
      {filteredUsers.map((user) => (
        <UserListItem
          key={user.userId}
          user={user}
          onEditClick={() => {
            setSelectedUser(user);
          }}
        />
      ))}
      {filteredUsers.length === 0 && (
        <div className="flex flex-col items-center justify-center h-full mt-4">
          <p className="text-gray-500">No users</p>
        </div>
      )}
      {selectedUser && <UserEditModal user={selectedUser} onClose={() => setSelectedUser(null)} />}
    </div>
  );
};

export default UserList;
