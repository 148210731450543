import { paths } from 'api';
import { API_URL } from 'common';
import createClient from 'openapi-fetch';
import { currentProjectIdObservable } from './observables';

let { GET, POST, PUT, DELETE } = createClient<paths>({
  baseUrl: API_URL,
  headers: {
    project_id: currentProjectIdObservable.value,
  },
});

currentProjectIdObservable.subscribe((projectId) => {
  ({ GET, POST, PUT, DELETE } = createClient<paths>({
    baseUrl: API_URL,
    headers: {
      'project-id': projectId,
    },
    // @ts-ignore
    fetch: customFetch,
  }));
});

async function customFetch(input: Parameters<typeof fetch>[0], init?: Parameters<typeof fetch>[1]): Promise<Response> {
  let response = await fetch(input, init);

  if (response.status === 401) {
    try {
      await fetch('/api/user/auth-tokens/rotate', {
        method: 'POST',
        credentials: 'include',
      });
      response = await fetch(input, init);
    } catch (error) {
      throw error;
    }
  }

  return response;
}

export { DELETE, GET, POST, PUT };
