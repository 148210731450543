import React, { useEffect } from 'react';
import { useTheme2 } from '@grafana/ui';

interface UpdateBodyThemeProps {}

const UpdateBodyTheme: React.FC<UpdateBodyThemeProps> = () => {
  const theme = useTheme2();

  useEffect(() => {
    if (theme.isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme.isDark]);

  return null;
};

export default UpdateBodyTheme;
