import { Button, Drawer, DropzoneFile, FileListItem, Icon, useTheme2 } from '@grafana/ui';
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';
import { API_URL } from 'common';

type UploadFilesDrawerProps = {
  onClose: () => void;
};

export default function FileManagerDrawer({ onClose }: UploadFilesDrawerProps) {
  const theme = useTheme2();
  const [files, setFiles] = useState<DropzoneFile[]>([]);
  const abortController = useRef<AbortController | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((files) => files.concat(acceptedFiles.map((file) => ({ file, id: v4(), error: null }))));
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleUpload = async () => {
    setProgress(0);
    setIsLoading(true);
    abortController.current = new AbortController();

    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('file', file.file);
      });
      await axios.postForm(`${API_URL}/api/files`, formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(Math.round((progressEvent.loaded / (progressEvent.total ?? 0)) * 100));
        },
        signal: abortController.current.signal,
      });
      onClose();
      setFiles([]);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer onClose={onClose} title="Upload Files" size="md">
      <div className="border border-dashed border-gray-600 w-full rounded-md p-4">
        <div {...getRootProps()} className="relative cursor-pointer">
          <input {...getInputProps()} />
          <div className="flex items-center justify-center py-4">
            <div className="flex flex-col items-center space-y-2">
              <Icon name="upload" className="w-7 h-7" />
              <span className="font-medium text-center">Upload file</span>
            </div>
          </div>
          {isDragActive && (
            <div
              className="absolute inset-0 flex items-center justify-center"
              style={{ backgroundColor: theme.colors.background.secondary }}
            >
              <span className="font-medium text-center text-white">Drop the files here ...</span>
            </div>
          )}
        </div>
        {files.length > 0 && (
          <div className="pt-4">
            <p className="font-medium text-base mb-0.5">Files ({files.length})</p>
            <div className="flex flex-col space-y-2 max-h-96 overflow-y-auto">
              {files.map((file: DropzoneFile) => (
                <FileListItem
                  key={file.id}
                  file={file}
                  removeFile={(file) => {
                    setFiles(files.filter((f) => f.id !== file.id));
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <Button
          type="button"
          size="md"
          className="mt-4 group"
          disabled={files.length === 0}
          onClick={() => {
            if (isLoading) {
              abortController.current?.abort();
              setIsLoading(false);
            } else {
              handleUpload().catch(() => {});
            }
          }}
        >
          {isLoading && <Icon name="fa fa-spinner" className="mr-2 inline-flex group-hover:hidden" />}
          {isLoading ? (
            <>
              <span className="inline-flex group-hover:hidden">Uploading ({progress}%)</span>
              <span className="hidden group-hover:inline-flex">Cancel</span>
            </>
          ) : (
            'Upload'
          )}
        </Button>
      </div>
    </Drawer>
  );
}
