import { Button, FormAPI } from '@grafana/ui';
import React from 'react';
import {
  DataflowProcessInput,
  ImportFunctionInput,
  SinkFunctionInput,
  SourceFunctionInput,
  TypedFunction,
} from '../../types';
import DataflowAddFromExistingFunctionButton from './DataflowAddFromExistingFunctionButton';
import { DataflowFormValues } from './DataflowForm';

type DataflowNewFunctionProps =
  | {
    typedFunctions: TypedFunction[];
  } & (
    | {
      type: 'Source';
      setValue: (value: SourceFunctionInput | number) => void;
    }
    | {
      type: 'Process';
      getValues: FormAPI<DataflowFormValues>['getValues'];
      setValue: (value: DataflowProcessInput) => void;
    }
    | {
      type: 'Sink';
      setValue: (value: SinkFunctionInput | number) => void;
    }
    | {
      type: 'Import';
      setValue: (value: ImportFunctionInput | number) => void;
    }
  );

const SOURCE_FUNCTION_TEMPLATE = `
from typing import Generator, Callable

def handle(
    args: dict, 
    subscribe: Callable[[dict], Generator[dict, None, None]],
    get_channels_data: Callable[[dict], list[dict]],
) -> Generator:
    # TODO: implement your function here
    # yield data every time you want to send data to the next function
    # e.g.
    # while True:
    #     data = get_data()
    #     yield data
    #
    # subscribe() subscribe to channels and channel groups with optional historical data
    #   @return a generator that yields data from subscribed channels, one time frame at a time
    #     data will always comes with the following order:
    #       1. channel registry list with subscribed channels (full list)
    #       2. historical data if start is set
    #       3. live data
    #       *  repeat 1 and 3 if channel registry list is updated
    #       ** when one of the subscribed time frame is updated, repeat 1-3 for that time frame
    # e.g.
    # subscribe({
    #     'channels': ['ch1', 'ch2'], # optional
    #     'channel_groups': ['group1', 'group2'], # optional
    #     'start': 0.0, # (timestamp, rfc3339, relative time (e.g. -10w/d/m/s/ms/us/ns)) optional, used for historical data
    # })
    #
    # get_channels_data() get imported, recorded, and historical channels data
    #   @return a list of time frame
    # e.g.
    # data = get_channels_data({
    #    'channels': ['ch1', 'ch2'], # optional
    #    'channel_groups': ['group1', 'group2'], # optional
    #    'start': 0.0, # (timestamp, rfc3339, relative time (e.g. -10w/d/m/s/ms/us/ns)) optional, default to 0.0, relative time is based on the first received data point
    #    'end': time.time(), # timestamp only, optional, default to current time, ignored if <= start
    #    'first_n': 1000, # optional
    #    'last_n': 1000, # optional, ignored if first_n is set
    # })
`.trim();

const PROCESS_FUNCTION_TEMPLATE = `
from typing import Iterable, Generator

def handle(data: Iterable, args: dict) -> Generator:
    # TODO: implement your function here
    # loop over iterable to get data yield from previous function
    # yield data every time you want to send data to the next function
    # e.g.
    # for d in data:
    #     yield process(d)
`.trim();

const SINK_FUNCTION_TEMPLATE = `
from typing import Iterable, Callable, List

def handle(
    data: Iterable,
    args: dict,
    register: Callable[[List[dict]], None],
    send: Callable[[Union[dict, List[dict]]], None],
):
    # TODO: implement your function here
    # loop over iterable to get data yield from previous function
    # call register() to register channels and upsert attributes
    # call send() to send data to grafana, channels in same dict is considered as same time frame (same table in grafana)
    # e.g.
    # register([{ 'name': 'ch1' }, { 'name': 'ch2', 'channel_attributes': {}, 'plotting_attributes': {}])
    # import time
    # for d in data:
    #     send({
    #         'time': [time.time()], # required, [float, float, ...] | np.ndarray[float]
    #         'ch1': [d['ch1']], # [float, float, ...] | np.ndarray[float]
    #         'ch2': [d['ch2']], # [float, float, ...] | np.ndarray[float]
    #     }) # list supported
    

# Below is the type for plotting_attributes
# Use this as reference purpose only

# from pydantic import BaseModel
# from typing import List, Optional, Union

# class EnumFieldConfig(BaseModel):
#     text: Optional[List[str]] = None
#     color: Optional[List[str]] = None
#     icon: Optional[List[str]] = None
#     description: Optional[List[str]] = None

# class FieldTypeConfig(BaseModel):
#     enum: Optional[EnumFieldConfig] = None

# class Threshold(BaseModel):
#     value: float
#     color: str
#     state: Optional[str] = None

# class ThresholdsMode(str, Enum):
#     absolute = "absolute"
#     percentage = "percentage"

# class ThresholdsConfig(BaseModel):
#     mode: ThresholdsMode
#     steps: List[Threshold]

# class MappingType(str):
#     VALUE_TO_TEXT = "value"
#     RANGE_TO_TEXT = "range"
#     REGEX_TO_TEXT = "regex"
#     SPECIAL_VALUE = "special"

# class ValueMappingResult(BaseModel):
#     text: Optional[str] = None
#     color: Optional[str] = None
#     icon: Optional[str] = None
#     index: Optional[int] = None

# class BaseValueMap(BaseModel):
#     type: MappingType
#     options: Dict[str, ValueMappingResult]

# class ValueMap(BaseValueMap):
#     type: MappingType = MappingType.VALUE_TO_TEXT

# class RangeMapOptions(BaseModel):
#     from_value: Optional[Union[int, float]] = None
#     to_value: Optional[Union[int, float]] = None
#     result: ValueMappingResult

# class RangeMap(BaseValueMap):
#     type: MappingType = MappingType.RANGE_TO_TEXT
#     options: RangeMapOptions

# class RegexMapOptions(BaseModel):
#     pattern: str
#     result: ValueMappingResult

# class RegexMap(BaseValueMap):
#     type: MappingType = MappingType.REGEX_TO_TEXT
#     options: RegexMapOptions

# class SpecialValueMatch(str):
#     TRUE = "true"
#     FALSE = "false"
#     NULL = "null"
#     NAN = "nan"
#     NULL_AND_NAN = "null+nan"
#     EMPTY = "empty"

# class SpecialValueOptions(BaseModel):
#     match: SpecialValueMatch
#     result: ValueMappingResult

# class SpecialValueMap(BaseValueMap):
#     type: MappingType = MappingType.SPECIAL_VALUE
#     options: SpecialValueOptions

# class PlottingAttributes(BaseModel):
#     max_data_points_per_second: Optional[number] = None # default 24
#     hidden: Optional[bool] = None
#     description: Optional[str] = None
#     filterable: Optional[bool] = None
#     unit: Optional[str] = None # check all possible unit below
#     decimals: Optional[int] = None # decimal count
#     min: Optional[Union[int, float]] = None
#     max: Optional[Union[int, float]] = None
#     interval: Optional[Union[int, float]] = None
#     mappings: Optional[List[Union[ValueMap, RangeMap, RegexMap, SpecialValueMap]]] = None
#     thresholds: Optional[ThresholdsConfig] = None
#     color: Optional[str] = None
#     null_value_mode: Optional[str] = None
#     no_value: Optional[str] = None # null | connected | null as zero
#     type: Optional[FieldTypeConfig] = None
#     field_min_max: Optional[bool] = None


# All possible unit
# Value # Description

# none # Number
# string # String
# short # short
# sishort # SI short
# percent # Percent (0-100)
# percentunit # Percent (0.0-1.0)
# humidity # Humidity (%H)
# dB # Decibel
# candela # Candela (cd)
# hex0x # Hexadecimal (0x)
# hex # Hexadecimal
# sci # Scientific notation
# locale # Locale format
# pixel # Pixels
# accMS2 # Meters/sec²
# accFS2 # Feet/sec²
# accG # G unit
# degree # Degrees (°)
# radian # Radians
# grad # Gradian
# arcmin # Arc Minutes
# arcsec # Arc Seconds
# areaM2 # Square Meters (m²)
# areaF2 # Square Feet (ft²)
# areaMI2 # Square Miles (mi²)
# acres # Acres (ac)
# hectares # Hectares (ha)
# flops # FLOP/s
# mflops # MFLOP/s
# gflops # GFLOP/s
# tflops # TFLOP/s
# pflops # PFLOP/s
# eflops # EFLOP/s
# zflops # ZFLOP/s
# yflops # YFLOP/s
# ppm # parts-per-million (ppm)
# conppb # parts-per-billion (ppb)
# conngm3 # nanogram per cubic meter (ng/m³)
# conngNm3 # nanogram per normal cubic meter (ng/Nm³)
# conμgm3 # microgram per cubic meter (μg/m³)
# conμgNm3 # microgram per normal cubic meter (μg/Nm³)
# conmgm3 # milligram per cubic meter (mg/m³)
# conmgNm3 # milligram per normal cubic meter (mg/Nm³)
# congm3 # gram per cubic meter (g/m³)
# congNm3 # gram per normal cubic meter (g/Nm³)
# conmgdL # milligrams per decilitre (mg/dL)
# conmmolL # millimoles per litre (mmol/L)
# currencyUSD # Dollars ($)
# currencyGBP # Pounds (£)
# currencyEUR # Euro (€)
# currencyJPY # Yen (¥)
# currencyRUB # Rubles (₽)
# currencyUAH # Hryvnias (₴)
# currencyBRL # Real (R$)
# currencyDKK # Danish Krone (kr)
# currencyISK # Icelandic Króna (kr)
# currencyNOK # Norwegian Krone (kr)
# currencySEK # Swedish Krona (kr)
# currencyCZK # Czech koruna (czk)
# currencyCHF # Swiss franc (CHF)
# currencyPLN # Polish Złoty (PLN)
# currencyBTC # Bitcoin (฿)
# currencymBTC # Milli Bitcoin (฿)
# currencyμBTC # Micro Bitcoin (฿)
# currencyZAR # South African Rand (R)
# currencyINR # Indian Rupee (₹)
# currencyKRW # South Korean Won (₩)
# currencyIDR # Indonesian Rupiah (Rp)
# currencyPHP # Philippine Peso (PHP)
# currencyVND # Vietnamese Dong (VND)
# currencyTRY # Turkish Lira (₺)
# currencyMYR # Malaysian Ringgit (RM)
# currencyXPF # CFP franc (XPF)
# currencyBGN # Bulgarian Lev (BGN)
# currencyPYG # Guaraní (₲)
# currencyUYU # Uruguay Peso (UYU)
# bytes # bytes(IEC)
# decbytes # bytes(SI)
# bits # bits(IEC)
# decbits # bits(SI)
# kbytes # kibibytes
# deckbytes # kilobytes
# mbytes # mebibytes
# decmbytes # megabytes
# gbytes # gibibytes
# decgbytes # gigabytes
# tbytes # tebibytes
# dectbytes # terabytes
# pbytes # pebibytes
# decpbytes # petabytes
# pps # packets/sec
# binBps # bytes/sec(IEC)
# Bps # bytes/sec(SI)
# binbps # bits/sec(IEC)
# bps # bits/sec(SI)
# KiBs # kibibytes/sec
# Kibits # kibibits/sec
# KBs # kilobytes/sec
# Kbits # kilobits/sec
# MiBs # mebibytes/sec
# Mibits # mebibits/sec
# MBs # megabytes/sec
# Mbits # megabits/sec
# GiBs # gibibytes/sec
# Gibits # gibibits/sec
# GBs # gigabytes/sec
# Gbits # gigabits/sec
# TiBs # tebibytes/sec
# Tibits # tebibits/sec
# TBs # terabytes/sec
# Tbits # terabits/sec
# PiBs # pebibytes/sec
# Pibits # pebibits/sec
# PBs # petabytes/sec
# Pbits # petabits/sec
# dateTimeAsIso # Datetime ISO
# dateTimeAsIsoNoDateIfToday # Datetime ISO (No date if today)
# dateTimeAsUS # Datetime US
# dateTimeAsUSNoDateIfToday # Datetime US (No date if today)
# dateTimeAsLocal # Datetime local
# dateTimeAsLocalNoDateIfToday # Datetime local (No date if today)
# dateTimeAsSystem # Datetime default
# dateTimeFromNow # From Now
# watt # Watt (W)
# kwatt # Kilowatt (kW)
# megwatt # Megawatt (MW)
# gwatt # Gigawatt (GW)
# mwatt # Milliwatt (mW)
# Wm2 # Watt per square meter (W/m²)
# voltamp # Volt-Ampere (VA)
# kvoltamp # Kilovolt-Ampere (kVA)
# voltampreact # Volt-Ampere reactive (VAr)
# kvoltampreact # Kilovolt-Ampere reactive (kVAr)
# watth # Watt-hour (Wh)
# watthperkg # Watt-hour per Kilogram (Wh/kg)
# kwatth # Kilowatt-hour (kWh)
# kwattm # Kilowatt-min (kWm)
# mwatth # Megawatt-hour (MWh)
# amph # Ampere-hour (Ah)
# kamph # Kiloampere-hour (kAh)
# mamph # Milliampere-hour (mAh)
# joule # Joule (J)
# ev # Electron volt (eV)
# amp # Ampere (A)
# kamp # Kiloampere (kA)
# mamp # Milliampere (mA)
# volt # Volt (V)
# kvolt # Kilovolt (kV)
# mvolt # Millivolt (mV)
# dBm # Decibel-milliwatt (dBm)
# mohm # Milliohm (mΩ)
# ohm # Ohm (Ω)
# kohm # Kiloohm (kΩ)
# Mohm # Megaohm (MΩ)
# farad # Farad (F)
# µfarad # Microfarad (µF)
# nfarad # Nanofarad (nF)
# pfarad # Picofarad (pF)
# ffarad # Femtofarad (fF)
# henry # Henry (H)
# mhenry # Millihenry (mH)
# µhenry # Microhenry (µH)
# lumens # Lumens (Lm)
# flowgpm # Gallons/min (gpm)
# flowcms # Cubic meters/sec (cms)
# flowcfs # Cubic feet/sec (cfs)
# flowcfm # Cubic feet/min (cfm)
# litreh # Litre/hour
# flowlpm # Litre/min (L/min)
# flowmlpm # milliLitre/min (mL/min)
# lux # Lux (lx)
# forceNm # Newton-meters (Nm)
# forcekNm # Kilonewton-meters (kNm)
# forceN # Newtons (N)
# forcekN # Kilonewtons (kN)
# Hs # hashes/sec
# KHs # kilohashes/sec
# MHs # megahashes/sec
# GHs # gigahashes/sec
# THs # terahashes/sec
# PHs # petahashes/sec
# EHs # exahashes/sec
# massmg # milligram (mg)
# massg # gram (g)
# masslb # pound (lb)
# masskg # kilogram (kg)
# masst # metric ton (t)
# lengthmm # millimeter (mm)
# lengthin # inch (in)
# lengthft # feet (ft)
# lengthm # meter (m)
# lengthkm # kilometer (km)
# lengthmi # mile (mi)
# pressurembar # Millibars
# pressurebar # Bars
# pressurekbar # Kilobars
# pressurepa # Pascals
# pressurehpa # Hectopascals
# pressurekpa # Kilopascals
# pressurehg # Inches of mercury
# pressurepsi # PSI
# radbq # Becquerel (Bq)
# radci # curie (Ci)
# radgy # Gray (Gy)
# radrad # rad
# radsv # Sievert (Sv)
# radmsv # milliSievert (mSv)
# radusv # microSievert (µSv)
# radrem # rem
# radexpckg # Exposure (C/kg)
# radr # roentgen (R)
# radsvh # Sievert/hour (Sv/h)
# radmsvh # milliSievert/hour (mSv/h)
# radusvh # microSievert/hour (µSv/h)
# rotrpm # Revolutions per minute (rpm)
# rothz # Hertz (Hz)
# rotkhz # Kilohertz (kHz)
# rotmhz # Megahertz (MHz)
# rotghz # Gigahertz (GHz)
# rotrads # Radians per second (rad/s)
# rotdegs # Degrees per second (°/s)
# celsius # Celsius (°C)
# fahrenheit # Fahrenheit (°F)
# kelvin # Kelvin (K)
# hertz # Hertz (1/s)
# ns # nanoseconds (ns)
# µs # microseconds (µs)
# ms # milliseconds (ms)
# s # seconds (s)
# m # minutes (m)
# h # hours (h)
# d # days (d)
# dtdurationms # duration (ms)
# dtdurations # duration (s)
# dthms # duration (hh:mm:ss)
# dtdhms # duration (d hh:mm:ss)
# timeticks # Timeticks (s/100)
# clockms # clock (ms)
# clocks # clock (s)
# cps # counts/sec (cps)
# ops # ops/sec (ops)
# reqps # requests/sec (rps)
# rps # reads/sec (rps)
# wps # writes/sec (wps)
# iops # I/O ops/sec (iops)
# eps # events/sec (eps)
# mps # messages/sec (mps)
# recps # records/sec (rps)
# rowsps # rows/sec (rps)
# cpm # counts/min (cpm)
# opm # ops/min (opm)
# reqpm # requests/min (rpm)
# rpm # reads/min (rpm)
# wpm # writes/min (wpm)
# epm # events/min (epm)
# mpm # messages/min (mpm)
# recpm # records/min (rpm)
# rowspm # rows/min (rpm)
# velocityms # meters/second (m/s)
# velocitykmh # kilometers/hour (km/h)
# velocitymph # miles/hour (mph)
# velocityknot # knot (kn)
# mlitre # millilitre (mL)
# litre # litre (L)
# m3 # cubic meter
# Nm3 # Normal cubic meter
# dm3 # cubic decimeter
# gallons # gallons
# bool # True / False
# bool_yes_no # Yes / No
# bool_on_off # On / Off
`.trim();

const IMPORT_FUNCTION_TEMPLATE = `
from typing import Iterable, Callable, List
import pandas as pd

# register([{ 'name': 'ch1' }, { 'name': 'ch2', 'channel_attributes': {}, 'plotting_attributes': {}])
# send({
#     'time': [time.time()], # required, [float, float, ...] | np.ndarray[float]
#     'ch1': [0.1, 0.2], # [float, float, ...] | np.ndarray[float]
#     'ch2': [0.2, 0.1], # [float, float, ...] | np.ndarray[float]
# })

def handle(
    files: List[str],
    args: dict,
    register: Callable[[List[dict]], None],
    send: Callable[[dict], None],
):
    registered_channels = {}
    for file in files:
      dataset = pd.read_csv(file)
      data = dataset.to_dict('list');
      for key in data.keys():
        if key not in registered_channels and key != 'time':
          registered_channels[key] = { 'name': key }
      register(list(registered_channels.values()))
      send(data)
    
`.trim();

const DataflowNewFunction: React.FC<DataflowNewFunctionProps> = ({ typedFunctions, type, setValue, ...props }) => {
  const handleNewInlineFunction = () => {
    switch (type) {
      case 'Source':
        setValue({
          outputType: 'TIMESERIES',
          function: {
            name: 'Inline Source',
            description: '',
            schema: {},
            code: SOURCE_FUNCTION_TEMPLATE,
            pinLevel: 'UNPINNED',
          },
        });
        break;
      case 'Process':
        const existingProcesses = (props as Extract<DataflowNewFunctionProps, { type: 'Process' }>).getValues(
          'processes'
        );
        setValue({
          sequence: existingProcesses.length + 1,
          args: {},
          function: {
            inputType: 'TIMESERIES',
            outputType: 'TIMESERIES',
            function: {
              name: 'Inline Function',
              description: '',
              schema: {},
              code: PROCESS_FUNCTION_TEMPLATE,
              pinLevel: 'UNPINNED',
            },
          },
        });
        break;
      case 'Sink':
        setValue({
          inputType: 'TIMESERIES',
          outputType: 'TIMESERIES',
          function: {
            name: 'Inline Sink',
            description: '',
            schema: {},
            code: SINK_FUNCTION_TEMPLATE,
            pinLevel: 'UNPINNED',
          },
        });
        break;
      case 'Import':
        setValue({
          function: {
            name: 'Inline Import',
            description: '',
            schema: {},
            code: IMPORT_FUNCTION_TEMPLATE,
            pinLevel: 'UNPINNED',
          },
        });
        break;
    }
  };

  const handleAddFromExistingFunction = (fn: TypedFunction) => {
    switch (type) {
      case 'Source':
        setValue(fn.function.id);
        break;
      case 'Process':
        const existingProcesses = (props as Extract<DataflowNewFunctionProps, { type: 'Process' }>).getValues(
          'processes'
        );
        setValue({
          sequence: existingProcesses.length + 1,
          args: {},
          function: fn.function.id,
        });
        break;
      case 'Sink':
        setValue(fn.function.id);
        break;
      case 'Import':
        setValue(fn.function.id);
        break;
    }
  };

  return (
    <div className="mr-1 border border-dashed rounded-md bg-transparent border-gray-200 dark:border-gray-500 px-4 py-8 flex flex-col items-center justify-center space-y-2">
      <p className="text-base my-0 font-semibold">Add Function</p>
      <div className="flex space-x-2 items-center justify-center">
        <Button onClick={handleNewInlineFunction}>Inline function</Button>
        <DataflowAddFromExistingFunctionButton
          typedFunctions={typedFunctions.filter((fn) => !fn.function.dataflowId && !fn.function.importId)}
          onSelected={handleAddFromExistingFunction}
        />
      </div>
    </div>
  );
};

export default DataflowNewFunction;
