import { ConfirmModal, ConfirmModalProps } from '@grafana/ui';
import React, { useState } from 'react';

type UseConfirmProps<T> = Omit<ConfirmModalProps, 'isOpen' | 'onDismiss' | 'onConfirm'> & {
  onDismiss?: ConfirmModalProps['onDismiss'];
} & {
  onConfirm: (confirmProps?: T) => void;
};

function useConfirm<T>(props: UseConfirmProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmProps, setConfirmProps] = useState<T>();

  const onConfirm = () => {
    setIsOpen(false);
    props.onConfirm(confirmProps);
  };

  const onAlternative = () => {
    setIsOpen(false);
    props.onAlternative?.();
  };

  const onDismiss = () => {
    setIsOpen(false);
    props.onDismiss?.();
  };

  return [
    <ConfirmModal
      {...props}
      key="0"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
      onAlternative={props.alternativeText ? onAlternative : undefined}
    />,
    ({ confirmProps }: { confirmProps?: T } = {}) => {
      setIsOpen(true);
      setConfirmProps(confirmProps);
    },
  ] as const;
}

export default useConfirm;
