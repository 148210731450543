import createClient from 'openapi-fetch';
import { API_URL } from 'common';
import { paths } from 'api';
import { currentProjectIdObservable } from './observables';

let { GET, POST, PUT, DELETE } = createClient<paths>({
  baseUrl: API_URL,
  headers: {
    project_id: currentProjectIdObservable.value,
  },
});

currentProjectIdObservable.subscribe((projectId) => {
  ({ GET, POST, PUT, DELETE } = createClient<paths>({
    baseUrl: API_URL,
    headers: {
      'project-id': projectId,
    },
  }));
});

export { GET, POST, PUT, DELETE };
