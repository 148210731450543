import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal } from '@grafana/ui';
import { POST } from 'client';
import useObservable from 'hooks/useObservable';
import { currentProjectIdObservable, projectListObservable } from 'observables';
import React, { useMemo } from 'react';
import RecordForm, { RecordFormValues } from './RecordForm';

export default function RecordAddModal({ onClose }: { onClose: () => void }) {
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const projectList = useObservable(projectListObservable, []);

  const isActiveProject = useMemo(() => {
    return projectList.find((project) => project.id === currentProjectId)?.active;
  }, [currentProjectId, projectList]);

  const handleStartRecord = async (values: RecordFormValues) => {
    const appEvents = getAppEvents();
    try {
      const response = await POST('/api/records', {
        body: values,
      });
      if (response.error) {
        throw new Error(response.error.message);
      }
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Record started successfully'],
      });
      onClose();
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Failed to start record: ${e instanceof Error ? e.message : e}`],
      });
    }
  };

  return (
    <Modal title="Add Record" isOpen={true} onDismiss={onClose}>
      <RecordForm
        disabled={!isActiveProject}
        onSubmit={handleStartRecord}
        actions={
          <>
            {!isActiveProject && (
              <p className="m-0">
                The current project is not active. Start a record in non-active projects will not record any data.
              </p>
            )}
            <Modal.ButtonRow>
              <Button variant="secondary" fill="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isActiveProject}>
                {/* <Icon name=" */}
                {/* <Icon name="circle-mono" size="sm" className="mr-2" /> */}
                Start Record
              </Button>
            </Modal.ButtonRow>
          </>
        }
      />
    </Modal>
  );
}
