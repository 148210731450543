import { WS_API_URL } from 'common';
import React, { useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import DataflowListItem from './DataflowListItem';
import { Dataflow } from '../../types';
import dayjs from 'dayjs';

const DataflowList = () => {
  const [dataflows, setDataflows] = useState<Dataflow[]>([]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/dataflows/watch');

    ws.onmessage = (event) => {
      const dataflows = JSON.parse(event.data) as Dataflow[];
      setDataflows(dataflows.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="flex flex-col">
      {dataflows.map((dataflow) => (
        <DataflowListItem key={dataflow.id} dataflow={dataflow} />
      ))}
    </div>
  );
};

export default DataflowList;
