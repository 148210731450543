import { Field, Input, TextArea } from '@grafana/ui';
import useObservable from 'hooks/useObservable';
import { recordListObservable } from 'observables';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';

export type RecordFormValues = {
  testId: string;
  description: string;
};

type RecordFormProps = {
  defaultValues?: RecordFormValues;
  actions?: React.ReactNode;
  onSubmit?: (values: RecordFormValues) => void;
  disabled?: boolean;
};

export default function RecordForm({ defaultValues, actions, onSubmit, disabled }: RecordFormProps) {
  const records = useObservable(recordListObservable, []);
  const recordTestIds = useMemo(() => records.map((record) => record.testId), [records]);
  const form = useForm<RecordFormValues>({
    disabled,
    defaultValues: defaultValues ?? {
      testId: '',
      description: '',
    },
    resolver: async (values) => {
      if (!values.testId) {
        return {
          values,
          errors: {
            testId: {
              type: 'required',
              message: 'Test ID is required',
            },
          },
        };
      }
      if (!defaultValues && recordTestIds.includes(values.testId)) {
        return {
          values,
          errors: {
            testId: {
              type: 'unique',
              message: 'Test ID must be unique',
            },
          },
        };
      }
      return {
        values,
        errors: {},
      };
    },
    reValidateMode: 'onChange',
  });

  return (
    <form className="gap-2 flex flex-col" onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}>
      <Field
        label="Test ID"
        invalid={!!form.formState.errors.testId}
        error={form.formState.errors.testId?.message}
        required
        disabled={!!defaultValues}
      >
        <Input {...form.register('testId', { required: 'Test ID is required' })} />
      </Field>
      <Field
        label="Description"
        invalid={!!form.formState.errors.description}
        error={form.formState.errors.description?.message}
      >
        <TextArea {...form.register('description')} />
      </Field>
      {actions}
    </form>
  );
}
