import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal } from '@grafana/ui';
import useObservable from 'hooks/useObservable';
import React, { useMemo } from 'react';
import { DELETE } from '../../client';
import { currentProjectIdObservable, projectListObservable } from '../../observables';
import { ProjectUser } from '../../types';

type UserDeleteModalProps = {
  user: ProjectUser;
  onClose: () => void;
};

export default function UserDeleteModal({ user, onClose }: UserDeleteModalProps) {
  // const [removeFromAllProjects, setRemoveFromAllProjects] = React.useState(false);
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const isBaseProject = useMemo(() => {
    return projectListObservable.value.find((project) => project.id === currentProjectId)?.base;
  }, [currentProjectId]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const appEvents = getAppEvents();

    try {
      let error;
      if (!isBaseProject) {
        const response = await DELETE(`/api/projects/{project_id}/users/{user_id}`, {
          params: {
            path: {
              project_id: currentProjectIdObservable.value,
              user_id: user.userId,
            },
          },
        });
        error = response.error;
      } else {
        const response = await DELETE(`/api/users/{user_id}`, {
          params: {
            path: {
              user_id: user.userId,
            },
          },
        });
        error = response.error;
      }
      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Delete user (${user.name}) Successfully`],
        });
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete user (${user.name}) failed: ${error.message}`],
        });
      }
    } catch (err) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Delete user (${user.name}) failed: ${err}`],
      });
    }
  };

  return (
    <Modal title={`Delete User (${user.name || user.login})`} isOpen={true} onDismiss={onClose}>
      <form onSubmit={handleSubmit}>
        <p>
          {!isBaseProject ? (
            <>
              Are you sure you want to remove the user <span className="font-bold">{user.name}</span> from this project?
            </>
          ) : (
            <>
              Are you sure you want to delete the user <span className="font-bold">{user.name}</span>? This will remove
              the user from all projects. User cannot log in to Grafana after this action.
            </>
          )}
        </p>
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Delete</Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  );
}
