import useObservable from 'hooks/useObservable';
import { recordListObservable } from 'observables';
import React, { useEffect, useState } from 'react';
import { Record } from '../../types';
import RecordEditModal from './RecordEditModal';
import RecordListItem from './RecordListItem';

const RecordList = () => {
  const records = useObservable(recordListObservable, []);
  const [selectedRecord, setSelectedRecord] = useState<Record | null>(null);

  useEffect(() => {
    if (selectedRecord) {
      const index = records.findIndex((record) => record.id === selectedRecord.id);
      if (index === -1) {
        setSelectedRecord(null);
      } else {
        setSelectedRecord(records[index]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  return (
    <div className="flex flex-col">
      {records.map((record) => (
        <RecordListItem
          key={record.id}
          record={record}
          onEditClick={() => {
            setSelectedRecord(record);
          }}
        />
      ))}
      {records.length === 0 && (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-500">No data recorded previously</p>
        </div>
      )}
      {selectedRecord && <RecordEditModal record={selectedRecord} onClose={() => setSelectedRecord(null)} />}
    </div>
  );
};

export default RecordList;
