import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import {
  Badge,
  Checkbox,
  ClickOutsideWrapper,
  ColorPicker,
  colors,
  Icon,
  IconButton,
  Input,
  Portal,
  useStyles2,
  useTheme2,
} from '@grafana/ui';
import { Popover } from '@headlessui/react';
import { components } from 'api';
import { WS_API_URL } from 'common/src/main';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AnimatePresence, LayoutGroup, m } from 'framer-motion';
import _ from 'lodash';
import { Activity, Bell, Disc, Folder, Import, LucideIcon } from 'lucide-react';
import {
  currentProjectIdObservable,
  projectListObservable,
  selectedSourceObservable,
  userObservable,
} from 'observables';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { distinct, map } from 'rxjs';
import stringHash from 'string-hash';
import { DELETE, PUT } from '../client';
import useConfirm from '../hooks/useConfirm';
import useObservable from '../hooks/useObservable';
import { Dataflow, DataImport, Derivative } from '../types';
import ChannelMenu from './ChannelMenu';
import DataflowEditDrawer from './Dataflow/DataflowEditDrawer';
import DataImportEditDrawer from './DataImport/DataImportEditDrawer';
import DerivativeEditDrawer from './Derivative/DerivativeEditDrawer';
import RecordEditModal from './Record/RecordEditModal';
import { appState } from './SimplePanel';

dayjs.extend(utc);

type Channel = components['schemas']['Channel'];
type ChannelGroup = components['schemas']['ChannelGroup'];

const getStyles = (theme: GrafanaTheme2) => {
  return {
    channelItem: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 4,
      '&:hover': {
        backgroundColor: theme.colors.emphasize(theme.colors.background.canvas, 0.1),
      },
    }),
    activeChannelItem: css({
      backgroundColor: theme.colors.emphasize(theme.colors.background.canvas, 0.1),
    }),
  };
};

type ChannelGroupWithChannels = ChannelGroup & {
  channels: Channel[];
  immutable?: boolean;
};

// const getColors = (theme: GrafanaTheme2) =>
//   theme.visualization.palette.filter(
//     (color) =>
//       colorManipulator.getContrastRatio(theme.visualization.getColorByName(color), theme.colors.background.primary) >=
//       theme.colors.contrastThreshold
//   );

const ChannelGroups: React.FC<{
  height: number;
  reducedMotion: 'user' | 'always';
  onChannelGroupSelected: (channelGroup: ChannelGroup) => void;
}> = ({ height, reducedMotion, onChannelGroupSelected }) => {
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const projects = useObservable(projectListObservable, []);
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>({});
  const onceRef = React.useRef(false);

  const sortedProject = useMemo(() => {
    // current project first, then active projects, then sorted by name
    return projects.slice().sort((a, b) => {
      if (a.id === currentProjectId) {
        return -1;
      }
      if (b.id === currentProjectId) {
        return 1;
      }
      if (a.active && !b.active) {
        return -1;
      }
      if (!a.active && b.active) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
  }, [projects, currentProjectId]);

  useEffect(() => {
    if (onceRef.current || currentProjectId === 0) {
      return;
    }
    onceRef.current = true;
    setExpanded((expanded) => ({
      ...expanded,
      [currentProjectId]: true,
    }));
  }, [currentProjectId, setExpanded]);

  return (
    <div className="-mt-4">
      <LayoutGroup>
        {sortedProject.map((project) => (
          <CategoryAccordion
            key={project.id}
            title={
              <>
                <span>{project.name}</span>
                {project.active && <Badge color="green" text="Active" className="ml-2" />}
                {project.id === currentProjectId && <Badge color="blue" text="Current" className="ml-2" />}
              </>
            }
            expand={expanded[project.id] ?? false}
            onExpandChange={(expand) =>
              setExpanded((expanded) => ({
                ...expanded,
                [project.id]: expand,
              }))
            }
            reducedMotion={reducedMotion}
            icon={Folder}
            className="pl-2"
          >
            <div className="pl-4 pr-2 pt-2 pb-1">
              <ProjectChannels
                projectId={project.id}
                height={height}
                reducedMotion={reducedMotion}
                onChannelGroupSelected={onChannelGroupSelected}
                isCurrentProject={project.id === currentProjectId}
                isActiveProject={project.active}
              />
            </div>
          </CategoryAccordion>
        ))}
      </LayoutGroup>
      <SelectedSourceHandler />
    </div>
  );
};

const ProjectChannels: React.FC<{
  projectId: number;
  height: number;
  reducedMotion: 'user' | 'always';
  onChannelGroupSelected: (channelGroup: ChannelGroup) => void;
  isCurrentProject?: boolean;
  isActiveProject?: boolean;
}> = ({ projectId, height, reducedMotion, onChannelGroupSelected, isCurrentProject }) => {
  const theme = useTheme2();
  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [channelGroups, setChannelGroups] = React.useState<ChannelGroup[]>([]);
  // const [recordList, setRecordList] = React.useState<Array<Required<components['schemas']['Channel']['record']>>>([]);
  // const [eventList, setEventList] = React.useState<Array<Required<components['schemas']['Channel']['event']>>>([]);
  const [groupedRealtimeChannels, setGroupedRealtimeChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [groupedImportedChannels, setGroupedImportedChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [groupedRecordedChannels, setGroupedRecordedChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [groupedEventChannels, setGroupedEventChannels] = React.useState<ChannelGroupWithChannels[]>([]);
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>({ 0: true, 1: true, 2: true, 3: true });
  const [recordedExpanded, setRecordedExpanded] = React.useState<Record<number, boolean>>({});
  const [eventExpanded, setEventExpanded] = React.useState<Record<number, boolean>>({});
  const groupNameEditing = useObservable(
    appState.pipe(
      distinct(({ groupNameEditing }) => groupNameEditing),
      map((x) => x.groupNameEditing)
    ),
    null
  );
  const [alsoDeleteChannels, setAlsoDeleteChannels] = React.useState(false);
  const [ConfirmDeleteModal, openConfirmDeleteModal] = useConfirm<{
    group: string;
    channels: Channel[];
  }>({
    title: 'Delete Channel Group',
    body: (
      <>
        <p>Are you sure you want to delete this channel group?</p>
        <Checkbox
          value={alsoDeleteChannels}
          onChange={(ev) => setAlsoDeleteChannels(ev.currentTarget.checked)}
          label="Also delete all channels in this group"
        />
      </>
    ),
    confirmText: 'Delete',
    onConfirm: async (props) => {
      if (!props) {
        return;
      }
      if (alsoDeleteChannels) {
        for (const channel of props.channels) {
          await DELETE('/api/channels/{name}', {
            params: {
              path: {
                name: channel.name,
              },
            },
            headers: {
              'project-id': projectId.toString(),
            },
          });
        }
      }
      await DELETE('/api/channel-groups/{name}', {
        params: {
          path: { name: props.group },
        },
        headers: {
          'project-id': projectId.toString(),
        },
      });
    },
  });
  const user = useObservable(userObservable, null);
  const realtimeChannels = useMemo(() => channels.filter((channel) => !!channel.dataflow), [channels]);
  const importedChannels = useMemo(() => channels.filter((channel) => !!channel.import), [channels]);
  const recordedChannels = useMemo(() => channels.filter((channel) => !!channel.record), [channels]);
  const eventChannels = useMemo(() => channels.filter((channel) => !!channel.event), [channels]);
  const importList = useMemo(() => {
    const importMap = importedChannels.reduce((acc, channel) => {
      if (!channel.import) {
        return acc;
      }
      if (!acc[channel.import.id]) {
        acc[channel.import.id] = channel.import;
      }
      return acc;
    }, {} as Record<number, Exclude<components['schemas']['Channel']['import'], null | undefined>>);
    return Object.values(importMap);
  }, [importedChannels]);
  const recordList = useMemo(() => {
    const recordMap = recordedChannels.reduce((acc, channel) => {
      if (!channel.record) {
        return acc;
      }
      if (!acc[channel.record.id]) {
        acc[channel.record.id] = channel.record;
      }
      return acc;
    }, {} as Record<number, Exclude<components['schemas']['Channel']['record'], null | undefined>>);
    return Object.values(recordMap);
  }, [recordedChannels]);
  const eventList = useMemo(() => {
    const eventMap = eventChannels.reduce((acc, channel) => {
      if (!channel.event) {
        return acc;
      }
      if (!acc[channel.event.id]) {
        acc[channel.event.id] = channel.event;
      }
      return acc;
    }, {} as Record<number, Exclude<components['schemas']['Channel']['event'], null | undefined>>);
    return Object.values(eventMap);
  }, [eventChannels]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/channels/watch?projectId=' + projectId);
    ws.addEventListener('message', (event) => {
      const channels = JSON.parse(event.data) as Channel[];
      setChannels(channels);
    });
    return () => {
      ws.close();
    };
  }, [projectId, setChannels]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/channel-groups/watch?projectId=' + projectId);
    ws.addEventListener('message', (event) => {
      const channelGroups = JSON.parse(event.data) as ChannelGroup[];
      setChannelGroups(channelGroups);
    });
    return () => {
      ws.close();
    };
  }, [projectId, setChannelGroups]);

  useEffect(() => {
    const realtimeChannelGroupNames = realtimeChannels.flatMap((channel) => channel.groups);
    const realtimeChannelGroups = channelGroups.filter((group) => realtimeChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, projectId },
      ...realtimeChannelGroups.map((group) => ({
        ...group,
        immutable: !group.userId && (user?.userRole === 'User' || user?.userRole === 'Client'),
        channels: [] as Channel[],
      })),
    ];

    for (const channel of realtimeChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    // put the default group first, then Raw, then Derived, then the rest sorted by name
    groups.sort((a, b) => {
      if (a.name === 'Default') {
        return -1;
      }
      if (b.name === 'Default') {
        return 1;
      }
      if (a.name === 'Raw') {
        return -1;
      }
      if (b.name === 'Raw') {
        return 1;
      }
      if (a.name === 'Derived') {
        return -1;
      }
      if (b.name === 'Derived') {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    groups.forEach((group) => {
      group.channels = _.uniqBy(group.channels, 'name');
    });

    // if default group is empty and there are other groups, remove it
    if (groups[0].channels.length === 0 && groups.length > 1) {
      groups.shift();
    }

    setGroupedRealtimeChannels(groups);
  }, [realtimeChannels, channelGroups, projectId, user?.userRole]);

  useEffect(() => {
    const importedChannelGroupNames = importedChannels.flatMap((channel) => channel.groups);
    const importedChannelGroups = channelGroups.filter((group) => importedChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, projectId },
      ...importedChannelGroups.map((group) => ({
        ...group,
        immutable: !group.userId && (user?.userRole === 'User' || user?.userRole === 'Client'),
        channels: [] as Channel[],
      })),
    ];

    for (const channel of importedChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    groups.forEach((group) => {
      group.channels = _.uniqBy(group.channels, 'name');
    });

    // if default group is empty and there are other groups, remove it
    if (groups[0].channels.length === 0 && groups.length > 1) {
      groups.shift();
    }

    setGroupedImportedChannels(groups);
  }, [importedChannels, channelGroups, projectId, user?.userRole]);

  useEffect(() => {
    const recordedChannelGroupNames = recordedChannels.flatMap((channel) => channel.groups);
    const recordedChannelGroups = channelGroups.filter((group) => recordedChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, projectId },
      ...recordedChannelGroups.map((group) => ({
        ...group,
        immutable: !group.userId && (user?.userRole === 'User' || user?.userRole === 'Client'),
        channels: [] as Channel[],
      })),
    ];

    for (const channel of recordedChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    groups.forEach((group) => {
      group.channels = _.uniqBy(group.channels, 'name');
    });

    // if default group is empty and there are other groups, remove it
    if (groups[0].channels.length === 0 && groups.length > 1) {
      groups.shift();
    }

    setGroupedRecordedChannels(groups);
  }, [recordedChannels, channelGroups, projectId, user?.userRole]);

  useEffect(() => {
    const eventChannelGroupNames = eventChannels.flatMap((channel) => channel.groups);
    const eventChannelGroups = channelGroups.filter((group) => eventChannelGroupNames.includes(group.name));

    const groups = [
      { id: 0, name: 'Default', channels: [] as Channel[], immutable: true, projectId },
      ...eventChannelGroups.map((group) => ({
        ...group,
        immutable: !group.userId && (user?.userRole === 'User' || user?.userRole === 'Client'),
        channels: [] as Channel[],
      })),
    ];

    for (const channel of eventChannels) {
      if (channel.groups.length === 0) {
        groups[0].channels.push(channel);
        continue;
      }
      for (const chGroup of channel.groups) {
        const group = groups.find((group) => group.name === chGroup);
        if (group) {
          group.channels.push(channel);
        }
      }
    }

    groups.forEach((group) => {
      group.channels = _.uniqBy(group.channels, 'name');
    });

    // if default group is empty and there are other groups, remove it
    if (groups[0].channels.length === 0 && groups.length > 1) {
      groups.shift();
    }

    setGroupedEventChannels(groups);
  }, [eventChannels, channelGroups, projectId, user?.userRole]);

  return (
    <>
      <>
        <CategorizedChannelGroups
          title="Realtime"
          icon={Activity}
          expand={expanded[0]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 0: expand }))}
          channelGroups={channelGroups}
          groupedChannels={groupedRealtimeChannels}
          setGroupedChannels={setGroupedRealtimeChannels}
          reducedMotion={reducedMotion}
          groupNameEditing={groupNameEditing}
          height={height}
          onChannelGroupSelected={onChannelGroupSelected}
          openConfirmDeleteModal={openConfirmDeleteModal}
          defaultExpanded
        />
        <CategoryAccordion
          title="Imported"
          icon={Import}
          expand={expanded[1]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 1: expand }))}
          reducedMotion={reducedMotion}
        >
          <m.div className="pl-6 pr-2 py-1">
            {importList.map((imported) => (
              <div key={imported.id} className="py-1 [&>div]:mb-0">
                <CategorizedChannelGroups
                  title={imported.name}
                  expand={expanded[imported.id]}
                  onExpandChange={(expand) =>
                    setExpanded((expanded) => ({
                      ...expanded,
                      [imported.id]: expand,
                    }))
                  }
                  channelGroups={channelGroups}
                  groupedChannels={groupedImportedChannels
                    .filter((group) => group.channels.some((channel) => channel.importId === imported.id))
                    .map((group) => ({
                      ...group,
                      channels: group.channels.filter((channel) => channel.importId === imported.id),
                    }))}
                  setGroupedChannels={setGroupedImportedChannels}
                  reducedMotion={reducedMotion}
                  groupNameEditing={groupNameEditing}
                  height={height}
                  onChannelGroupSelected={onChannelGroupSelected}
                  openConfirmDeleteModal={openConfirmDeleteModal}
                  classes={{
                    title: cx(
                      '!font-semibold',
                      css({
                        color: theme.colors.text.primary,
                      })
                    ),
                  }}
                />
              </div>
            ))}
          </m.div>
        </CategoryAccordion>
        <CategoryAccordion
          title="Recorded"
          icon={Disc}
          expand={expanded[2]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 2: expand }))}
          reducedMotion={reducedMotion}
        >
          <m.div className="pl-6 pr-2 py-1">
            {recordList.map((record) => (
              <div key={record.id} className="py-1 [&>div]:mb-0">
                <CategorizedChannelGroups
                  title={record.testId}
                  expand={recordedExpanded[record.id]}
                  onExpandChange={(expand) =>
                    setRecordedExpanded((expanded) => ({
                      ...expanded,
                      [record.id]: expand,
                    }))
                  }
                  channelGroups={channelGroups}
                  groupedChannels={groupedRecordedChannels
                    .filter((group) => group.channels.some((channel) => channel.recordId === record.id))
                    .map((group) => ({
                      ...group,
                      channels: group.channels.filter((channel) => channel.recordId === record.id),
                    }))}
                  setGroupedChannels={setGroupedRecordedChannels}
                  reducedMotion={reducedMotion}
                  groupNameEditing={groupNameEditing}
                  height={height}
                  onChannelGroupSelected={onChannelGroupSelected}
                  openConfirmDeleteModal={openConfirmDeleteModal}
                  classes={{
                    title: cx(
                      '!font-semibold',
                      css({
                        color: theme.colors.text.primary,
                      })
                    ),
                  }}
                />
              </div>
            ))}
          </m.div>
        </CategoryAccordion>
        <CategoryAccordion
          title="Events"
          icon={Bell}
          expand={expanded[3]}
          onExpandChange={(expand) => setExpanded((expanded) => ({ ...expanded, 3: expand }))}
          reducedMotion={reducedMotion}
        >
          <m.div className="pl-6 pr-2 py-1">
            {eventList.map((event) => (
              <div key={event.id} className="py-1 [&>div]:mb-0">
                <CategorizedChannelGroups
                  title={`${event.name}${event.duplicateId > 1 ? ` (${event.duplicateId})` : ''}`}
                  expand={eventExpanded[event.id]}
                  onExpandChange={(expand) =>
                    setEventExpanded((expanded) => ({
                      ...expanded,
                      [event.id]: expand,
                    }))
                  }
                  channelGroups={channelGroups}
                  groupedChannels={groupedEventChannels
                    .filter((group) => group.channels.some((channel) => channel.eventId === event.id))
                    .map((group) => ({
                      ...group,
                      channels: group.channels.filter((channel) => channel.eventId === event.id),
                    }))}
                  setGroupedChannels={setGroupedEventChannels}
                  reducedMotion={reducedMotion}
                  groupNameEditing={groupNameEditing}
                  height={height}
                  onChannelGroupSelected={onChannelGroupSelected}
                  openConfirmDeleteModal={openConfirmDeleteModal}
                  classes={{
                    title: cx(
                      '!font-semibold',
                      css({
                        color: theme.colors.text.primary,
                      })
                    ),
                  }}
                />
              </div>
            ))}
          </m.div>
        </CategoryAccordion>
      </>
      {ConfirmDeleteModal}
    </>
  );
};

const CategoryAccordion: React.FC<{
  title: React.ReactNode;
  icon?: LucideIcon;
  iconClass?: string;
  expand: boolean;
  onExpandChange: (expanded: boolean) => void;
  reducedMotion?: 'user' | 'always';
  titleClass?: string;
  className?: string;
  children: React.ReactNode;
}> = ({
  title,
  icon: TitleIcon,
  iconClass,
  expand,
  onExpandChange,
  reducedMotion,
  titleClass,
  className,
  children,
}) => {
  return (
    <m.div layout={reducedMotion === 'user' ? 'position' : false} className={cx(!expand ? 'mb-2' : 'mb-1', className)}>
      <div className="flex items-center" role="button" onClick={() => onExpandChange(!expand)}>
        {TitleIcon && <TitleIcon className="mr-2 h-4 w-4" />}
        <h6 className={cx('text-base m-0 font-bold tracking-wider', titleClass)}>{title}</h6>
        <Icon
          name="angle-down"
          size="xl"
          className={cx('ml-auto mr-0', iconClass)}
          style={{
            transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
      <AnimatePresence>{expand && children}</AnimatePresence>
    </m.div>
  );
};

const CategorizedChannelGroups = ({
  title,
  icon: TitleIcon,
  expand,
  onExpandChange,
  channelGroups,
  groupedChannels,
  setGroupedChannels,
  reducedMotion,
  groupNameEditing,
  height,
  onChannelGroupSelected,
  openConfirmDeleteModal,
  defaultExpanded = false,
  classes,
}: {
  title: string;
  icon?: LucideIcon;
  expand: boolean;
  onExpandChange: (expanded: boolean) => void;
  channelGroups: ChannelGroup[];
  groupedChannels: ChannelGroupWithChannels[];
  setGroupedChannels: React.Dispatch<React.SetStateAction<ChannelGroupWithChannels[]>>;
  reducedMotion: 'user' | 'always';
  groupNameEditing: number | null;
  height: number;
  onChannelGroupSelected: (channelGroup: ChannelGroup) => void;
  openConfirmDeleteModal: (props: { confirmProps: { group: string; channels: Channel[] } }) => void;
  defaultExpanded?: boolean;
  classes?: {
    title?: string;
  };
}) => {
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>(
    groupedChannels.reduce((acc, group) => {
      acc[group.id] = true;
      return acc;
    }, {} as Record<number, boolean>)
  );

  useEffect(() => {
    if (!defaultExpanded) {
      return;
    }
    setExpanded(
      groupedChannels.reduce((acc, group) => {
        acc[group.id] = true;
        return acc;
      }, {} as Record<number, boolean>)
    );
  }, [groupedChannels, defaultExpanded]);

  return (
    <m.div layout={reducedMotion === 'user' ? 'position' : false} className={!expand ? 'mb-2' : 'mb-1'}>
      <div className="flex items-center" role="button" onClick={() => onExpandChange(!expand)}>
        {TitleIcon && <TitleIcon className="mr-2 h-4 w-4" />}
        <h6 className={cx('text-base m-0 font-bold tracking-wider', classes?.title)}>{title}</h6>
        <Icon
          name="angle-down"
          size="xl"
          className="ml-auto mr-0"
          style={{
            transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
      <AnimatePresence mode="popLayout">
        {expand && (
          <div className="py-1">
            {groupedChannels.map((group) => (
              <React.Fragment key={group.id}>
                <m.div
                  layout={reducedMotion === 'user' ? 'position' : false}
                  role="button"
                  className="flex items-center pl-6 pr-2 py-1"
                  onClick={() => setExpanded({ ...expanded, [group.id]: !expanded[group.id] })}
                >
                  <div className="flex items-center">
                    {groupNameEditing === group.id ? (
                      <ClickOutsideWrapper
                        onClick={() => {
                          appState.next({ ...appState.getValue(), groupNameEditing: null });
                        }}
                      >
                        <form
                          onSubmit={async (ev) => {
                            ev.preventDefault();
                            appState.next({ ...appState.getValue(), groupNameEditing: null });
                            await PUT('/api/channel-groups/{id}', {
                              params: {
                                path: { id: group.id },
                              },
                              body: group,
                            });
                          }}
                        >
                          <Input
                            value={group.name}
                            onChange={(ev) => {
                              group.name = ev.currentTarget.value;
                              setGroupedChannels((groupedChannels) => [...groupedChannels]);
                            }}
                            onBlur={async () => {
                              appState.next({
                                ...appState.getValue(),
                                groupNameEditing: null,
                              });
                              await PUT('/api/channel-groups/{id}', {
                                params: {
                                  path: { id: group.id },
                                },
                                body: group,
                              });
                            }}
                          />
                        </form>
                      </ClickOutsideWrapper>
                    ) : (
                      <>
                        <p className="text-base m-0 font-semibold">
                          {group.name.startsWith('__u') ? (
                            <>
                              {group.name.split('__')[2]} ({group.channels.length}){' '}
                              <Badge text="User" color="green" className="ml-1" />
                            </>
                          ) : group.name.startsWith('__') ? (
                            <>
                              {group.name.split('__')[2]} ({group.channels.length})
                            </>
                          ) : (
                            <>
                              {group.name} ({group.channels.length})
                            </>
                          )}
                        </p>
                        {!group.immutable && (
                          <IconButton
                            name="pen"
                            aria-label="edit"
                            className={css({ marginLeft: 8 })}
                            size="sm"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              appState.next({
                                ...appState.getValue(),
                                groupNameEditing: group.id,
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex items-center ml-auto mr-0">
                    {groupNameEditing !== group.id && (
                      <>
                        {!group.immutable && (
                          <>
                            <IconButton
                              name="sliders-v-alt"
                              aria-label=""
                              size="sm"
                              className="mr-2"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                onChannelGroupSelected({
                                  id: group.id,
                                  name: group.name,
                                  projectId: group.projectId,
                                });
                              }}
                            />
                            <IconButton
                              name="trash-alt"
                              aria-label="delete"
                              size="sm"
                              className="mr-2"
                              onClick={async (event) => {
                                event.stopPropagation();
                                openConfirmDeleteModal({
                                  confirmProps: { group: group.name, channels: group.channels },
                                });
                              }}
                            />
                          </>
                        )}
                        {/* <IconButton
                  name={
                    group.channels.every((channel) => hiddenChannels.includes(channel.name)) ? 'eye-slash' : 'eye'
                  }
                  aria-label=""
                  size="sm"
                  style={{ marginRight: 8 }}
                  onClick={async (event) => {
                    event.stopPropagation();
                    if (group.channels.every((channel) => hiddenChannels.includes(channel.name))) {
                      for (const channel of group.channels) {
                        channel.plottingAttributes.hidden = false;
                      }
                      await PUT('/api/channels', {
                        body: group.channels,
                      });
                    } else {
                      for (const channel of group.channels) {
                        channel.plottingAttributes.hidden = true;
                      }
                      await PUT('/api/channels', {
                        body: group.channels,
                      });
                    }
                  }}
                /> */}
                      </>
                    )}
                    <Icon
                      name="angle-down"
                      size="xl"
                      style={{
                        transform: expanded[group.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </div>
                </m.div>
                <AnimatePresence mode="popLayout">
                  {expanded[group.id] && (
                    <m.div
                      key={group.id}
                      layout={reducedMotion === 'user'}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="pl-6"
                      style={{
                        maxHeight: height - 32,
                        overflow: 'auto',
                        paddingBottom: 4,
                      }}
                    >
                      {group.channels.map((channel) => (
                        <m.div layout={reducedMotion === 'user'} key={channel.name}>
                          <ChannelItem
                            channel={channel}
                            rerenderChannels={() => setGroupedChannels((groupedChannels) => [...groupedChannels])}
                            hiddenChannels={[]}
                            colors={colors}
                            groups={channelGroups}
                          />
                        </m.div>
                      ))}
                    </m.div>
                  )}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </div>
        )}
      </AnimatePresence>
    </m.div>
  );
};

const ChannelItem: React.FC<{
  channel: Channel;
  rerenderChannels: () => void;
  hiddenChannels: string[];
  colors: string[];
  groups: ChannelGroup[];
}> = ({ channel, rerenderChannels, hiddenChannels, colors, groups }) => {
  const styles = useStyles2(getStyles);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'flip',
        options: {
          padding: 360,
        },
      },
    ],
  });
  const user = useObservable(userObservable, null);

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={cx(styles.channelItem, open && styles.activeChannelItem)}
            as="div"
            role="button"
          >
            <div className="flex items-center">
              <div
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
                className={cx(
                  'relative',
                  css({
                    '& button': { width: 14, height: 14 },
                  })
                )}
              >
                <ColorPicker
                  color={
                    (channel.plottingAttributes.color as string) ||
                    colors[Math.abs(stringHash(channel.name)) % colors.length]
                  }
                  onChange={async (color) => {
                    channel.plottingAttributes.color = color;
                    rerenderChannels();
                    await PUT('/api/channels/{name}', {
                      params: {
                        path: { name: channel.name },
                      },
                      body: {
                        ...channel,
                        channelAttributes: channel.overrideChannelAttributes,
                        isPublic: channel.overrideIsPublic,
                      },
                    });
                  }}
                />
                {!channel.userId && (user?.userRole === 'User' || user?.userRole === 'Client') && (
                  <div className="absolute inset-0 z-10" />
                )}
              </div>
              <p className="m-0 ml-2">
                {channel.name.startsWith('__u') ? (
                  <>
                    {channel.name.split('__')[2]} <Badge text="User" color="green" />
                  </>
                ) : channel.name.startsWith('__') ? (
                  channel.name.split('__')[2]
                ) : (
                  channel.name
                )}
              </p>
            </div>
            <div className="flex items-center">
              {/* <IconButton
                name={hiddenChannels.includes(channel.name) ? 'eye-slash' : 'eye'}
                aria-label=""
                size="sm"
                style={{ marginRight: 8 }}
                onClick={async (event) => {
                  event.stopPropagation();
                  channel.plottingAttributes.hidden = !hiddenChannels.includes(channel.name);
                  await PUT('/api/channels/{name}', {
                    params: {
                      path: { name: channel.name },
                    },
                    body: channel,
                  });
                }}
              /> */}
              <Icon name="angle-right" size="sm" />
            </div>
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={(ref) => {
                setPopperElement(ref);
              }}
              style={popperStyles.popper}
              // className={css({
              //   zIndex: 1300,
              // })}
              {...attributes.popper}
            >
              <ChannelMenu channel={channel} groups={groups} colors={colors} />
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};

function SelectedSourceHandler() {
  const selectedSource = useObservable(selectedSourceObservable, null);

  const onClose = () => {
    selectedSourceObservable.next(null);
  };

  if (selectedSource === null) {
    return null;
  }

  const { sourceType, channel } = selectedSource;

  return (
    <>
      {sourceType === 'Dataflow' ? (
        <DataflowEditDrawer dataflow={channel.dataflow as Dataflow} onClose={onClose} />
      ) : sourceType === 'Import' ? (
        <DataImportEditDrawer dataImport={channel.import as DataImport} onClose={onClose} />
      ) : sourceType === 'Record' ? (
        <RecordEditModal record={channel.record as components['schemas']['Record']} onClose={onClose} />
      ) : sourceType === 'Derived' ? (
        <DerivativeEditDrawer derivative={channel.derivative as Derivative} onClose={onClose} />
      ) : null}
    </>
  );
}

export default ChannelGroups;
