import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Checkbox, Collapse, Field, Modal, Stack } from '@grafana/ui';
import { GET, POST } from 'client';
import React, { useCallback, useEffect, useMemo } from 'react';
import ProjectForm, { ProjectFormValues } from './ProjectForm';
import { projectListObservable, runtimeStateObservable } from '../../observables';
import { ProjectUser, ProjectUserRole } from '../../types';

export default function ProjectAddModal({ onClose }: { onClose: () => void }) {
  const [activateAfterCreate, setActivateAfterCreate] = React.useState(false);
  const [activeProjectUsers, setActiveProjectUsers] = React.useState<ProjectUser[]>([]);
  // const [collapsed, setCollapsed] = React.useState<Record<string, boolean>>({});
  // const clickOnCheckbox = React.useRef(false);
  const [selectedRoles, setSelectedRoles] = React.useState<ProjectUserRole[]>(['Admin', 'Developer']);
  const activeProject = useMemo(
    () => projectListObservable.value.find((project) => project.id === runtimeStateObservable.value.activeProjectId),
    []
  );

  const activeProjectAdminUsers = useMemo(
    () => activeProjectUsers.filter((user) => user.userRole === 'Admin'),
    [activeProjectUsers]
  );
  const activeProjectDeveloperUsers = useMemo(
    () => activeProjectUsers.filter((user) => user.userRole === 'Developer'),
    [activeProjectUsers]
  );
  const activeProjectUserUsers = useMemo(
    () => activeProjectUsers.filter((user) => user.userRole === 'User'),
    [activeProjectUsers]
  );
  const activeProjectClientUsers = useMemo(
    () => activeProjectUsers.filter((user) => user.userRole === 'Client'),
    [activeProjectUsers]
  );

  const handleCreate = useCallback(
    async (values: ProjectFormValues) => {
      const appEvents = getAppEvents();
      try {
        const response = await POST('/api/projects', {
          body: values,
          params: {
            query: {
              activate: activateAfterCreate,
            },
          },
        });
        if (response.error) {
          throw new Error(response.error.message);
        }
        const newProjectId = response.data.id;
        if (selectedRoles.length > 0) {
          const promises = [];
          for (const role of selectedRoles) {
            switch (role) {
              case 'Admin':
                for (const user of activeProjectAdminUsers) {
                  promises.push(
                    POST('/api/projects/{project_id}/users', {
                      body: {
                        userId: user.userId,
                        role: role,
                      },
                      params: {
                        path: {
                          project_id: newProjectId,
                        },
                      },
                    })
                  );
                }
                break;
              case 'Developer':
                for (const user of activeProjectDeveloperUsers) {
                  promises.push(
                    POST('/api/projects/{project_id}/users', {
                      body: {
                        userId: user.userId,
                        role: role,
                      },
                      params: {
                        path: {
                          project_id: newProjectId,
                        },
                      },
                    })
                  );
                }
                break;
              case 'User':
                for (const user of activeProjectUserUsers) {
                  promises.push(
                    POST('/api/projects/{project_id}/users', {
                      body: {
                        userId: user.userId,
                        role: role,
                      },
                      params: {
                        path: {
                          project_id: newProjectId,
                        },
                      },
                    })
                  );
                }
                break;
              case 'Client':
                for (const user of activeProjectClientUsers) {
                  promises.push(
                    POST('/api/projects/{project_id}/users', {
                      body: {
                        userId: user.userId,
                        role: role,
                      },
                      params: {
                        path: {
                          project_id: newProjectId,
                        },
                      },
                    })
                  );
                }
                break;
            }
          }
          await Promise.all(promises);
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: ['Project created successfully'],
          });
          onClose();
        }
      } catch (e) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Failed to create project: ${e instanceof Error ? e.message : e}`],
        });
      }
    },
    [
      activeProjectAdminUsers,
      activeProjectClientUsers,
      activeProjectDeveloperUsers,
      activeProjectUserUsers,
      selectedRoles,
      activateAfterCreate,
      onClose,
    ]
  );

  useEffect(() => {
    // Fetch active project users
    (async () => {
      try {
        const { data, error } = await GET('/api/projects/{project_id}/users', {
          params: {
            path: {
              project_id: runtimeStateObservable.value.activeProjectId,
            },
          },
        });
        if (error) {
          throw new Error(error.message);
        }
        setActiveProjectUsers(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [setActiveProjectUsers]);

  return (
    <Modal title="Add Project" isOpen={true} onDismiss={onClose}>
      <ProjectForm
        onSubmit={handleCreate}
        extraFields={
          <Field label={`Copy users from active project (${activeProject?.name})`}>
            <div className="flex flex-col items-start mt-1 space-y-1">
              <Checkbox
                label={`All Admins (${activeProjectAdminUsers.length})`}
                checked={selectedRoles.includes('Admin')}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setSelectedRoles([...selectedRoles, 'Admin']);
                  } else {
                    setSelectedRoles(selectedRoles.filter((role) => role !== 'Admin'));
                  }
                }}
              />
              <Checkbox
                label={`All Developers (${activeProjectDeveloperUsers.length})`}
                checked={selectedRoles.includes('Developer')}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setSelectedRoles([...selectedRoles, 'Developer']);
                  } else {
                    setSelectedRoles(selectedRoles.filter((role) => role !== 'Developer'));
                  }
                }}
              />
              <Checkbox
                label={`All Users (${activeProjectUserUsers.length})`}
                checked={selectedRoles.includes('User')}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setSelectedRoles([...selectedRoles, 'User']);
                  } else {
                    setSelectedRoles(selectedRoles.filter((role) => role !== 'User'));
                  }
                }}
              />
              <Checkbox
                label={`All Clients (${activeProjectClientUsers.length})`}
                checked={selectedRoles.includes('Client')}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setSelectedRoles([...selectedRoles, 'Client']);
                  } else {
                    setSelectedRoles(selectedRoles.filter((role) => role !== 'Client'));
                  }
                }}
              />
              {/*<Collapse*/}
              {/*  label={<Checkbox label="All Users" />}*/}
              {/*  collapsible*/}
              {/*  isOpen={collapsed['all']}*/}
              {/*  onToggle={() => setCollapsed((collapsed) => ({ ...collapsed, all: !collapsed['all'] }))}*/}
              {/*>*/}
              {/*  <div className="flex flex-col">*/}
              {/*    {activeProjectAdminUsers.length > 0 && (*/}
              {/*      <Collapse*/}
              {/*        label={*/}
              {/*          <Checkbox*/}
              {/*            label="Admins"*/}
              {/*            onClick={(e) => {*/}
              {/*              e.stopPropagation();*/}
              {/*              // clickOnCheckbox.current = true;*/}
              {/*            }}*/}
              {/*          />*/}
              {/*        }*/}
              {/*        collapsible*/}
              {/*        isOpen={collapsed['admins']}*/}
              {/*        onToggle={() => {*/}
              {/*          // if (clickOnCheckbox.current) {*/}
              {/*          //   clickOnCheckbox.current = false;*/}
              {/*          //   return;*/}
              {/*          // }*/}
              {/*          setCollapsed((collapsed) => ({*/}
              {/*            ...collapsed,*/}
              {/*            admins: !collapsed['admins'],*/}
              {/*          }));*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <div className="flex flex-col space-y-2 pl-8 -mt-3">*/}
              {/*          {activeProjectAdminUsers.map((user) => (*/}
              {/*            <Checkbox key={user.userId} label={user.name || user.login} className="flex justify-start" />*/}
              {/*          ))}*/}
              {/*        </div>*/}
              {/*      </Collapse>*/}
              {/*    )}*/}
              {/*    {activeProjectDeveloperUsers.length > 0 && (*/}
              {/*      <Collapse*/}
              {/*        label={<Checkbox label="Developers" onClick={(e) => e.stopPropagation()} />}*/}
              {/*        collapsible*/}
              {/*        isOpen={collapsed['developers']}*/}
              {/*        onToggle={() =>*/}
              {/*          setCollapsed((collapsed) => ({ ...collapsed, developers: !collapsed['developers'] }))*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <div className="flex flex-col space-y-2 pl-8 -mt-3">*/}
              {/*          {activeProjectDeveloperUsers.map((user) => (*/}
              {/*            <Checkbox key={user.userId} label={user.name || user.login} className="flex justify-start" />*/}
              {/*          ))}*/}
              {/*        </div>*/}
              {/*      </Collapse>*/}
              {/*    )}*/}
              {/*    {activeProjectUserUsers.length > 0 && (*/}
              {/*      <Collapse*/}
              {/*        label={<Checkbox label="Users" onClick={(e) => e.stopPropagation()} />}*/}
              {/*        collapsible*/}
              {/*        isOpen={collapsed['users']}*/}
              {/*        onToggle={() =>*/}
              {/*          setCollapsed((collapsed) => ({*/}
              {/*            ...collapsed,*/}
              {/*            users: !collapsed['users'],*/}
              {/*          }))*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <div className="flex flex-col space-y-2 pl-8 -mt-3">*/}
              {/*          {activeProjectUserUsers.map((user) => (*/}
              {/*            <Checkbox key={user.userId} label={user.name || user.login} className="flex justify-start" />*/}
              {/*          ))}*/}
              {/*        </div>*/}
              {/*      </Collapse>*/}
              {/*    )}*/}
              {/*    {activeProjectClientUsers.length > 0 && (*/}
              {/*      <Collapse*/}
              {/*        label={<Checkbox label="Clients" onClick={(e) => e.stopPropagation()} />}*/}
              {/*        collapsible*/}
              {/*        isOpen={collapsed['clients']}*/}
              {/*        onToggle={() =>*/}
              {/*          setCollapsed((collapsed) => ({*/}
              {/*            ...collapsed,*/}
              {/*            clients: !collapsed['clients'],*/}
              {/*          }))*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <div className="flex flex-col space-y-2 pl-8 -mt-3">*/}
              {/*          {activeProjectClientUsers.map((user) => (*/}
              {/*            <Checkbox key={user.userId} label={user.name || user.login} className="flex justify-start" />*/}
              {/*          ))}*/}
              {/*        </div>*/}
              {/*      </Collapse>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</Collapse>*/}
            </div>
          </Field>
        }
        actions={
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setActivateAfterCreate(false);
              }}
            >
              Create
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setActivateAfterCreate(true);
              }}
            >
              Create and Activate
            </Button>
          </Modal.ButtonRow>
        }
      />
    </Modal>
  );
}
