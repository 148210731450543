import React, { useEffect, useState } from 'react';
import { AppEvents, PanelProps } from '@grafana/data';
import { ChannelGroup, DataImport, SimpleOptions } from 'types';
import { domMax, LazyMotion, MotionConfig } from 'framer-motion';
import ChannelGroups from './ChannelGroups';
import { Button, Select } from '@grafana/ui';
import { POST } from '../client';
import { BehaviorSubject } from 'rxjs';
import DataflowList from './Dataflow/DataflowList';
import FunctionList from './Function/FunctionList';
import FunctionAddDrawer from './Function/FunctionAddDrawer';
import DataflowAddDrawer from './Dataflow/DataflowAddDrawer';
import UpdateBodyTheme from './utils/UpdateBodyTheme';
import ChannelGroupChannelToggleList from './Channel/ChannelGroupChannelToggleList';
import dayjs from 'dayjs';
import { getAppEvents } from '@grafana/runtime';
import DataImportList from './DataImport/DataImportList';
import FileList from './File/FileList';
import UploadFilesDrawer from './File/UploadFilesDrawer';
import DataImportEditDrawer from './DataImport/DataImportEditDrawer';

export const appState = new BehaviorSubject({
  groupNameEditing: null as number | null,
  setGroupNameEditing: (id: number | null) => {
    appState.next({ ...appState.getValue(), groupNameEditing: id });
  },
});

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ width, height, options, onOptionsChange }) => {
  const { view, selectedChannelGroup } = options;
  const [reducedMotion, setReducedMotion] = useState<'user' | 'always'>('user');
  const [open, setOpen] = useState(false);
  const [selectedDataImport, setSelectedDataImport] = useState<DataImport | null>(null);

  const handleViewChange = (view: SimpleOptions['view']) => {
    onOptionsChange({ ...options, view });
  };

  const getDrawer = () => {
    if (!open) {
      return null;
    }

    const onClose = () => {
      setOpen(false);
    };

    switch (view) {
      case 'functions':
        return <FunctionAddDrawer onClose={onClose} />;
      case 'dataflows':
        return <DataflowAddDrawer onClose={onClose} />;
      case 'files':
        return <UploadFilesDrawer onClose={onClose} />;
      default:
        return null;
    }
  };

  const handleAddClick = () => {
    setOpen(true);
  };

  const handleChannelGroupSelected = (channelGroup: ChannelGroup) => {
    onOptionsChange({
      ...options,
      selectedChannelGroup: channelGroup,
    });
  };

  useEffect(() => {
    // observe whether body has classname 'react-draggable-transparent-selection' and set reducedMotion accordingly
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes') {
          if (mutation.attributeName === 'class') {
            setReducedMotion(
              document.body.classList.contains('react-draggable-transparent-selection') ? 'always' : 'user'
            );
          }
        }
      }
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <MotionConfig reducedMotion={reducedMotion}>
      <LazyMotion features={domMax}>
        <div className="relative max-h-full overflow-auto" style={{ width, height }}>
          {!selectedChannelGroup ? (
            <>
              <div className="flex justify-between items-center p-1">
                <Select
                  value={view}
                  options={[
                    { label: 'Channels', value: 'channels' } as const,
                    { label: 'Dataflows', value: 'dataflows' } as const,
                    { label: 'Functions', value: 'functions' } as const,
                    { label: 'Data Imports', value: 'dataImports' } as const,
                    { label: 'Files', value: 'files' } as const,
                  ]}
                  onChange={(item) => handleViewChange(item.value || 'channels')}
                  width="auto"
                />
                {view === 'channels' && (
                  <Button
                    icon="plus"
                    fill="text"
                    onClick={async () => {
                      const { data: group, error } = await POST('/api/channel-groups', {
                        body: {
                          name: 'New Group',
                        },
                      });
                      if (error) {
                        console.error(error);
                        return;
                      }
                      // const group = (await res.json()) as ChannelGroup;
                      // setGroupNameEditing(group.id);
                      appState.getValue().setGroupNameEditing(group.id);
                    }}
                  >
                    Add New Group
                  </Button>
                )}
                {view === 'dataflows' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Dataflow
                  </Button>
                )}
                {view === 'functions' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add Function
                  </Button>
                )}
                {view === 'dataImports' && (
                  <Button
                    icon="plus"
                    fill="text"
                    onClick={async () => {
                      const response = await POST('/api/data-imports', {
                        body: {
                          name: `Data Import (${dayjs().format('YYYY-MM-DD HH:mm:ss')})`,
                        },
                      });
                      const appEvents = getAppEvents();

                      if (response.error) {
                        appEvents.publish({
                          type: AppEvents.alertError.name,
                          payload: [`Data Import add failed: ${response.error.message}`],
                        });
                        return;
                      }

                      setSelectedDataImport(response.data);
                    }}
                  >
                    Add Data Import
                  </Button>
                )}
                {view === 'files' && (
                  <Button icon="plus" fill="text" onClick={handleAddClick}>
                    Add File
                  </Button>
                )}
              </div>
              <div className="mt-4">
                {view === 'channels' && (
                  <ChannelGroups
                    height={height}
                    reducedMotion={reducedMotion}
                    onChannelGroupSelected={handleChannelGroupSelected}
                  />
                )}
                {view === 'dataflows' && <DataflowList />}
                {view === 'functions' && <FunctionList />}
                {view === 'dataImports' && (
                  <DataImportList
                    selectedDataImport={selectedDataImport}
                    setSelectedDataImport={setSelectedDataImport}
                  />
                )}
                {view === 'files' && <FileList />}
              </div>
            </>
          ) : (
            <ChannelGroupChannelToggleList options={options} onOptionsChange={onOptionsChange} />
          )}
          {selectedDataImport && (
            <DataImportEditDrawer dataImport={selectedDataImport} onClose={() => setSelectedDataImport(null)} />
          )}
        </div>
        {getDrawer()}
        <UpdateBodyTheme />
      </LazyMotion>
    </MotionConfig>
  );
};
