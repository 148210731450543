import React, { useEffect, useState } from 'react';
import { Icon, IconButton, useTheme2 } from '@grafana/ui';
import { File } from '../../types';
import { DELETE } from '../../client';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WS_API_URL } from 'common';

export default function FileList() {
  const theme = useTheme2();
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(`${WS_API_URL}/api/files/watch`);

    ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.data) as File[];
      setFiles(data);
    });

    return () => {
      ws.close();
    };
  }, []);

  const handleDelete = async (file_name: string) => {
    try {
      const response = await DELETE('/api/files/{file_name}', {
        params: {
          path: {
            file_name,
          },
        },
      });
      const appEvents = getAppEvents();
      if (response.error) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          message: response.error.message,
        });
      } else {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          message: 'File deleted successfully',
        });
      }
    } catch (e) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        message: `Failed to delete file: ${e instanceof Error ? e.message : `${e}`}`,
      });
    }
  };

  if (!open) {
    return null;
  }

  return (
    <>
      {files && (
        <>
          {files.length > 0 ? (
            <div className="flex flex-col space-y-2 max-h-96 overflow-y-auto">
              {files.map((file) => (
                <div
                  key={file.name}
                  className="flex items-center p-3 border rounded-md"
                  style={{ backgroundColor: theme.colors.background.secondary }}
                >
                  <Icon name="file-blank" className="w-4 h-4 mr-2" />
                  <span className="truncate">{file.name}</span>
                  <span className="text-sm ml-auto mr-4 whitespace-nowrap">{toHumanReadable(file.size)}</span>
                  <IconButton
                    name="trash-alt"
                    aria-label={`delete ${file.name}`}
                    onClick={() => handleDelete(file.name)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center h-48">
              <p className="text-lg font-medium text-gray-500">No files found</p>
            </div>
          )}
        </>
      )}
    </>
  );
}

function toHumanReadable(size: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  let i = 0;
  while (size > 1024) {
    size /= 1024;
    i++;
  }

  return `${size.toFixed(2)} ${units[i]}`;
}
