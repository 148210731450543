import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Badge, Button, Card } from '@grafana/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useObservable from 'hooks/useObservable';
import { DEFAULT_RUNTIME_STATE, runtimeStateObservable } from 'observables';
import React, { useCallback } from 'react';
import { DELETE, POST } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { Record } from '../../types';

dayjs.extend(utc);

interface RecordListItemProps {
  record: Record;
  onEditClick: () => void;
}

const RecordListItem: React.FC<RecordListItemProps> = ({ record, onEditClick }) => {
  const recording = useObservable(runtimeStateObservable, DEFAULT_RUNTIME_STATE).recording;

  const [ConfirmComponent, handleDelete] = useConfirm({
    title: `Delete record (${record.testId})`,
    body: 'Are you sure you want to delete this record? All previous recorded data will be removed.',
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      try {
        const { error } = await DELETE('/api/records/{id}', {
          params: {
            path: {
              id: record.id,
            },
          },
        });

        if (!error) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: [`Delete record (${record.testId}) Successfully`],
          });
        } else {
          appEvents.publish({
            type: AppEvents.alertError.name,
            payload: [`Delete record (${record.testId}) failed: ${error.message}`],
          });
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete record (${record.testId}) failed: ${err}`],
        });
      }
    },
  });

  const handleFinish = useCallback(async () => {
    const appEvents = getAppEvents();

    try {
      await POST(`/api/records/{id}/finish`, {
        params: {
          path: {
            id: record.id,
          },
        },
      });
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Finish record (${record.testId}) Successfully`],
      });
    } catch (e) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Finish record (${record.testId}) failed: ${e}`],
      });
    }
  }, [record]);

  return (
    <Card>
      <Card.Heading>{record.testId}</Card.Heading>
      <Card.Meta>
        {dayjs(record.startedAt).utcOffset(new Date().getTimezoneOffset()).format('YYYY-MM-DD HH:mm:ss')}
        {record.finishedAt && `${millisecondsToHumanReadable(dayjs(record.finishedAt).diff(dayjs(record.startedAt)))}`}
      </Card.Meta>
      <Card.Description>{record.description}</Card.Description>
      <Card.Tags>
        {!record.finishedAt ? <Badge color="blue" text="Recording" /> : <Badge color="green" text="Finished" />}
      </Card.Tags>
      <Card.Actions>
        <Button key="edit" variant="secondary" icon="edit" onClick={onEditClick}>
          Edit
        </Button>
        {recording?.id === record.id ? (
          <Button key="finish" variant="destructive" icon="times" onClick={() => handleFinish()}>
            Finish
          </Button>
        ) : (
          <Button key="delete" variant="destructive" icon="trash-alt" onClick={() => handleDelete()}>
            Delete
          </Button>
        )}
      </Card.Actions>
      {ConfirmComponent}
      {/* {isOpen && <DatasourceEditDrawer datasource={datasource} onClose={() => setIsOpen(false)} onEdited={onEdited} />} */}
    </Card>
  );
};

function millisecondsToHumanReadable(ms: number): string {
  let seconds = Math.floor(ms / 1000);
  ms %= 1000;
  if (seconds < 60) {
    return `${seconds}s`;
  }
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;
  if (minutes < 60) {
    return `${minutes}m${seconds}s`;
  }
  let hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${hours}h${minutes}m${seconds}s`;
}

export default RecordListItem;
