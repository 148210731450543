import React, { useEffect, useMemo, useRef, useState } from 'react';
import { File, ImportDataInput, TypedFunction } from '../../types';
import { Field, FieldSet, FormAPI, InputControl, ReactMonacoEditor, Select } from '@grafana/ui';
import { GET } from '../../client';
import DataflowFunctionBlock from '../Dataflow/DataflowFunctionBlock';
import DataflowInlineFunction, { DataflowInlineFunctionFormValues } from '../Dataflow/DataflowInlineFunction';
import DataflowNewFunction from '../Dataflow/DataflowNewFunction';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WS_API_URL } from 'common';
import { SelectableValue } from '@grafana/data';

export type DataImportFormValues = ImportDataInput;

interface DataImportFormProps extends FormAPI<DataImportFormValues> {
  console: string;
}

const DataImportForm: React.FC<DataImportFormProps> = ({
  register,
  errors,
  control,
  watch,
  getValues,
  setValue,
  console: consoleText,
}) => {
  const [typedFunctions, setTypedFunctions] = useState<TypedFunction[]>([]);
  const importFunctions = useMemo(
    () => typedFunctions.filter((fn) => fn.__typename === 'Import'),
    [typedFunctions]
  ) as Array<Extract<TypedFunction, { __typename: 'Import' }>>;
  const importInited = useRef(false);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    GET('/api/typed-functions')
      .then(({ data }) => setTypedFunctions(data || []))
      .catch(console.log);
  }, []);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(`${WS_API_URL}/api/files/watch`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data) as File[];
      setFiles(data);
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <FieldSet className="mb-2">
      {/*<Field label="Name" invalid={!!errors.name} error={errors.name?.message} required>*/}
      {/*  <Input*/}
      {/*    type="text"*/}
      {/*    {...register('name', {*/}
      {/*      required: 'Name is required',*/}
      {/*    })}*/}
      {/*  />*/}
      {/*</Field>*/}
      <Field label="Files" invalid={!!errors.files}>
        <div className="flex space-x-2 items-center">
          <InputControl
            control={control}
            name="files"
            render={({ field }) => (
              <Select
                {...field}
                value={field.value}
                isMulti
                onChange={(value) => {
                  field.onChange((value as Array<SelectableValue<String>>).map((v) => v.value));
                }}
                options={files?.map((file) => ({ label: file.name, value: file.name })) ?? []}
              />
            )}
          />
          {/*<Button type="button" size="md" onClick={() => setOpen(true)}>*/}
          {/*  Upload*/}
          {/*</Button>*/}
        </div>
      </Field>
      <Field label="Import Function" invalid={!!errors.importFunction} error={errors.importFunction?.message} required>
        <InputControl
          control={control}
          name="importFunction"
          rules={{
            required: 'Import Function is required',
          }}
          render={({ field }) => {
            if (field.value === null) {
              return (
                <DataflowNewFunction
                  typedFunctions={importFunctions}
                  type="Import"
                  setValue={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }

            const importFunction = importFunctions.find((fn) => fn.function.id === field.value);

            // console.log(importFunction, field.value);
            if (typeof field.value !== 'number') {
              const handleSetValue = (value: DataflowInlineFunctionFormValues) => {
                field.onChange({
                  function: {
                    name: 'Inline Import',
                    description: '',
                    schema: {},
                    code: value.code,
                    pinLevel: 'UNPINNED',
                    createdAt: new Date().toISOString(),
                  },
                });
              };

              const value =
                typeof field.value !== 'number'
                  ? {
                      code: field.value.function.code,
                    }
                  : {
                      code: importFunction?.function.code || '',
                    };

              if (!importInited.current) {
                handleSetValue(value);
                importInited.current = true;
              }

              return (
                <DataflowInlineFunction
                  type="Import"
                  value={value}
                  setValue={(value) => {
                    field.onChange({
                      inputType: value.inputType,
                      outputType: value.outputType,
                      function: {
                        name: 'Inline Import',
                        description: '',
                        schema: {},
                        code: value.code,
                        pinLevel: 'UNPINNED',
                        createdAt: new Date().toISOString(),
                      },
                    });
                  }}
                  onDelete={() => field.onChange(null)}
                />
              );
            }
            return (
              <>
                {importFunctions.length > 0 && (
                  <DataflowFunctionBlock
                    fn={importFunctions.find((fn) => fn.function.id === field.value)!}
                    argument={getValues('importFunctionArgs')}
                    setArgument={(value) => {
                      // field2.value = value;
                      setValue('importFunctionArgs', value);
                    }}
                    onDelete={() => field.onChange(null)}
                  />
                )}
              </>
            );
          }}
        />
      </Field>
      {consoleText && (
        <div>
          <ReactMonacoEditor
            width="100%"
            height="100px"
            language="json"
            value={consoleText}
            options={{
              readOnly: true,
              lineNumbers: 'off',
              minimap: {
                enabled: false,
              },
            }}
          />
        </div>
      )}
    </FieldSet>
  );
};

export default DataImportForm;
