import { css, cx } from '@emotion/css';
import { Checkbox, IconButton } from '@grafana/ui';
import { API_URL } from 'common';
import React, { useEffect, useState } from 'react';
import { transformLocalName } from 'utils';
import { Channel, SimpleOptions } from '../../types';

interface ChannelGroupChannelToggleListProps {
  options: SimpleOptions;
  onOptionsChange: (options: SimpleOptions) => void;
}

const ChannelGroupChannelToggleList: React.FC<ChannelGroupChannelToggleListProps> = ({ options, onOptionsChange }) => {
  const selectedChannelGroup = options.selectedChannelGroup;
  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    if (!selectedChannelGroup?.projectId) {
      return;
    }
    fetch(`${API_URL}/api/channels?projectId=${selectedChannelGroup?.projectId}`)
      .then((response) => response.json())
      .then((data) => {
        setChannels(data);
      });
  }, [selectedChannelGroup?.projectId]);

  const handleChange = (channel: Channel) => async (event: React.FormEvent<HTMLInputElement>) => {
    if (!selectedChannelGroup) {
      return;
    }

    const checked = event.currentTarget.checked;
    const channelGroups = channel.groups;

    if (checked) {
      channelGroups.push(selectedChannelGroup.name);
    } else {
      channelGroups.splice(channelGroups.indexOf(selectedChannelGroup.name), 1);
    }

    try {
      await fetch(`${API_URL}/api/channels/${channel.name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...channel,
          groups: channelGroups,
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (!selectedChannelGroup) {
    return null;
  }

  return (
    <>
      <div className="flex items-center space-x-2 p-1">
        <IconButton
          name="arrow-left"
          aria-label=""
          size="xxl"
          onClick={() => {
            onOptionsChange({
              ...options,
              view: 'channels',
              selectedChannelGroup: null,
            });
          }}
        />
        <p className="my-0 font-semibold">{transformLocalName(selectedChannelGroup.name)} channels</p>
      </div>
      <div className="mt-4 list-none flex flex-col">
        {channels.map((channel) => (
          <Checkbox
            key={channel.name}
            label={transformLocalName(channel.name)}
            value={channel.groups.includes(selectedChannelGroup.name)}
            onChange={handleChange(channel)}
            className={cx(
              'flex space-x-2 hover:bg-black/10 hover:dark:bg-white/10 p-1 cursor-pointer',
              css({
                '& > span': {
                  fontSize: '1em',
                },
                '& input': {
                  cursor: 'pointer',
                },
              })
            )}
          />
        ))}
      </div>
    </>
  );
};

export default ChannelGroupChannelToggleList;
