import type { Observable } from 'rxjs';
import { useEffect, useState } from 'react';

// useObservable hook
export default function useObservable<T>(observable: Observable<T>, initialState: T): T {
  const [value, setValue] = useState(initialState);
  useEffect(() => {
    const sub = observable.subscribe(setValue);
    return () => sub.unsubscribe();
  }, [observable]);
  return value;
}
