import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal, Tab, TabsBar } from '@grafana/ui';
import { POST } from 'client';
import useObservable from 'hooks/useObservable';
import React, { useCallback, useMemo } from 'react';
import { currentProjectIdObservable, projectListObservable } from '../../observables';
import { ProjectUserRole } from '../../types';
import UserAddFromExisting from './UserAddFromExisting';
import UserForm, { UserFormValues } from './UserForm';

export default function UserAddModal({ onClose }: { onClose: () => void }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const currentProjectId = useObservable(currentProjectIdObservable, 0);
  const projectList = useObservable(projectListObservable, []);

  const isBaseProject = useMemo(() => {
    return projectList.find((project) => project.id === currentProjectId)?.base;
  }, [currentProjectId, projectList]);

  const handleCreate = useCallback(
    async (values: UserFormValues | { userId: number; role: ProjectUserRole }) => {
      const appEvents = getAppEvents();
      try {
        if (values.role === 'Admin') {
          // add to base project first, then add to other projects
          const baseProject = projectList.find((project) => project.base);
          if (!baseProject) {
            throw new Error('Base project not found');
          }
          const response = await POST('/api/projects/{project_id}/users', {
            body: values,
            params: {
              path: {
                project_id: baseProject.id,
              },
            },
          });
          if (response.error) {
            throw new Error(response.error.message);
          }
          const user = response.data;
          for (const project of projectList) {
            if (project.base) {
              continue;
            }
            const response = await POST('/api/projects/{project_id}/users', {
              body: {
                userId: user.userId,
                role: values.role,
              },
              params: {
                path: {
                  project_id: project.id,
                },
              },
            });
            if (response.error) {
              throw new Error(response.error.message);
            }
          }
        } else {
          const response = await POST('/api/projects/{project_id}/users', {
            body: values,
            params: {
              path: {
                project_id: currentProjectIdObservable.value,
              },
            },
          });
          if (response.error) {
            throw new Error(response.error.message);
          }
        }
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['User created successfully'],
        });
        onClose();
      } catch (e) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Failed to create user: ${e instanceof Error ? e.message : e}`],
        });
      }
    },
    [onClose, projectList]
  );

  return (
    <Modal title={!isBaseProject ? 'Add User to Current Project' : 'Add User'} isOpen={true} onDismiss={onClose}>
      {!isBaseProject ? (
        <>
          <TabsBar className="mb-6">
            <Tab label="New User" active={tabIndex === 0} onChangeTab={() => setTabIndex(0)} />
            <Tab label="Existing User" active={tabIndex === 1} onChangeTab={() => setTabIndex(1)} />
          </TabsBar>
          {tabIndex === 0 && (
            <UserForm
              onSubmit={handleCreate}
              actions={
                <Modal.ButtonRow>
                  <Button variant="secondary" fill="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Create</Button>
                </Modal.ButtonRow>
              }
            />
          )}
          {tabIndex === 1 && <UserAddFromExisting onSubmit={handleCreate} onClose={onClose} />}
        </>
      ) : (
        <UserForm
          onSubmit={handleCreate}
          actions={
            <Modal.ButtonRow>
              <Button variant="secondary" fill="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </Modal.ButtonRow>
          }
        />
      )}
    </Modal>
  );
}
