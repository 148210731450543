import { css, cx } from '@emotion/css';
import {
  Badge,
  Button,
  Checkbox,
  Icon,
  IconButton,
  InlineField,
  Input,
  MultiSelect,
  Select,
  TagList,
  Tooltip,
  useTheme2,
} from '@grafana/ui';
import Form from '@rjsf/core';
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  BaseInputTemplateProps,
  FormContextType,
  getInputProps,
  getTemplate,
  getUiOptions,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { DragControls } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { GET } from '../../client';
import { currentProjectIdObservable } from '../../observables';
import { APIEvent, Channel, ChannelGroup, DataImport, TypedFunction } from '../../types';
import SelectChannelsDrawer from '../shared/SelectChannelsDrawer';

interface DataflowFunctionBlockProps {
  fn: TypedFunction;
  argument: Record<string, any>;
  setArgument: (arg: Record<string, any>) => void;
  onDelete: () => void;
  controls?: DragControls;
}

const DataflowFunctionBlock: React.FC<DataflowFunctionBlockProps> = ({
  fn,
  argument,
  setArgument,
  onDelete,
  controls,
}) => {
  const theme = useTheme2();
  const [schema, setSchema] = useState<RJSFSchema | null>();

  const formData = useMemo(() => {
    return argument;
  }, [argument]);

  useEffect(() => {
    (async () => {
      try {
        const [
          { data: channelGroupsSchema },
          { data: channels },
          { data: dataImports = [] },
          { data: records = [] },
          { data: events = [] },
        ] = await Promise.all([
          GET('/api/channel-groups/schema'),
          GET('/api/channels'),
          GET('/api/data-imports'),
          GET('/api/records'),
          GET('/api/events'),
        ]);
        if (!channelGroupsSchema || !channels) {
          return;
        }
        setSchema((schema = fn.function.schema) => ({
          ...schema,
          $defs: {
            ...schema!.$defs,
            'channel-groups': channelGroupsSchema,
            channel: {
              type: 'string',
              enum: channels.map((channel: Channel) => channel.name),
            },
            'realtime-channel': {
              type: 'string',
              enum: channels.filter((channel: Channel) => !!channel.dataflowId).map((channel: Channel) => channel.name),
            },
            'static-channel': {
              type: 'string',
              enum: channels.filter((channel: Channel) => !channel.dataflowId).map((channel: Channel) => channel.name),
            },
            'data-import-name': {
              type: 'string',
              title: 'Import',
              oneOf: dataImports.map((dataImport: DataImport) => ({
                title: dataImport.name,
                const: dataImport.name,
              })),
            },
            'record-test-id': {
              type: 'string',
              title: 'Record',
              oneOf: records.map((record) => ({
                title: record.testId,
                const: record.testId,
              })),
            },
            'event-id': {
              type: 'number',
              title: 'Event',
              oneOf: events.map((event: APIEvent) => ({
                title: event.name + ` (${event.id}) [${event.createdAt}]`,
                const: event.id,
              })),
            },
            record: {
              type: 'object',
              oneOf: records.map((record) => ({
                title: record.testId,
                properties: {
                  testId: {
                    type: 'string',
                    const: record.testId,
                  },
                  description: {
                    type: 'string',
                    const: record.description,
                  },
                },
              })),
            },
            event: {
              type: 'object',
              oneOf: events.map((event: APIEvent) => ({
                title: event.name + ` (${event.id}) [${event.createdAt}]`,
                properties: {
                  id: {
                    type: 'number',
                    const: event.id,
                  },
                  name: {
                    type: 'string',
                    const: event.name,
                  },
                  type: {
                    type: 'string',
                    const: event.type,
                  },
                  created_at: {
                    type: 'string',
                    const: event.createdAt,
                  },
                },
              })),
            },
            'data-import': {
              type: 'object',
              // enum: dataImports.map((dataImport: DataImport) => dataImport.name),
              oneOf: dataImports.map((dataImport: DataImport) => ({
                title: dataImport.name,
                properties: {
                  id: {
                    type: 'string',
                    const: dataImport.id,
                  },
                  name: {
                    type: 'string',
                    const: dataImport.name,
                  },
                },
              })),
            },
            'data-import-with-type': {
              type: 'object',
              title: 'Import',
              oneOf: dataImports.map((dataImport: DataImport) => ({
                title: dataImport.name,
                properties: {
                  type: {
                    type: 'string',
                    const: 'import',
                  },
                  name: {
                    type: 'string',
                    const: dataImport.name,
                  },
                },
              })),
            },
            'record-with-type': {
              type: 'object',
              title: 'Record',
              oneOf: records.map((record) => ({
                title: record.testId,
                properties: {
                  type: {
                    type: 'string',
                    const: 'record',
                  },
                  test_id: {
                    type: 'string',
                    const: record.testId,
                  },
                },
              })),
            },
            'event-with-type': {
              type: 'object',
              title: 'Event',
              oneOf: events.map((event: APIEvent) => ({
                title: event.name,
                properties: {
                  type: {
                    type: 'string',
                    const: 'event',
                  },
                  id: {
                    type: 'number',
                    const: event.id,
                  },
                },
              })),
            },
            // 'event-label': {
            //   type: 'string',
            //   oneOf: events.map((event: APIEvent) => ({
            //     title: event.name + ` (${event.id}) [${event.createdAt}]`,
            //     const: event.id,
            //   })),
            // },
            'data-import-or-record-or-event': {
              type: 'object',
              oneOf: [
                {
                  $ref: '#/$defs/data-import-name',
                },
                {
                  $ref: '#/$defs/record-test-id',
                },
                {
                  $ref: '#/$defs/event-id',
                },
              ],
              // oneOf: [
              //   ...dataImports.map((dataImport: DataImport) => ({
              //     title: dataImport.name,
              //     properties: {
              //       type: {
              //         type: 'string',
              //         const: 'import',
              //       },
              //       name: {
              //         type: 'string',
              //         const: dataImport.name,
              //       },
              //     },
              //   })),
              //   ...records.map((record) => ({
              //     title: record.testId,
              //     properties: {
              //       type: {
              //         type: 'string',
              //         const: 'record',
              //       },
              //       testId: {
              //         type: 'string',
              //         const: record.testId,
              //       },
              //     },
              //   })),
              //   ...events.map((event: APIEvent) => ({
              //     title: event.name,
              //     properties: {
              //       type: {
              //         type: 'string',
              //         const: 'event',
              //       },
              //       id: {
              //         type: 'number',
              //         const: event.id,
              //       },
              //     },
              //   })),
              // ],
              // discriminator: {
              //   propertyName: 'type',
              //   // mapping: {
              //   //   import: '#/$defs/data-import-with-type',
              //   //   record: '#/$defs/record-with-type',
              //   //   event: '#/$defs/event-with-type',
              //   // },
              // },
            },
          },
        }));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [fn.function.schema]);

  return (
    <div
      className={cx(
        'p-4 w-full flex flex-col rounded-md relative',
        css({
          backgroundColor: theme.colors.background.secondary,
        })
      )}
    >
      <div className="absolute right-4 top-4 flex space-x-4 items-center">
        {controls && (
          <Icon name="draggabledots" size="lg" className="cursor-grab" onPointerDown={(e) => controls.start(e)} />
        )}
        <IconButton
          name="trash-alt"
          size="lg"
          variant="destructive"
          tooltip="Delete"
          tooltipPlacement="left"
          onClick={onDelete}
        />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex-1 flex flex-col space-y-2">
          <div className="flex items-center">
            <p className="my-0 text-base font-medium">{fn.function.name}</p>
            <Tooltip
              interactive
              content={
                <div className="max-h-[80vh] overflow-auto">
                  <p className="my-0 text-sm">{fn.function.description}</p>
                  <p className="mb-1 mt-4">Code: </p>
                  <p className="my-0 text-sm whitespace-pre-wrap">{fn.function.code}</p>
                </div>
              }
            >
              <Icon name="question-circle" size="sm" className="ml-2" />
            </Tooltip>
          </div>
          {schema && (
            <Form
              schema={schema}
              validator={validator}
              formData={formData}
              onChange={(data, id) => {
                setArgument(data.formData);
              }}
              templates={{
                TitleFieldTemplate: () => <></>,
                ObjectFieldTemplate: (props) => (
                  <div className="flex flex-col space-y-4">
                    {props.properties.map((element) => (
                      <div key={element.name}>{element.content}</div>
                    ))}
                  </div>
                ),
                BaseInputTemplate,
                FieldTemplate: ({ label, children, id, schema, required }) => {
                  if (id === 'root') {
                    return <>{children}</>;
                  }
                  return (
                    <InlineField
                      grow
                      shrink
                      label={label}
                      className="[&_label]:bg-neutral-200 dark:[&_label]:bg-neutral-900"
                      required={required}
                    >
                      {children}
                    </InlineField>
                  );
                },
                ButtonTemplates: {
                  SubmitButton: () => {
                    return <></>;
                  },
                },
                ArrayFieldTemplate,
                ArrayFieldItemTemplate,
              }}
              widgets={{
                CheckboxWidget: (props) => {
                  return (
                    <Checkbox
                      value={props.value}
                      onChange={(e) => {
                        props.onChange(e.currentTarget.checked);
                      }}
                    />
                  );
                },
                SelectWidget: (props) => {
                  // if (props.schema.$id === '/channel_groups') {
                  //   return <ChannelGroupsMultiSelect value={props.value} onChange={props.onChange} />;
                  // }

                  if (props.schema.type !== 'array') {
                    return (
                      <Select
                        options={
                          props.options.enumOptions?.map((option) => ({
                            label: transformLocalName(option.label),
                            value: option.value,
                          })) || []
                        }
                        value={props.value}
                        onChange={(item) => {
                          props.onChange(item.value);
                        }}
                      />
                    );
                  }

                  if (props.schema.$id === 'channels-and-groups') {
                    return (
                      <div className="flex flex-col space-y-2">
                        <div>
                          <SelectChannelsWidget value={props.value} onChange={props.onChange} />
                        </div>
                        {props.value?.channels?.length > 0 && (
                          <TagList
                            className="justify-start"
                            displayMax={20}
                            tags={props.value.channels.map((channel: string) => transformLocalName(channel))}
                          />
                        )}
                      </div>
                    );
                  }

                  return (
                    <MultiSelect
                      options={
                        props.options.enumOptions?.map((option) => ({
                          label: transformLocalName(option.label),
                          value: option.value,
                        })) || []
                      }
                      value={props.value}
                      onChange={(item) => {
                        props.onChange(item.map((i) => i.value));
                      }}
                    />
                  );
                },
              }}
              uiSchema={{
                'ui:widget': 'checkboxes',
                'ui:order': schema.propertiesOrder,
              }}
            />
          )}
        </div>
        {fn.__typename !== 'Import' &&
          fn.__typename !== 'PostSource' &&
          fn.__typename !== 'EventTrigger' &&
          fn.__typename !== 'Export' && (
            <div className="flex space-x-2 ml-auto mr-0">
              {fn.__typename !== 'Source' ? (
                <Badge
                  icon="arrow-up"
                  text={fn.inputType}
                  color={fn.inputType === 'TIMESERIES' ? 'blue' : 'orange'}
                  className={
                    fn.inputType === 'TIMESERIES'
                      ? 'bg-slate-100 dark:bg-slate-900'
                      : 'bg-yellow-100 dark:bg-yellow-900'
                  }
                />
              ) : (
                <Badge icon="arrow-up" text="NONE" color="red" className="bg-red-100 dark:bg-red-950" />
              )}
              {fn.__typename !== 'Derivative' ? (
                <Badge
                  icon="arrow-down"
                  text={fn.outputType}
                  color={fn.outputType === 'TIMESERIES' ? 'blue' : 'orange'}
                  className={
                    fn.outputType === 'TIMESERIES'
                      ? 'bg-slate-100 dark:bg-slate-900'
                      : 'bg-yellow-100 dark:bg-yellow-900'
                  }
                />
              ) : (
                <Badge icon="arrow-down" text="NONE" color="red" className="bg-red-100 dark:bg-red-950" />
              )}
            </div>
          )}
      </div>
    </div>
  );
};

function BaseInputTemplate(props: BaseInputTemplateProps) {
  const {
    schema,
    id,
    options,
    label,
    value,
    type,
    placeholder,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    rawErrors,
    hideError,
    uiSchema,
    registry,
    formContext,
    ...rest
  } = props;

  const onTextChange = ({ target: { value: val } }: React.ChangeEvent<HTMLInputElement>) => {
    // Use the options.emptyValue if it is specified and newVal is also an empty string
    onChange(val === '' ? options.emptyValue || '' : val);
  };

  const onTextBlur = ({ target: { value: val } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, val);
  const onTextFocus = ({ target: { value: val } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, val);

  const inputProps = { ...rest, ...getInputProps(schema, type, options) };

  return (
    <Input
      id={id}
      label={label}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readonly}
      autoFocus={autofocus}
      onChange={onChangeOverride || onTextChange}
      onBlur={onTextBlur}
      onFocus={onTextFocus}
      {...inputProps}
    />
  );
}

function ArrayFieldTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: ArrayFieldTemplateProps<T, S, F>
) {
  const {
    canAdd,
    className,
    disabled,
    idSchema,
    uiSchema,
    items,
    onAddClick,
    readonly,
    registry,
    required,
    schema,
    title,
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const ArrayFieldDescriptionTemplate = getTemplate<'ArrayFieldDescriptionTemplate', T, S, F>(
    'ArrayFieldDescriptionTemplate',
    registry,
    uiOptions
  );
  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate', T, S, F>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  );
  const ArrayFieldTitleTemplate = getTemplate<'ArrayFieldTitleTemplate', T, S, F>(
    'ArrayFieldTitleTemplate',
    registry,
    uiOptions
  );

  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;
  return (
    <fieldset className={className} id={idSchema.$id}>
      <ArrayFieldTitleTemplate
        idSchema={idSchema}
        title={uiOptions.title || title}
        required={required}
        schema={schema}
        uiSchema={uiSchema}
        registry={registry}
      />
      <ArrayFieldDescriptionTemplate
        idSchema={idSchema}
        description={uiOptions.description || schema.description}
        schema={schema}
        uiSchema={uiSchema}
        registry={registry}
      />
      <div className="flex flex-col">
        {items &&
          items.map(({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
            <ArrayFieldItemTemplate key={key} {...itemProps} />
          ))}
      </div>
      {canAdd && (
        // <AddButton
        //     className="array-item-add"
        //     onClick={onAddClick}
        //     disabled={disabled || readonly}
        //     uiSchema={uiSchema}
        //     registry={registry}
        // />
        <Button onClick={onAddClick}>Add</Button>
      )}
    </fieldset>
  );
}

const ChannelGroupsMultiSelect = ({
  value,
  onChange,
}: {
  value: number[];
  onChange: (channelGroups: number[]) => void;
}) => {
  const [channelGroups, setChannelGroups] = useState<ChannelGroup[]>([]);

  useEffect(() => {
    GET('/api/channel-groups').then(({ data, error }) => {
      if (error) {
        return;
      }
      setChannelGroups(data || []);
    });
  }, []);

  return (
    <MultiSelect
      options={channelGroups.map((group) => ({
        label: transformLocalName(group.name),
        value: group.id,
      }))}
      value={value}
      onChange={(item) => {
        onChange(item.map((i) => i.value || 0));
      }}
    />
  );
};

function ArrayFieldItemTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: ArrayFieldTemplateItemType<T, S, F>
) {
  const {
    children,
    className,
    disabled,
    hasToolbar,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    hasCopy,
    index,
    onCopyIndexClick,
    onDropIndexClick,
    onReorderClick,
    readonly,
    registry,
    uiSchema,
  } = props;
  const { CopyButton, MoveDownButton, MoveUpButton, RemoveButton } = registry.templates.ButtonTemplates;
  return (
    <div className="mb-2 flex space-x-2">
      <div className="flex-1">{children}</div>
      {hasToolbar && (
        <div className="flex space-x-2">
          {(hasMoveUp || hasMoveDown) && (
            // <MoveUpButton
            //     style={btnStyle}
            //     disabled={disabled || readonly || !hasMoveUp}
            //     onClick={onReorderClick(index, index - 1)}
            //     uiSchema={uiSchema}
            //     registry={registry}
            // />
            <IconButton
              name="arrow-up"
              aria-label=""
              onClick={onReorderClick(index, index - 1)}
              disabled={disabled || readonly || !hasMoveUp}
            />
          )}
          {(hasMoveUp || hasMoveDown) && (
            // <MoveDownButton
            //     style={btnStyle}
            //     disabled={disabled || readonly || !hasMoveDown}
            //     onClick={onReorderClick(index, index + 1)}
            //     uiSchema={uiSchema}
            //     registry={registry}
            // />
            <IconButton
              name="arrow-down"
              aria-label=""
              onClick={onReorderClick(index, index + 1)}
              disabled={disabled || readonly || !hasMoveDown}
            />
          )}
          {hasCopy && (
            <CopyButton
              disabled={disabled || readonly}
              onClick={onCopyIndexClick(index)}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
          {hasRemove && (
            // <RemoveButton
            //     style={btnStyle}
            //     disabled={disabled || readonly}
            //     onClick={onDropIndexClick(index)}
            //     uiSchema={uiSchema}
            //     registry={registry}
            // />
            <IconButton
              name="trash-alt"
              aria-label="delete"
              onClick={onDropIndexClick(index)}
              disabled={disabled || readonly}
            />
          )}
        </div>
      )}
    </div>
  );
}

function SelectChannelsWidget(props: {
  value: {
    channels: string[];
    channel_groups: string[];
  };
  onChange: ({ channels, channel_groups }: { channels: string[]; channel_groups: string[] }) => void;
}) {
  const [open, setOpen] = useState(false);
  const projectId = currentProjectIdObservable.value;
  return (
    <>
      <Button onClick={() => setOpen(true)}>Select Channels</Button>
      {open && (
        <SelectChannelsDrawer
          selectedChannels={props.value.channels?.map((channel) => ({ name: channel, projectId })) ?? []}
          selectedGroups={props.value.channel_groups?.map((group) => ({ name: group, projectId })) ?? []}
          onClose={() => setOpen(false)}
          onChange={(selectedChannels, selectedChannelGroups) => {
            props.onChange({
              channels: Array.from(new Set(selectedChannels.map((channel) => channel.name))),
              channel_groups: Array.from(new Set(selectedChannelGroups.map((group) => group.name))),
            });
          }}
        />
      )}
    </>
  );
}

function transformLocalName(name: string): string {
  if (name.startsWith('__u')) {
    return name.split('__')[2] + ' (User)';
  }
  if (name.startsWith('__e')) {
    return name.split('__')[2] + ` (Event ${name.split('__')[1].slice(1)})`;
  }
  return name;
}

export default DataflowFunctionBlock;
