import useObservable from '../../hooks/useObservable';
import { globalUserListObservable, projectUserListObservable } from '../../observables';
import React, { useMemo } from 'react';
import { Button, Card, EmptyState, Field, Icon, Input, Modal, Select } from '@grafana/ui';
import { GlobalUser, ProjectUserRole } from '../../types';

type UserAddFromExistingProps = {
  onSubmit: (user: { userId: number; role: ProjectUserRole }) => void;
  onClose: () => void;
};

export default function UserAddFromExisting({ onSubmit, onClose }: UserAddFromExistingProps) {
  const [selectedUser, setSelectedUser] = React.useState<GlobalUser | null>(null);
  const [selectedRole, setSelectedRole] = React.useState<ProjectUserRole>('Client');
  const [selectOpen, setSelectOpen] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedUser) {
      onSubmit({ userId: selectedUser.userId, role: selectedRole });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <Field label="Existing User">
        {!selectedUser ? (
          <div
            role="button"
            onClick={() => setSelectOpen(true)}
            className="border border-dashed flex items-center justify-center rounded-lg p-4 transition-colors hover:bg-black/10"
          >
            <div className="flex flex-col space-y-3 items-center">
              <Icon name="plus" size="xl" />
              <p className="mb-0">Select user to add</p>
            </div>
          </div>
        ) : (
          <Card>
            <Card.Figure>
              <img
                src={selectedUser.avatarUrl}
                height="40"
                width="40"
                className="rounded-full"
                alt={selectedUser.name || selectedUser.login}
              />
            </Card.Figure>
            <Card.Heading>{selectedUser.name || selectedUser.login}</Card.Heading>
            <Card.Meta>{selectedUser.email}</Card.Meta>
            <Card.Actions>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  setSelectOpen(true);
                }}
              >
                Change User
              </Button>
            </Card.Actions>
          </Card>
        )}
      </Field>
      <Field label="Role">
        <Select
          options={[
            {
              label: 'Admin',
              value: 'Admin',
            },
            {
              label: 'Developer',
              value: 'Developer',
            },
            {
              label: 'User',
              value: 'User',
            },
            {
              label: 'Client',
              value: 'Client',
            },
          ]}
          value={selectedRole}
          onChange={(v) => setSelectedRole(v.value as ProjectUserRole)}
        />
      </Field>
      {selectOpen && (
        <UserSelectModal
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          onClose={() => setSelectOpen(false)}
        />
      )}
      <Modal.ButtonRow>
        <Button type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={!selectedUser}>
          Add User
        </Button>
      </Modal.ButtonRow>
    </form>
  );
}

function UserSelectModal({
  selectedUser,
  setSelectedUser,
  onClose,
}: {
  selectedUser: GlobalUser | null;
  setSelectedUser: (user: GlobalUser | null) => void;
  onClose: () => void;
}) {
  const [keywords, setKeywords] = React.useState('');
  const globalUsers = useObservable(globalUserListObservable, []);
  const projectUsers = useObservable(projectUserListObservable, []);
  const [currentSelectedUser, setCurrentSelectedUser] = React.useState(selectedUser);

  const nonProjectUsers = useMemo(
    () =>
      globalUsers.filter((globalUser) => {
        return !projectUsers.some((projectUser) => projectUser.userId === globalUser.userId);
      }),
    [globalUsers, projectUsers]
  );

  const filteredNonProjectUsers = useMemo(() => {
    if (!keywords) {
      return nonProjectUsers;
    }
    return nonProjectUsers.filter((user) => {
      return (
        user.name?.toLowerCase().includes(keywords.toLowerCase()) ||
        user.login?.toLowerCase().includes(keywords.toLowerCase())
      );
    });
  }, [nonProjectUsers, keywords]);

  return (
    <Modal title="Select User" isOpen={true} onDismiss={onClose}>
      <div className="flex flex-col space-y-2">
        <Input
          prefix={<Icon name="search" />}
          value={keywords}
          onChange={(e) => setKeywords(e.currentTarget.value)}
          placeholder="Search Users"
        />
        <div className="flex flex-col max-h-[50vh] overflow-y-auto p-2">
          {filteredNonProjectUsers.map((user) => (
            <Card
              key={user.userId}
              isSelected={currentSelectedUser?.userId === user.userId}
              onClick={() => setCurrentSelectedUser(user)}
            >
              <Card.Figure>
                <img
                  src={user.avatarUrl}
                  height="40"
                  width="40"
                  className="rounded-full"
                  alt={user.name || user.login}
                />
              </Card.Figure>
              <Card.Heading>{user.name || user.login}</Card.Heading>
              <Card.Meta>{user.email}</Card.Meta>
            </Card>
          ))}
          {nonProjectUsers.length === 0 && <EmptyState variant="not-found" message="No users available to add" />}
          {nonProjectUsers.length > 0 && filteredNonProjectUsers.length === 0 && (
            <div className="flex items-center justify-center p-4 text-gray-500">
              <Icon name="info-circle" className="mr-2" />
              <p className="mb-0">No users found</p>
            </div>
          )}
        </div>
      </div>
      <Modal.ButtonRow>
        <Button type="button" onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            setSelectedUser(currentSelectedUser);
            onClose();
          }}
          disabled={!currentSelectedUser}
        >
          Confirm
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
}
