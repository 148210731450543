import { getAppEvents } from '@grafana/runtime';
import { POST } from 'client';
import { WS_API_URL } from 'common';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BehaviorSubject } from 'rxjs';
import type { Channel, ChannelGroup, Dataflow, GlobalUser, Project, ProjectUser, Record, RuntimeState } from 'types';

export const recordListObservable = new BehaviorSubject<Record[]>([]);
export const DEFAULT_RUNTIME_STATE: RuntimeState = {
  recording: null,
  activeProjectId: 0,
};
export const runtimeStateObservable = new BehaviorSubject<RuntimeState>(DEFAULT_RUNTIME_STATE);
export const channelListObservable = new BehaviorSubject<Channel[]>([]);
export const channelGroupListObservable = new BehaviorSubject<ChannelGroup[]>([]);
export const projectListObservable = new BehaviorSubject<Project[]>([]);
export const globalUserListObservable = new BehaviorSubject<GlobalUser[]>([]);
export const projectUserListObservable = new BehaviorSubject<ProjectUser[]>([]);
export const dataflowListObservable = new BehaviorSubject<Dataflow[]>([]);
// export const realtimeChannelListObservable = channelListObservable.pipe(
//   map((channels) => {
//     return channels.filter((channel) => !!channel.dataflowId);
//   })
// );
// export const importedChannelListObservable = channelListObservable.pipe(
//   map((channels) => {
//     return channels.filter((channel) => !!channel.importId);
//   })
// );
// export const recordedChannelListObservable = channelListObservable.pipe(
//   map((channels) => {
//     return channels.filter((channel) => !!channel.recordId);
//   })
// );

export const currentProjectIdObservable = new BehaviorSubject<number>(0);

fetch('/api/user')
  .then((response) => response.json())
  .then((user) => {
    currentProjectIdObservable.next(user.orgId);
  });

currentProjectIdObservable.subscribe((projectId) => {
  if (projectId === 0) {
    return;
  }

  const ws = new ReconnectingWebSocket(WS_API_URL + '/api/runtime-state/watch?project_id=' + projectId);

  ws.onmessage = (event) => {
    const runtimeState = JSON.parse(event.data) as RuntimeState;
    runtimeStateObservable.next(runtimeState);
  };

  const ws2 = new ReconnectingWebSocket(WS_API_URL + '/api/channels/watch?project_id=' + projectId);

  ws2.onmessage = (event) => {
    const channels = JSON.parse(event.data) as Channel[];
    channelListObservable.next(channels);
  };

  const ws3 = new ReconnectingWebSocket(WS_API_URL + '/api/channel-groups/watch?project_id=' + projectId);

  ws3.onmessage = (event) => {
    const channelGroups = JSON.parse(event.data) as ChannelGroup[];
    channelGroupListObservable.next(channelGroups);
  };

  const ws4 = new ReconnectingWebSocket(WS_API_URL + '/api/records/watch?project_id=' + projectId);

  ws4.onmessage = (event) => {
    const records = JSON.parse(event.data) as Record[];
    recordListObservable.next(records);
  };

  const ws5 = new ReconnectingWebSocket(WS_API_URL + '/api/projects/watch');

  ws5.onmessage = (event) => {
    const projects = JSON.parse(event.data);
    projectListObservable.next(projects);
  };

  const ws6 = new ReconnectingWebSocket(WS_API_URL + '/api/users/watch');

  ws6.onmessage = (event) => {
    const users = JSON.parse(event.data);
    globalUserListObservable.next(users);
  };

  const ws7 = new ReconnectingWebSocket(WS_API_URL + `/api/projects/${projectId}/users/watch`);

  ws7.onmessage = (event) => {
    const users = JSON.parse(event.data);
    projectUserListObservable.next(users);
  };

  return () => {
    ws.close();
    ws2.close();
    ws3.close();
    ws4.close();
    ws5.close();
    ws6.close();
    ws7.close();
  };
});

let previousRuntimeState: RuntimeState | null = null;

runtimeStateObservable.subscribe((state) => {
  if (state.recording) {
    const el = document.createElement('button');
    el.id = 'stop-recording-button';
    el.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" style="width:32px;height:32px;margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-stop"><circle cx="12" cy="12" r="10"/><rect x="9" y="9" width="6" height="6" rx="1"/></svg>
        <p style="color: white; font-size: 16px; font-weight: bold; margin: 0;">Stop Recording</p>
    `;
    el.style.position = 'fixed';
    el.style.bottom = '30px';
    el.style.right = '30px';
    el.style.zIndex = '9999';
    el.style.backgroundColor = 'hsl(0, 100%, 50%)';
    el.style.border = 'none';
    el.style.borderRadius = '28px';
    el.style.padding = '12px 20px';
    el.style.display = 'flex';
    el.style.justifyContent = 'center';
    el.style.alignItems = 'center';
    el.style.cursor = 'pointer';
    el.title = 'Stop Recording';
    el.onclick = async () => {
      if (!state.recording) {
        return;
      }
      try {
        await POST(`/api/records/{id}/finish`, {
          params: {
            path: {
              id: state.recording.id,
            },
          },
        });
        const appEvents = getAppEvents();
        appEvents.publish({
          type: 'alert-success',
          payload: [`Stop recording (${state.recording.testId}) Successfully`],
        });
      } catch (e) {
        console.error(e);
      }
    };
    document.body.appendChild(el);
  } else {
    const el = document.getElementById('stop-recording-button');
    if (el) {
      el.remove();
    }
  }
});
