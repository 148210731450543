import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { IconButton } from '@grafana/ui';
import { DELETE } from 'client';
import useConfirm from 'hooks/useConfirm';
import React, { useState } from 'react';
import { Channel } from 'types';
import { transformLocalName } from 'utils';

function DeleteChannelButton({ channel, onSuccessfulDelete }: { channel: Channel; onSuccessfulDelete?: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [DeleteChannelModal, handleDelete] = useConfirm({
    title: 'Delete Channel',
    body: `Are you sure you want to delete channel ${transformLocalName(channel.name)}?`,
    confirmText: 'Delete',
    onConfirm: async () => {
      const appEvents = getAppEvents();
      setIsLoading(true);
      try {
        await DELETE('/api/channels/{name}', {
          params: {
            path: {
              name: channel.name,
            },
          },
          headers: {
            'project-id': channel.projectId.toString(),
          },
        });
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: [`Delete channel (${transformLocalName(channel.name)}) Successfully`],
        });
        if (onSuccessfulDelete) {
          onSuccessfulDelete();
        }
      } catch (err) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Delete channel (${transformLocalName(channel.name)}) failed: ${err}`],
        });
      }
      setIsLoading(false);
    },
  });

  return (
    <>
      <IconButton
        type="button"
        name={!isLoading ? 'trash-alt' : 'spinner'}
        disabled={isLoading}
        aria-label="Delete channel"
        onClick={() => handleDelete()}
      />
      {DeleteChannelModal}
    </>
  );
}

export default DeleteChannelButton;
