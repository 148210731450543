import { css, cx } from '@emotion/css';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Drawer,
  DropzoneFile,
  Form,
  FormsOnSubmit,
  HorizontalGroup,
  Icon,
  IconButton,
  Input,
  Tab,
  TabContent,
  TabsBar,
  useTheme2,
} from '@grafana/ui';
import { Disclosure } from '@headlessui/react';
import { API_URL } from 'common';
import DeleteChannelButton from 'components/Channel/DeleteChannelButton';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-json-view-lite/dist/index.css';
import { DELETE, GET, PUT } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import { currentProjectIdObservable } from '../../observables';
import { Channel, DataImport, DataImportResult } from '../../types';
import { transformLocalName } from '../../utils';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import DataImportForm, { DataImportFormValues } from './DataImportForm';

interface DataImportEditDrawerProps {
  dataImport: DataImport;
  onClose: () => void;
}

const DataImportEditDrawer: React.FC<DataImportEditDrawerProps> = ({ dataImport, onClose }) => {
  const [consoleText, setConsoleText] = useState<string>('');
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You have unsaved changes, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const abortController = useRef<AbortController | null>(null);
  const isDirtyRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const result = useMemo(() => {
    return dataImport.result as undefined as DataImportResult | undefined;
  }, [dataImport]);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [files, setFiles] = useState<DropzoneFile[]>([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  const handleSubmit: FormsOnSubmit<DataImportFormValues> = async (data) => {
    try {
      setIsLoading(true);
      abortController.current?.abort();
      setConsoleText('');
      abortController.current = new AbortController();
      const formData = new FormData();
      formData.set(
        'importFunction',
        typeof data.importFunction === 'number' ? data.importFunction.toString() : JSON.stringify(data.importFunction)
      );
      formData.set('importFunctionArgs', JSON.stringify(data.importFunctionArgs));
      files.forEach((file) => {
        formData.append('file', file.file);
      });
      const response = await fetch(`${API_URL}/api/data-imports/${dataImport.id}/import`, {
        method: 'POST',
        body: formData,
        headers: {
          'project-id': currentProjectIdObservable.value.toString(),
        },
        signal: abortController.current.signal,
      });
      const appEvents = getAppEvents();
      if (!response.ok) {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`DataImport edit failed: ${response.statusText}`],
        });
        return;
      }
      const reader = response.body?.getReader();
      if (!reader) {
        return;
      }
      const decoder = new TextDecoder();
      let done = false;
      while (!done && isLoadingRef.current) {
        const { done: _done, value } = await reader.read();
        done = _done;
        setConsoleText((prev) => prev + decoder.decode(value));
      }
      setIsLoading(false);
    } catch (err) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        return;
      }
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`DataImport edit failed: ${err}`],
      });
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!isDirtyRef.current) {
      onClose();
      return;
    }
    showConfirm();
  };

  return (
    <Drawer
      title={
        <>
          <div className="flex items-center p-4 pb-6 relative">
            {!isNameEditing ? (
              <>
                <p className="text-2xl font-medium mb-0 mr-4">{dataImport.name}</p>
                <IconButton
                  name="pen"
                  aria-label="edit name"
                  size="lg"
                  onClick={() => {
                    setIsNameEditing(true);
                  }}
                />
              </>
            ) : (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setIsNameEditing(false);
                  const formValue = new FormData(e.currentTarget);
                  const appEvents = getAppEvents();
                  try {
                    const response = await PUT('/api/data-imports/{id}', {
                      params: {
                        path: {
                          id: dataImport.id,
                        },
                      },
                      body: {
                        name: formValue.get('name') as string,
                      },
                    });
                    if (response.error) {
                      appEvents.publish({
                        type: AppEvents.alertError.name,
                        payload: [`Data Import edit failed: ${response.error.message}`],
                      });
                    } else {
                      appEvents.publish({
                        type: AppEvents.alertSuccess.name,
                        payload: ['Data Import name updated successfully'],
                      });
                    }
                  } catch (e) {
                    appEvents.publish({
                      type: AppEvents.alertError.name,
                      payload: [`Data Import edit failed: ${e instanceof Error ? e.message : e}`],
                    });
                  }
                }}
                className="flex items-center space-x-2"
              >
                <Input type="text" name="name" defaultValue={dataImport.name} className="min-w-[400px] max-w-full" />
                <Button type="submit" size="md">
                  Save
                </Button>
              </form>
            )}
            <IconButton
              name="times"
              aria-label="close drawer"
              className="absolute top-4 right-4 h-4 w-4"
              onClick={handleClose}
            />
          </div>
          <div className="bg-gray-700 h-px w-full" />
        </>
      }
      size="md"
      onClose={handleClose}
      scrollableContent
    >
      <>
        <Form
          defaultValues={{
            files: dataImport.files,
            // @ts-ignore
            importFunction: dataImport.importFunction?.function.importId
              ? dataImport.importFunction
              : dataImport.importFunction?.function.id ?? null,
            importFunctionArgs: dataImport.importFunctionArgs,
          }}
          className="p-4"
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <DataImportForm {...props} console={consoleText} files={files} setFiles={setFiles} />
              {!isLoading && result && (
                <>
                  <TabsBar>
                    <Tab label="Result" active={tabIndex === 0} onChangeTab={() => setTabIndex(0)} />
                    <Tab label="Channel" active={tabIndex === 1} onChangeTab={() => setTabIndex(1)} />
                  </TabsBar>
                  <TabContent>
                    {tabIndex === 0 && (
                      <div className="mt-2">
                        {result?.type === 'success' && (
                          <Alert title="Import Success" severity="success">
                            {dataImport.files.length > 0 && (
                              <>
                                <p className="text-base text-gray-500 dark:text-gray-300 m-0">Files</p>
                                <div className="space-y-2 mt-1 mb-2">
                                  {dataImport.files.map((file) => (
                                    <Badge text={file.replace(/^tmp\/files\/\d+\//, '')} color="orange" key={file} />
                                  ))}
                                </div>
                              </>
                            )}
                            <p className="text-base text-gray-500 dark:text-gray-300 m-0">
                              Imported {Object.values(result.data).reduce((acc, cur) => acc + cur, 0)} points
                            </p>
                            <div className="space-y-2 mt-1">
                              <div className="flex flex-wrap gap-1">
                                {result.registered.map((registry) => (
                                  <Badge text={transformLocalName(registry.name)} color="green" key={registry.name} />
                                ))}
                              </div>
                            </div>
                          </Alert>
                        )}
                        {result?.type === 'error' && (
                          <Alert title="Import Failed" severity="error">
                            {dataImport.files.length > 0 && (
                              <>
                                <p className="text-base text-gray-500 dark:text-gray-300 m-0">Files</p>
                                <div className="space-y-2 mt-1 mb-2">
                                  {dataImport.files.map((file) => (
                                    <Badge text={file.replace(/^tmp\/files\/\d+\//, '')} color="orange" key={file} />
                                  ))}
                                </div>
                              </>
                            )}
                            <p className="text-base dark:text-gray-300">{result.message}</p>
                          </Alert>
                        )}
                      </div>
                    )}
                    {tabIndex === 1 && <DataImportChannels dataImport={dataImport} />}
                  </TabContent>
                </>
              )}
              {result && (
                <p className="text-sm text-gray-500">
                  Import will remove all previously imported data in this &quot;Data Import&quot;.
                </p>
              )}
              <HorizontalGroup spacing="md">
                <Button type="submit" disabled={isLoading}>
                  {isLoading && <Icon name="fa fa-spinner" className="mr-2" />}
                  {!isLoading ? 'Save And Import' : 'Importing...'}
                </Button>
                {isLoading && (
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      setIsLoading(false);
                      abortController.current?.abort();
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </HorizontalGroup>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

function DataImportChannels({ dataImport }: { dataImport: DataImport }) {
  const theme = useTheme2();
  const [channels, setChannels] = useState<Channel[]>([]);
  const groupedChannels = useMemo(() => {
    const grouped: Record<string, Channel[]> = {};
    channels.forEach((channel) => {
      if (channel.groups.length === 0) {
        grouped[''] = grouped[''] || [];
        grouped[''].push(channel);
      }
      for (const group of channel.groups) {
        if (!grouped[group]) {
          grouped[group] = [];
        }
        grouped[group].push(channel);
      }
    });
    return grouped;
  }, [channels]);
  const [alsoDeleteChannels, setAlsoDeleteChannels] = React.useState(false);

  const fetchChannels = useCallback(async () => {
    try {
      const response = await GET('/api/channels');
      if (response.error) {
        throw new Error(response.error.message);
      }
      setChannels(
        response.data.filter(
          (channel: Channel) => channel.projectId === dataImport.projectId && channel.importId === dataImport.id
        )
      );
    } catch {}
  }, [dataImport]);

  const [ConfirmDeleteModal, openConfirmDeleteModal] = useConfirm<{
    group: string;
    channels: Channel[];
  }>({
    title: 'Delete Channel Group',
    body: (
      <>
        <p>Are you sure you want to delete this channel group?</p>
        <Checkbox
          value={alsoDeleteChannels}
          onChange={(ev) => setAlsoDeleteChannels(ev.currentTarget.checked)}
          label="Also delete all channels in this group"
        />
      </>
    ),
    confirmText: 'Delete',
    onConfirm: async (props) => {
      if (!props) {
        return;
      }
      if (alsoDeleteChannels) {
        for (const channel of props.channels) {
          await DELETE('/api/channels/{name}', {
            params: {
              path: {
                name: channel.name,
              },
            },
            headers: {
              'project-id': dataImport.projectId.toString(),
            },
          });
        }
      }
      await DELETE('/api/channel-groups/{name}', {
        params: {
          path: { name: props.group },
        },
        headers: {
          'project-id': dataImport.projectId.toString(),
        },
      });
      fetchChannels();
    },
  });

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={cx(
        'my-2 py-2 rounded-sm',
        css({
          backgroundColor: theme.colors.background.canvas,
          border: `1px solid ${theme.colors.border.medium}`,
        })
      )}
    >
      {Object.keys(groupedChannels).map((group) => (
        <Disclosure key={group} defaultOpen>
          <Disclosure.Button
            as="div"
            role="button"
            className={cx('flex items-center justify-between w-full px-4 py-2 border-b')}
          >
            <p className="text-base font-semibold mb-0">{group ? transformLocalName(group) : 'Ungrouped'}</p>
            <div>
              {group && (
                <IconButton
                  name="trash-alt"
                  aria-label="delete"
                  size="md"
                  className="mr-2"
                  onClick={async (event) => {
                    event.stopPropagation();
                    openConfirmDeleteModal({
                      confirmProps: { group: group, channels: groupedChannels[group] },
                    });
                  }}
                />
              )}
              <Icon name="angle-down" />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="pl-4 pb-2">
            {groupedChannels[group].map((channel) => (
              <div
                key={channel.name}
                className="p-2 hover:bg-black/10 dark:hover:bg-white/10 rounded-md flex items-center justify-between"
              >
                <p className="mb-0 text-base opacity-80">{transformLocalName(channel.name)}</p>
                <DeleteChannelButton channel={channel} onSuccessfulDelete={fetchChannels} />
              </div>
            ))}
          </Disclosure.Panel>
        </Disclosure>
      ))}
      {ConfirmDeleteModal}
    </form>
  );
}

export default DataImportEditDrawer;
