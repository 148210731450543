import { WS_API_URL } from 'common';
import React, { useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import DataflowListItem from './DataflowListItem';
import { Dataflow } from '../../types';
import dayjs from 'dayjs';
import { currentProjectIdObservable } from '../../observables';
import useObservable from '../../hooks/useObservable';

const DataflowList = () => {
  const [dataflows, setDataflows] = useState<Dataflow[]>([]);
  const projectId = useObservable(currentProjectIdObservable, 0);

  useEffect(() => {
    if (projectId === 0) {
      return;
    }

    const ws = new ReconnectingWebSocket(WS_API_URL + '/api/dataflows/watch?project_id=' + projectId);

    ws.onmessage = (event) => {
      const dataflows = JSON.parse(event.data) as Dataflow[];
      setDataflows(dataflows.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1)));
    };

    return () => {
      ws.close();
    };
  }, [projectId]);

  return (
    <div className="flex flex-col">
      {dataflows.map((dataflow) => (
        <DataflowListItem key={dataflow.id} dataflow={dataflow} />
      ))}
    </div>
  );
};

export default DataflowList;
