import useObservable from 'hooks/useObservable';
import { projectListObservable } from 'observables';
import React, { useEffect, useState } from 'react';
import { Project } from '../../types';
import ProjectEditModal from './ProjectEditModal';
import ProjectListItem from './ProjectListItem';

const ProjectList = () => {
  const projects = useObservable(projectListObservable, []);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  useEffect(() => {
    if (selectedProject) {
      const index = projects.findIndex((project) => project.id === selectedProject.id);
      if (index === -1) {
        setSelectedProject(null);
      } else {
        setSelectedProject(projects[index]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  return (
    <div className="flex flex-col">
      {projects.map((project) => (
        <ProjectListItem
          key={project.id}
          project={project}
          onEditClick={() => {
            setSelectedProject(project);
          }}
        />
      ))}
      {projects.length === 0 && (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-500">No data projected previously</p>
        </div>
      )}
      {selectedProject && <ProjectEditModal project={selectedProject} onClose={() => setSelectedProject(null)} />}
    </div>
  );
};

export default ProjectList;
